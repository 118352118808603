import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { partnersServices } from '@services';
import { LIMIT } from '@constants';
import { handlePartnersData } from './partners.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  partners: [],
  total: 0,
  firstItem: 0,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    fetchPartners: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchPartnersSuccess: (state, { payload }) => {
      state.partners = handlePartnersData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.partners.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.partners?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchPartnersFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchPartners, fetchPartnersSuccess, fetchPartnersFailure } =
  partnersSlice.actions;

export const selectPartnersState = (state) => state.partners;

export const getPartners = (payload) => {
  return async (dispatch) => {
    dispatch(fetchPartners());

    try {
      const page = parseInt(payload?.page, 10);
      const partners = await partnersServices?.getPartners(payload);
      partners.meta.page = page;

      dispatch(fetchPartnersSuccess(partners));
    } catch (error) {
      dispatch(fetchPartnersFailure(error));
    }
  };
};

export default partnersSlice.reducer;
