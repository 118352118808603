import { request } from '@utils';
import { LIMIT } from '@constants';

const getCampaigns = async (payload = {}) => {
  if (!payload?.page) {
    payload.page = 1;
  }
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/marketing/campaigns`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getCampaign = async (campaignId) => {
  const results = await request.get(`/v1/marketing/campaigns/${campaignId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const createCampaign = async (payload = {}) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/marketing/campaigns`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const cancelCampaign = async (campaignId) => {
  const results = await request.remove(`/v1/marketing/campaigns/${campaignId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editCampaign = async (campaignId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/marketing/campaigns/${campaignId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postCampaignImage = async (campaignId, payload) => {
  const fd = new FormData();
  fd.append('image', payload.file);
  fd.append('tags', payload.tags);
  const params = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/marketing/campaigns/${campaignId}/images`,
    params
  );
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const deleteCampaignImage = async (campaignId, imageId) => {
  const results = await request.remove(
    `/v1/marketing/campaigns/${campaignId}/images/${imageId}`
  );
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const addCoupons = async (payload = {}) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/marketing/coupons`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getCampaigns,
  getCampaign,
  createCampaign,
  postCampaignImage,
  editCampaign,
  cancelCampaign,
  deleteCampaignImage,
  addCoupons,
};
