import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCampaignsState,
  getCampaigns,
  addSnapshot,
  clearSnapshot,
} from '@store';
import { showError } from '@utils';

export const useCampaignsOperations = (fetchOnMount) => {
  const { total, loading, campaigns, pageSize, hasErrors, errorMessage } =
    useSelector(selectCampaignsState);
  const {
    push,
    query: { page = 1, orderBy = '', order = '', token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getCampaigns = useCallback(async () => {
    await dispatch(getCampaigns({ orderBy, order, page }));
  }, [orderBy, order, page, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/campaigns?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/campaigns?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/campaigns?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/campaigns?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  const _addSnapshot = useCallback(
    async (payload) => {
      dispatch(addSnapshot(payload));
    },
    [dispatch]
  );

  const _clearSnapshot = useCallback(async () => {
    dispatch(clearSnapshot());
  }, [dispatch]);

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getCampaigns();
    }
  }, [_getCampaigns, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    campaigns,
    pageSize,
    hasErrors,
    errorMessage,
    getCampaigns: _getCampaigns,
    nextPage: _nextPage,
    token,
    addSnapshot: _addSnapshot,
    clearSnapshot: _clearSnapshot,
    sortBy: _sortBy,
  };
};
