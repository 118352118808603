import { LIMIT } from '@constants';
import { base64URIToBlob, generateShortId, request } from '@utils';

const getHandoveredCars = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }

  const queryParams = `?handoverType=${payload.handoverType}&page=${payload.page}&pageSize=${payload.pageSize}&order=${payload.order}&orderBy=${payload.orderBy}`;
  delete payload?.handoverType;
  delete payload?.page;
  delete payload?.pageSize;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/subscriptions/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getInspectedCars = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }

  const queryParams = `?page=${payload.page}&pageSize=${payload.pageSize}&order=${payload.order}&orderBy=${payload.orderBy}`;
  delete payload?.page;
  delete payload?.pageSize;
  delete payload?.order;
  delete payload?.orderBy;
  delete payload?.inspectionType;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/subscriptions/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const patchDailySubscription = async (type, subscriptionId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionId}/handover/${type}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const patchInspection = async (type, subscriptionId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/subscriptions/${subscriptionId}/inspection/${type}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const uploadImageSideSubscription = async (
  type,
  subscriptionId,
  side,
  payload
) => {
  const params = {
    body: payload,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/sidechecks/${side}/images`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteImageSideSubscription = async (
  type,
  subscriptionId,
  side,
  imageId
) => {
  const params = {
    body: {},
  };
  const results = await request.remove(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/sidechecks/${side}/images/${imageId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const uploadImageKmTires = async (type, subscriptionId, payload) => {
  const params = {
    body: payload,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/fuel-km-tires-check/images`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteImageKmTires = async (type, subscriptionId, imageId) => {
  const params = {
    body: {},
  };
  const results = await request.remove(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/fuel-km-tires-check/images/${imageId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const uploadImageExtraNotes = async (type, subscriptionId, payload) => {
  const params = {
    body: payload,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/extra-notes/images`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteImageExtraNotes = async (type, subscriptionId, imageId) => {
  const params = {
    body: {},
  };
  const results = await request.remove(
    `/v1/subscriptions/${subscriptionId}/handover/${type}/extra-notes/images/${imageId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addSignature = async (type, subscriptionId, signature) => {
  let params = {};

  if (signature) {
    const image = base64URIToBlob(signature);
    const fd = new FormData();
    fd.append('image', image, `${generateShortId()}.png`);
    params = {
      body: fd,
      header: {
        Accept: '*/*',
      },
    };
  }
  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/handover/${type}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getHandoveredCars,
  patchDailySubscription,
  uploadImageSideSubscription,
  deleteImageSideSubscription,
  uploadImageKmTires,
  deleteImageKmTires,
  uploadImageExtraNotes,
  deleteImageExtraNotes,
  addSignature,
  patchInspection,
  getInspectedCars,
};
