import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHandoveredCarsState,
  getHandoveredCars,
  getMoreHandoveredCars,
  changePage,
  initState,
} from '@store';
import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';
import moment from 'moment';

export const useHandoverCarsOperations = (handoverType, fetchOnMount) => {
  const {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    pageCount,
    pageSize,
    groups,
  } = useSelector(selectHandoveredCarsState);
  const { isReady } = useRouter();
  const dispatch = useDispatch();

  const _computePayload = (currentPage) => {
    const type = handoverType === 0 ? 'delivery' : 'return';
    const storageName = `dropdown${
      type === 'delivery' ? 'Delivery' : 'Return'
    }FilterRules`;
    const startDate = moment().subtract(1, 'months').valueOf(); // 1 month ago
    const endDate = moment().add(7, 'days').valueOf(); // 7 days from now

    let dropdownFilterRules = localStorage.getItem(storageName);

    if (dropdownFilterRules) {
      dropdownFilterRules = JSON.parse(dropdownFilterRules);
    } else {
      dropdownFilterRules = [];
    }
    dropdownFilterRules.push(
      {
        field: `${type}Date`,
        operator: 4,
        type: 7,
        value: startDate,
      },
      {
        field: `${type}Date`,
        operator: 5,
        type: 7,
        value: endDate,
      }
    );

    let payload = localStorage[`${type}HandoveredCarsQuery`];

    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = [...payload.value, ...dropdownFilterRules];
      } else if (dropdownFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: dropdownFilterRules,
        };
      }
    } else if (!payload && dropdownFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: dropdownFilterRules,
      };
    } else {
      payload = {
        type: FILTER_RULE_ENUMS.EmptyRuleType,
      };
    }

    return {
      handoverType,
      page: currentPage,
      pageSize,
      order: -1,
      orderBy: `${type}Date`,
      ...payload,
    };
  };

  const _getHandoveredCars = useCallback(
    async (currentPage = 1) => {
      const payload = _computePayload(currentPage);

      await dispatch(getHandoveredCars(payload));
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _getMoreHandoveredCars = useCallback(
    async (currentPage) => {
      const payload = _computePayload(currentPage);

      await dispatch(getMoreHandoveredCars(payload));
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchMore = () => {
    const nextPage = page + 1;

    if (pageCount >= nextPage) {
      _getMoreHandoveredCars(nextPage);
    }
  };

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
    return () => dispatch(initState());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getHandoveredCars();
    }
  }, [fetchOnMount, isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    handoverType,
    pageSize,
    groups,
    getHandoveredCars: _getHandoveredCars,
    fetchMore,
    changePage,
  };
};
