import { request } from '@utils';

const getAvailabilities = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = 100;
  }
  const results = await request.get(`/v1/graphs/availability`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getAvailabilities };
