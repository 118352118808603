import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { roadsideAssistanceServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { asyncForEach } from '@utils';
import {
  handleRoadsideAssistancesData,
  handleRoadsideAssistanceData,
} from './roadsideAssistance.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  roadsideAssistances: [],
  roadsideAssistance: {},
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const roadsideAssistanceSlice = createSlice({
  name: 'roadsideAssistance',
  initialState,
  reducers: {
    fetchRoadsideAssistances: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchRoadsideAssistancesSuccess: (state, { payload }) => {
      state.roadsideAssistances = handleRoadsideAssistancesData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.roadsideAssistances.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.roadsideAssistances?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchRoadsideAssistancesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchRoadsideAssistance: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchRoadsideAssistanceSuccess: (state, { payload }) => {
      state.roadsideAssistance = handleRoadsideAssistanceData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchRoadsideAssistanceFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchRoadsideAssistances,
  fetchRoadsideAssistancesSuccess,
  fetchRoadsideAssistancesFailure,
  fetchRoadsideAssistance,
  fetchRoadsideAssistanceSuccess,
  fetchRoadsideAssistanceFailure,
} = roadsideAssistanceSlice.actions;

export const selectRoadsideAssistancesState = (state) =>
  state.roadsideAssistance;

export const getRoadsideAssistances = (payload) => {
  return async (dispatch) => {
    dispatch(fetchRoadsideAssistances());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await roadsideAssistanceServices?.getRoadsideAssistances(
        payload
      );
      response.meta.page = page;

      dispatch(fetchRoadsideAssistancesSuccess(response));
    } catch (error) {
      dispatch(fetchRoadsideAssistancesFailure(error));
    }
  };
};

export const deleteRoadsideAssistances = (payload, page) => {
  return async (dispatch) => {
    dispatch(fetchRoadsideAssistances());

    try {
      await asyncForEach(payload, async (roadsideAssistanceId) => {
        await roadsideAssistanceServices?.deleteRoadsideAssistance(
          roadsideAssistanceId
        );
      });

      dispatch(getRoadsideAssistances(page));
    } catch (error) {
      dispatch(fetchRoadsideAssistancesFailure(error));
    }
  };
};

export const getRoadsideAssistance = (id) => {
  return async (dispatch) => {
    dispatch(fetchRoadsideAssistance());

    try {
      const details = await roadsideAssistanceServices?.getRoadsideAssistance(
        id
      );

      dispatch(fetchRoadsideAssistanceSuccess(details?.data));
    } catch (error) {
      dispatch(fetchRoadsideAssistanceFailure(error));
    }
  };
};

export default roadsideAssistanceSlice.reducer;
