import { ORDERS_STATUS, TRANSACTIONS_STATUS } from '@constants';
import { fixedNumber, formatAmount, formatDate } from '@utils';

export const handleOrders = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      id: item.id,
      userId: item.userId,
      status: ORDERS_STATUS?.[item?.status] || '-',
      totalOfferAmount: formatAmount(fixedNumber(item?.totalPrice, 2)),
      receipt: item?.invoice || '-',
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
    };
    newArray[index] = row;
    return newArray;
  });
  return array;
};

const handleDetails = (details) => {
  if (!details) return '-';
  let detailsString = '';

  if (details?.location) {
    detailsString = details?.location;
  }
  if (details?.monthsDuration) {
    detailsString = `${details?.location} ${details?.monthsDuration} Month${
      details?.monthsDuration === 1 ? '' : 's'
    }`;
  }

  return detailsString;
};

export const handleOrder = (order) => {
  const sections = {
    userInformation: {
      userId: {
        label: 'User ID:',
        renderedValue: order?.userId || '-',
        link: `/users/${order?.userId}`,
        target: '_blank',
        onEditField: null,
      },
      firstName: {
        label: 'Name:',
        renderedValue: `${order?.billingInfo?.firstName || '-'}`,
      },
      lastName: {
        label: 'Surname:',
        renderedValue: `${order?.billingInfo?.lastName || '-'}`,
      },
      phoneNumber: {
        label: 'Phone:',
        renderedValue: `${order?.billingInfo?.phoneNumber || '-'}`,
      },
      email: {
        label: 'Email:',
        renderedValue: `${order?.billingInfo?.email || '-'}`,
      },
      contract: {
        label: 'Contract:',
        renderedValue: `${
          order?.billingInfo?.activity ? 'Επιχείρηση' : 'Ιδιώτης'
        }`,
      },
      description: {
        label: 'Description:',
        renderedValue: `-`,
      },
    },
    billingInformation: {
      vat: {
        label: 'VAT:',
        renderedValue: `${order?.billingInfo?.vat || '-'}`,
      },
      companyName: {
        label: 'Company Name:',
        renderedValue: `${order?.billingInfo?.companyName || '-'}`,
      },
      doi: {
        label: 'Tax Authority:',
        renderedValue: `${order?.billingInfo?.doi || '-'}`,
      },
      activity: {
        label: 'Activity:',
        renderedValue: `${order?.billingInfo?.activity || '-'}`,
      },
      address: {
        label: 'Address:',
        renderedValue: `${order?.billingInfo?.address || '-'}`,
      },
      postalCode: {
        label: 'Postal Code:',
        renderedValue: `${order?.billingInfo?.postalCode || '-'}`,
      },
    },
    orderInformation: {
      orderId: {
        label: 'Order ID:',
        renderedValue: `${order?.id || '-'}`,
      },
      createdAt: {
        label: 'Order Date:',
        renderedValue: order.createdAt
          ? formatDate(order.createdAt, 'DD/MM/YYYY')
          : '-',
      },
      status: {
        label: 'Order Status:',
        renderedValue: `${ORDERS_STATUS?.[order?.status] || '-'}`,
      },
      discount: {
        label: 'Discount:',
        renderedValue: order?.discount
          ? `${Number(order?.discount) * 100}%`
          : '-',
      },
      totalOfferAmount: {
        label: 'Final Price:',
        renderedValue: order?.totalPrice
          ? formatAmount(fixedNumber(order?.totalPrice))
          : '-',
      },
      invoice: {
        label: 'Receipt:',
        renderedValue: `${order?.invoice?.filename || '-'}`,
        link: order?.invoice ? `${order?.invoice?.url}` : null,
        target: '_blank',
        onEditField: null,
      },
    },

    ...order,
  };

  const products = [];
  order?.items?.forEach((item) => {
    products[item?.skuId] = {
      skuId: {
        label: 'SKU:',
        renderedValue: `${item?.skuId || '-'}`,
      },
      product: {
        label: 'Product:',
        renderedValue: `${item?.productId || '-'}`,
      },
      category: {
        label: 'Vehicle Category:',
        renderedValue: item?.details?.vehicleType,
      },
      details: {
        label: 'Details:',
        renderedValue: handleDetails(item?.details),
      },
      price: {
        label: 'Price:',
        renderedValue: item?.price
          ? formatAmount(fixedNumber(item?.price))
          : `-`,
      },
    };
  });
  sections.products = products;
  return sections;
};

export const handleTransactions = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      ...item,
      providerTransactionId: item.providerTransactionId,
      userId: item.userId,
      status: TRANSACTIONS_STATUS?.[item?.status] || '-',
      failureReason: item?.failureReason || '-',
      amount: formatAmount(fixedNumber(item?.amount)) || '-',
      refundedAmmount: item?.amount,
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
    };
    newArray[index] = row;
    return newArray;
  });
  return array;
};
