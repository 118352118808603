import { request } from '@utils';
import { LIMIT } from '@constants';

const getInsurances = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/insurances`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getInsurancesEnums = async () => {
  const results = await request.get(`/v1/insurances/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getInsurance = async (id) => {
  const results = await request.get(`/v1/insurances/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteInsurance = async (id) => {
  const results = await request.remove(`/v1/insurances/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postInsurance = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/insurances`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editInsuranceById = async (insuranceId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/insurances/${insuranceId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getInsurances,
  getInsurance,
  deleteInsurance,
  postInsurance,
  getInsurancesEnums,
  editInsuranceById,
};
