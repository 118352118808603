// eslint-disable-next-line import/no-extraneous-dependencies
const slugifyLib = require('slugify');

const slugify = (str) => {
  slugifyLib.extend({ '@': 'at', '*': '-' });
  return slugifyLib(str, {
    replacement: '-',
    lower: true,
    strict: true,
  });
};

export default slugify;
