import {
  RESERVATIONS_SUBSCRIPTION_TYPES,
  RESERVATIONS_TYPES,
} from '@constants';
import { formatDate } from '@utils';

export const handleReservations = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      ...item,
      vehicleInternalId: item?.vehicleInternalId || '-',
      vehicleName: item?.vehicleName || '-',
      vehiclePlate: item?.vehiclePlate || '-',
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      subscriptionType:
        RESERVATIONS_SUBSCRIPTION_TYPES[item.subscriptionType] || '-',
      subscriptionId: item?.subscriptionId || '-',
      comments: item?.comments?.length
        ? item.comments.map((comment) => comment.message).join(', ')
        : '-',
    };
    newArray[index] = row;

    return newArray;
  });
  return array;
};

export const handleReservationData = (data) => {
  return {
    ...data,
    createdAt: data.createdAt ? formatDate(data.createdAt, 'DD/MM/YYYY') : '-',
    updatedAt: data.updatedAt ? formatDate(data.updatedAt, 'DD/MM/YYYY') : '-',
    comments: data?.comments?.map((item) => {
      return {
        ...item,
        date: item.createdAt
          ? formatDate(item.createdAt, 'DD/MM/YYYY hh:mm')
          : '-',
        time: ' ',
        name: item?.createdBy,
        comment: item?.message,
      };
    }),
  };
};

export const handleReservationHistory = (data) => {
  if (!data) {
    return [];
  }
  const history = data
    ?.map((item) => {
      const isType = item?.field?.[0] === 'Type';

      if (item?.field?.[0] === 'Comments') return null;

      if (isType) {
        const oldValue = Object.values(RESERVATIONS_TYPES).find(
          (type) => type.value === item?.from
        )?.text;
        const newValue = Object.values(RESERVATIONS_TYPES).find(
          (type) => type.value === item?.to
        )?.text;

        return {
          ...item,
          createdBy: item?.actor,
          fieldName: item?.field?.[0] || '',
          newValue,
          oldValue,
        };
      }

      return {
        ...item,
        createdBy: item?.actor,
        fieldName: item?.field?.[0] || '',
        newValue: item?.to || '',
        oldValue: item?.from || '-',
      };
    })
    ?.filter((item) => item);
  return history;
};
