import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { fleetVehiclesServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  dealers: [],
  sourcingTypes: [],
  colors: [],
};
// Actual Slice
export const addFleetVehicleSlice = createSlice({
  name: 'addFleetVehicle',
  initialState,
  reducers: {
    initializeAddFleetVehicleState: (state) => {
      state.colors = [];
      state.sourcingTypes = [];
      state.dealers = [];
    },
    fetchData: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchDataSuccess: (state) => {
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    setFleetColorsDataFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    setFleetSourcingTypes: (state, { payload }) => {
      state.sourcingTypes = payload?.sourcingTypes.map((sourcingType) => {
        return {
          value: sourcingType,
          text: sourcingType,
        };
      });
    },
    setFleetDealers: (state, { payload }) => {
      state.dealers = payload?.dealers.map((dealer) => {
        return {
          value: dealer.slug,
          text: dealer.name,
        };
      });
    },
    setFleetColors: (state, { payload }) => {
      state.colors = payload?.colors.map((item) => {
        return {
          value: item.slug,
          text: item.name,
        };
      });
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  setFleetVhiclesSuccess,
  setFleetColorsDataFailureSuccess,
  setFleetColorsDataFailure,
  setFleetSourcingTypes,
  setFleetDealers,
  setFleetColors,
  initializeAddFleetVehicleState,
} = addFleetVehicleSlice.actions;

export const selectAddFleetVehicleState = (state) => state.addFleetVehicle;

export const getFleetSourcingTypes = () => {
  return async (dispatch) => {
    try {
      const sourcingTypes =
        await fleetVehiclesServices.getVehicleSourcingTypes();
      dispatch(
        setFleetSourcingTypes({
          sourcingTypes: sourcingTypes?.data,
        })
      );
    } catch (error) {
      dispatch(setFleetColorsDataFailure(error));
    }
  };
};

export const getFleetDealers = () => {
  return async (dispatch) => {
    try {
      const dealers = await fleetVehiclesServices.getVehicleDealers();
      dispatch(
        setFleetDealers({
          dealers: dealers?.data,
        })
      );
    } catch (error) {
      dispatch(setFleetColorsDataFailure(error));
    }
  };
};

export const getFleetColors = () => {
  return async (dispatch) => {
    try {
      const colors = await fleetVehiclesServices?.getVehicleColors();

      dispatch(
        setFleetColors({
          colors: colors?.data,
        })
      );
    } catch (error) {
      dispatch(setFleetColorsDataFailure(error));
    }
  };
};

export default addFleetVehicleSlice.reducer;
