import { useEffect } from 'react';

import { showError, flatternErrors } from '@utils';

export const useFormErrorsNotification = (errors) => {
  useEffect(() => {
    const newErrorList = flatternErrors(errors, []);

    if (newErrorList?.length) {
      showError(
        `Please update the following fields: ${newErrorList.join(', ')}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);
};

export default useFormErrorsNotification;
