import { AGENT_ROLES_OPTIONS } from '@constants';
import { formatDate } from '@utils';

export const handleTeamsData = (array, agents) => {
  if (!array) {
    return [];
  }

  const roles = Object.values(AGENT_ROLES_OPTIONS)
    ?.map((role) => role?.value)
    ?.join(', ');
  const agentMapping = (email) => {
    const agent = agents?.find((item) => item?.email === email);
    return `${agent?.firstName} ${agent?.lastName}`;
  };

  array?.forEach((item, index, newArray) => {
    const row = {
      id: item?.id || '-',
      name: item?.name || '-',
      members: item?.members,
      membersByRole: item?.members?.filter((member) => {
        return roles.includes(member) ? member : null;
      }),
      membersByName: item?.members?.filter((member) => {
        return roles.includes(member) ? null : member;
      }),
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      createdBy: item?.createdBy ? agentMapping(item?.createdBy) : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
      updatedBy: item?.updatedBy ? agentMapping(item?.updatedBy) : '-',
    };
    newArray[index] = row;

    return newArray;
  });

  return array;
};

export const handleTeamData = (data) => {
  const roles = Object.values(AGENT_ROLES_OPTIONS);

  data.createdAt = data?.createdAt
    ? formatDate(data?.createdAt, 'DD/MM/YYYY, HH:mm')
    : '';
  data.updatedAt = data?.updatedAt
    ? formatDate(data?.updatedAt, 'DD/MM/YYYY, HH:mm')
    : '';
  data.membersByRole = data?.members
    ?.filter((member) => roles.some((role) => role?.value === member))
    ?.map((member) => {
      const role = roles.find((r) => r?.value === member);
      return { text: role?.text, value: member };
    });
  data.membersByName = data?.members
    ?.filter((member) => {
      return !roles.find((role) => role?.value === member);
    })
    ?.map((member) => ({ text: member, value: member }));

  return data;
};

export const handleAgentsData = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      text: item?.firstName
        ? `${item?.firstName} ${item?.lastName}`
        : item?.email,
      value: item?.email,
      role: AGENT_ROLES_OPTIONS[`is${item?.role}`]?.text || '',
    };
    newArray[index] = row;

    return newArray;
  });

  return array?.sort((a, b) => a.text.localeCompare(b.text));
};
