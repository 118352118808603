import React, { useCallback, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Box } from '@mui/material';
import { useClickOutside } from '@hooks';

const PopoverColorPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);
  return (
    <Box className="picker">
      <Box
        className="swatch"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <Box className="popover" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
          <HexColorInput color={color} onChange={onChange} />
        </Box>
      )}
    </Box>
  );
};

const memoizedPopoverColorPicker = React.memo(PopoverColorPicker);
export { memoizedPopoverColorPicker as PopoverColorPicker };

export default memoizedPopoverColorPicker;
