import dynamic from 'next/dynamic';

const SubscriptionsGrid = dynamic(() =>
  import('./SubscriptionsGrid/SubscriptionsGrid')
);
const FeesGrid = dynamic(() => import('./FeesGrid/FeesGrid'));
const AddFeeModal = dynamic(() => import('./AddFeeModal/AddFeeModal'));
const AddDriverModal = dynamic(() => import('./AddDriverModal/AddDriverModal'));
const VehicleDetails = dynamic(() => import('./VehicleDetails/VehicleDetails'));
const VehicleControlList = dynamic(() =>
  import('./VehicleControlList/VehicleControlList')
);
const HandoverFinalChecklist = dynamic(() =>
  import('./HandoverFinalChecklist/HandoverFinalChecklist')
);
const DriversGrid = dynamic(() => import('./DriversGrid/DriversGrid'));
const SubscriptionsFilters = dynamic(() =>
  import('./SubscriptionsFilters/SubscriptionsFilters')
);
const DeliveryReturnPlanModal = dynamic(() =>
  import('./DeliveryReturnPlanModal/DeliveryReturnPlanModal')
);

const SubscriprionTabs = dynamic(() =>
  import('./SubscriprionTabs/SubscriprionTabs')
);

const DeliveryAndReturn = dynamic(() =>
  import('./DeliveryAndReturn/DeliveryAndReturn')
);
const SubscriptionsDocumentsGrid = dynamic(() =>
  import('./SubscriptionDocumentsGrid/SubscriptionDocumentsGrid')
);

const LinkedSubscriptions = dynamic(() =>
  import('./LinkedSubscriptions/LinkedSubscriptions')
);

const AddDocumentModal = dynamic(() =>
  import('./AddDocumentModal/AddDocumentModal')
);
const History = dynamic(() => import('./History/History'));
const Fees = dynamic(() => import('./Fees/Fees'));
const InspectionDeliveryReturnPlanModal = dynamic(() =>
  import(
    './InspectionDeliveryReturnPlanModal/InspectionDeliveryReturnPlanModal'
  )
);

export {
  SubscriptionsGrid,
  FeesGrid,
  AddFeeModal,
  AddDriverModal,
  VehicleDetails,
  VehicleControlList,
  HandoverFinalChecklist,
  DriversGrid,
  SubscriptionsFilters,
  DeliveryReturnPlanModal,
  SubscriprionTabs,
  DeliveryAndReturn,
  SubscriptionsDocumentsGrid,
  LinkedSubscriptions,
  AddDocumentModal,
  History,
  Fees,
  InspectionDeliveryReturnPlanModal,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
