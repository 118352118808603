import { createSlice, current } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep } from 'lodash';

import { fleetVehiclesServices, servicePointsServices } from '@services';
import {
  SERVICE_POINT_STATUSES,
  GENERIC_ERROR_MESSAGE,
  SERVICE_POINTS_PAGE_SIZE,
} from '@constants';
import {
  handleVehicle,
  addInsurances,
  addRoadsideAssistance,
  addColors,
  addServicePoints,
  addServicePointsAll,
  addDealers,
  addFinancingProviders,
  addHistory,
} from './fleetVehicle.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  vehicle: null,
  insurances: [],
  servicePoints: [],
  servicePointsAll: [],
  dealers: [],
  financingProviders: [],
  sourcingTypes: [],
  history: [],
  changelog: {
    loading: false,
    errorMessage: '',
    data: [],
  },
};

// Actual Slice
export const fleetVehicleSlice = createSlice({
  name: 'fleetVehicle',
  initialState,
  reducers: {
    initialize: (state) => {
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.vehicle = null;
      state.insurances = [];
      state.servicePoints = [];
      state.servicePointsAll = [];
      state.dealers = [];
      state.financingProviders = [];
      state.sourcingTypes = [];
      state.changelog = initialState.changelog;
    },
    fetchFleetVehicleSuccess: (state, { payload }) => {
      state.vehicle = handleVehicle(
        payload?.vehicle,
        payload?.servicePointsAll
      );
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchFleetVehicleFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchFleetVehicleFinancingProviders: (state, { payload }) => {
      const vehicle = cloneDeep(current(state.vehicle));
      state.vehicle = addFinancingProviders(
        vehicle,
        payload?.financingProviders
      );
      state.financingProviders = payload?.financingProviders;
    },
    fetchFleetVehicleSourcingTypes: (state, { payload }) => {
      state.sourcingTypes = payload?.sourcingTypes;
    },
    fetchFleetVehicleDealers: (state, { payload }) => {
      let vehicle = null;
      if (state?.vehicle) {
        vehicle = cloneDeep(current(state.vehicle));
      }
      state.vehicle = addDealers(vehicle, payload?.dealers);
      state.dealers = payload?.dealers;
    },

    fetchFleetVehicleServicePointsAll: (state, { payload }) => {
      const vehicle = cloneDeep(current(state.vehicle));
      state.vehicle = addServicePointsAll(vehicle, payload?.servicePointsAll);
      state.servicePointsAll = payload?.servicePointsAll;
    },
    fetchFleetVehicleServicePoints: (state, { payload }) => {
      const vehicle = cloneDeep(current(state.vehicle));
      state.vehicle = addServicePoints(vehicle, payload?.servicePoints);
      state.servicePoints = payload?.servicePoints;
    },

    fetchFleetVehicleColors: (state, { payload }) => {
      let vehicle = null;
      if (state.vehicle) {
        vehicle = cloneDeep(current(state.vehicle));
      }
      state.vehicle = addColors(vehicle, payload?.colors);
      state.colors = payload?.colors;
    },
    fetchFleetVehicleRoadsideAssistanceSuccess: (state, { payload }) => {
      const vehicle = cloneDeep(current(state.vehicle));
      state.vehicle = addRoadsideAssistance(
        vehicle,
        payload?.roadsideAssistance
      );
    },
    fetchFleetVehicleInsurancesSuccess: (state, { payload }) => {
      const vehicle = cloneDeep(current(state.vehicle));
      state.vehicle = addInsurances(vehicle, payload?.insurances);
      state.insurances = payload?.insurances;
    },
    fetchFleetVehicleHistory: (state, { payload }) => {
      const vehicle = cloneDeep(current(state.vehicle));
      state.vehicle = addHistory(vehicle, payload?.history);
      state.history = payload?.history;
    },
    fetchFleetVehicleChangelog: (state) => {
      state.changelog.loading = true;
      state.changelog.errorMessage = '';
    },
    fetchFleetVehicleChangelogSuccess: (state, { payload }) => {
      state.changelog.loading = false;
      state.changelog.data = payload;
    },
    fetchFleetVehicleChangelogFailure: (state, { payload }) => {
      state.changelog.loading = false;
      state.changelog.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchFleetVehicleSuccess,
  fetchFleetVehicleFailure,
  initialize,
  fetchFleetVehicleInsurancesSuccess,
  fetchFleetVehicleRoadsideAssistanceSuccess,
  fetchFleetVehicleColors,
  fetchFleetVehicleServicePoints,
  fetchFleetVehicleServicePointsAll,
  fetchFleetVehicleDealers,
  fetchFleetVehicleFinancingProviders,
  fetchFleetVehicleSourcingTypes,
  fetchFleetVehicleHistory,
  fetchFleetVehicleChangelog,
  fetchFleetVehicleChangelogFailure,
  fetchFleetVehicleChangelogSuccess,
} = fleetVehicleSlice.actions;

export const selectFleetVehicleState = (state) => state.fleetVehicle;

export const getFleetVehicle = ({ vehicleId }) => {
  return async (dispatch) => {
    try {
      const vehicle = await fleetVehiclesServices?.getVehicleById(vehicleId);
      const servicePointsAll = await servicePointsServices.getServicePoints({
        page: 1,
        pageSize: 1000,
      });

      dispatch(
        fetchFleetVehicleSuccess({
          vehicle: vehicle?.data,
          insurances: [],
          colors: [],
          roadsideAssistance: [],
          servicePoints: [],
          servicePointsAll: servicePointsAll?.data,
          financingProviders: [],
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleFinancingProviders = () => {
  return async (dispatch) => {
    try {
      const financingProviders =
        await fleetVehiclesServices?.getVehicleProviders();
      dispatch(
        fetchFleetVehicleFinancingProviders({
          financingProviders: financingProviders?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleSourcingTypes = () => {
  return async (dispatch) => {
    try {
      const sourcingTypes =
        await fleetVehiclesServices.getVehicleSourcingTypes();
      dispatch(
        fetchFleetVehicleSourcingTypes({
          sourcingTypes: sourcingTypes?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleDealers = () => {
  return async (dispatch) => {
    try {
      const dealers = await fleetVehiclesServices.getVehicleDealers();
      dispatch(
        fetchFleetVehicleDealers({
          dealers: dealers?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleServicePointsAll = () => {
  return async (dispatch) => {
    try {
      const servicePointsAll = await servicePointsServices.getServicePoints({
        page: 1,
        pageSize: SERVICE_POINTS_PAGE_SIZE,
        type: 0,
        field: null,
        operator: null,
        value: [
          {
            field: 'status',
            operator: 0,
            type: 5,
            value: SERVICE_POINT_STATUSES.active, // fetch only acticated
          },
        ],
      });

      dispatch(
        fetchFleetVehicleServicePointsAll({
          servicePointsAll: servicePointsAll?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleServicePoints = (payload) => {
  return async (dispatch) => {
    try {
      const servicePoints =
        await fleetVehiclesServices?.getVehicleServicePointsByID(payload);

      dispatch(
        fetchFleetVehicleServicePoints({
          servicePoints: servicePoints?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleColor = () => {
  return async (dispatch) => {
    try {
      const colors = await fleetVehiclesServices?.getVehicleColors();

      dispatch(
        fetchFleetVehicleColors({
          colors: colors?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleRoadsideAssistance = () => {
  return async (dispatch) => {
    try {
      const roadsideAssistance =
        await fleetVehiclesServices?.getRoadsideAssistances();

      dispatch(
        fetchFleetVehicleRoadsideAssistanceSuccess({
          roadsideAssistance: roadsideAssistance?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleInsurances = () => {
  return async (dispatch) => {
    try {
      const insurances = await fleetVehiclesServices?.getInsurances();

      dispatch(
        fetchFleetVehicleInsurancesSuccess({
          insurances: insurances?.data,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleHistory = (plate) => {
  return async (dispatch) => {
    try {
      const history = await fleetVehiclesServices.getVehicleHistory(plate);

      dispatch(
        fetchFleetVehicleHistory({
          history,
        })
      );
    } catch (error) {
      dispatch(fetchFleetVehicleFailure(error));
    }
  };
};

export const getFleetVehicleChangelog = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFleetVehicleChangelog());

      const { data } = await fleetVehiclesServices.getVehicleChangelog(id);
      dispatch(fetchFleetVehicleChangelogSuccess(data));
    } catch (error) {
      dispatch(fetchFleetVehicleChangelogFailure(error));
    }
  };
};

export default fleetVehicleSlice.reducer;
