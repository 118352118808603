import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectInsurancesState, getInsurances, deleteInsurances } from '@store';
import { showError } from '@utils';

export const useInsurancesOperations = (fetchOnMount) => {
  const { total, loading, insurances, pageSize, hasErrors, errorMessage } =
    useSelector(selectInsurancesState);
  const {
    push,
    query: { page = 1, orderBy = 'internalId', order = 'asc', token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _deleteInsurances = useCallback(
    async (insurancesIds) => {
      await dispatch(deleteInsurances(insurancesIds, { orderBy, order, page }));
    },
    [dispatch, page, orderBy, order]
  );

  const _getInsurances = useCallback(async () => {
    await dispatch(getInsurances({ orderBy, order, page }));
  }, [page, orderBy, order, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/insurances?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/insurances?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/insurances?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/insurances?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getInsurances();
    }
  }, [_getInsurances, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    insurances,
    pageSize,
    hasErrors,
    errorMessage,
    getInsurances: _getInsurances,
    nextPage: _nextPage,
    deleteInsurances: _deleteInsurances,
    sortBy: _sortBy,
  };
};
