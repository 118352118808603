import { request } from '@utils';

const functionalityRemoved = 'Functionality has been removed';

const addSubscription = async ({ subscriptionId, body }) => {
  const params = {
    body,
  };

  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/subscribers`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const patchSubscriberVATBySubscriptionId = () => {
  return Promise.reject(functionalityRemoved);
};

const patchSubscriberTelephoneBySubscriptionId = () => {
  return Promise.reject(functionalityRemoved);
};

const patchSubscriberAddressBySubscriptionId = () => {
  return Promise.reject(functionalityRemoved);
};

export {
  patchSubscriberVATBySubscriptionId,
  patchSubscriberTelephoneBySubscriptionId,
  patchSubscriberAddressBySubscriptionId,
  addSubscription,
};
