import dynamic from 'next/dynamic';

const InsurancesGrid = dynamic(() => import('./InsurancesGrid/InsurancesGrid'));
const AddInsuranceModal = dynamic(() =>
  import('./AddInsuranceModal/AddInsuranceModal')
);
const InsuranceDetails = dynamic(() =>
  import('./InsuranceDetails/InsuranceDetails')
);
const EditInsuranceFieldsModal = dynamic(() =>
  import('./EditInsuranceFieldsModal/EditInsuranceFieldsModal')
);

export {
  InsurancesGrid,
  AddInsuranceModal,
  InsuranceDetails,
  EditInsuranceFieldsModal,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
