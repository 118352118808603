export const handleConfigurationData = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      text: item?.name,
      value: JSON.stringify({
        slug: item?.slug,
        name: item?.name,
        json: item?.value,
      }),
    };
    newArray[index] = row;

    return newArray;
  });
  array.push({
    text: 'New configuration',
    value: JSON.stringify({
      name: 'New configuration',
    }),
  });
  return array;
};
