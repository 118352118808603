import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCampaignsState,
  getCampaign,
  addCampaign,
  initializePostCampaign,
  editCampaign,
} from '@store';
import { showError } from '@utils';

export const useCampaignOperations = () => {
  const {
    loading,
    campaign,
    hasErrors,
    errorMessage,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
  } = useSelector(selectCampaignsState);
  const {
    query: { campaignId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getCampaign = useCallback(async () => {
    await dispatch(getCampaign(campaignId));
  }, [dispatch, campaignId]);

  const _postCampaign = useCallback(
    async (payload) => {
      await dispatch(addCampaign(payload));
    },
    [dispatch]
  );

  const _editCampaign = useCallback(
    async (payload) => {
      await dispatch(editCampaign(payload));
    },
    [dispatch]
  );

  const _initializePostCampaign = useCallback(async () => {
    await dispatch(initializePostCampaign());
  }, [dispatch]);

  useEffect(() => {
    if (campaignId) {
      _getCampaign();
    }
  }, [campaignId, _getCampaign]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (postErrorMessage || errorMessage) {
      showError(postErrorMessage || errorMessage);
    }
  }, [postErrorMessage, errorMessage]);

  return {
    loading,
    campaign,
    hasErrors,
    errorMessage,
    getCampaign: _getCampaign,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
    campaignId,
    postCampaign: _postCampaign,
    initializePostCampaign: _initializePostCampaign,
    editCampaign: _editCampaign,
  };
};
