import { useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { overviewServices } from '@services';
import { showError } from '@utils';

export const useOverviewOperations = () => {
  const {
    query: { token },
  } = useRouter();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState('');
  const [data, setData] = useState([]);

  const _getDailyBookings = useCallback(async () => {
    try {
      setLoading(true);
      const dailyBookings = await overviewServices.getDailyBookings();
      setData(dailyBookings?.data?.reverse());
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    _getDailyBookings();
  }, [_getDailyBookings, token]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    data,
    errorMessage,
    getDailyBookings: _getDailyBookings,
    token,
  };
};
