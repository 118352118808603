import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgentsState, getAgents } from '@store';
import { showError } from '@utils';
import { getAgentsUrl, getCleanedUrlAgentsParams } from 'helpers';

export const useAgentsOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    agents,
    pageCount,
    hasErrors,
    errorMessage,
    pageSize,
  } = useSelector(selectAgentsState);
  const {
    push,
    query: { page = '1', token, role },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getAgents = useCallback(
    async (params) => {
      await dispatch(
        getAgents(getCleanedUrlAgentsParams({ page, pageSize, ...params }))
      );
    },
    [page, pageSize, dispatch]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      const params = {
        page: currentPage,
        role,
      };
      const url = getAgentsUrl(params);

      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
      _getAgents(params);
    },
    [push, _getAgents, role]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getAgents();
    }
  }, [isReady, fetchOnMount, _getAgents]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    pageCount,
    loading,
    agents,
    hasErrors,
    errorMessage,
    getAgents: _getAgents,
    nextPage: _nextPage,
    token,
  };
};
