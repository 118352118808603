import { mediaServices } from '@services';
import { showError } from '@utils';
import { useState } from 'react';

export const useVehicleDocuments = ({
  vehicle,
  callback,
  variant = 'vehicle',
}) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const getDocuments = () => {
    const { docs: vehicleDocuments } = vehicle[variant];
    callback(vehicleDocuments);
  };

  const handleDeleteDocument = (doc) => {
    callback((prevState) =>
      prevState.filter((docItem) => docItem.id !== doc.id)
    );
  };

  const handleAddDocument = async (newDocuments) => {
    const entityId =
      variant === 'vehicle'
        ? `IC${vehicle?.[variant]?.skuId}`
        : `ICB${vehicle?.[variant]?.skuId}`;
    const postDocument = (doc) =>
      mediaServices.postDoc({
        file: doc.file,
        entityType: 'catalog',
        entityId,
        documentType: '26', // Other
        publicAccess: true,
      });
    setIsLoadingDocuments(true);

    try {
      const results = await Promise.all(newDocuments.map(postDocument));

      if (results) {
        results.forEach((result, index) => {
          newDocuments[index].id = result?.data?.[0];
        });
        callback((prevState) => [...prevState, ...newDocuments]);
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsLoadingDocuments(false);
    }
  };

  return {
    getDocuments,
    handleDeleteDocument,
    handleAddDocument,
    isLoadingDocuments,
  };
};
