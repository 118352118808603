import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersState, getOrders } from '@store';
import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';

export const useOrdersOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    orders,
    firstItem,
    lastItem,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectOrdersState);
  const {
    push,
    query: {
      page = 1,
      orderBy = 'createdAt',
      order = 'desc',
      token,
      ...others
    },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _getOrders = useCallback(async () => {
    let payload = localStorage.marketOrderssQuery;
    let { ordersDropdownFilterRules } = localStorage;
    if (ordersDropdownFilterRules) {
      ordersDropdownFilterRules = JSON.parse(ordersDropdownFilterRules);
    } else {
      ordersDropdownFilterRules = [];
    }
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = [...payload.value, ...ordersDropdownFilterRules];
      } else if (ordersDropdownFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: ordersDropdownFilterRules,
        };
      }
    } else if (!payload && ordersDropdownFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: ordersDropdownFilterRules,
      };
    } else {
      payload = {
        type: FILTER_RULE_ENUMS.EmptyRuleType,
      };
    }
    await dispatch(getOrders({ orderBy, order, page, ...payload }));
  }, [page, orderBy, order, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/orders?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/orders?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/orders?&page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/orders?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getOrders();
    }
  }, [_getOrders, token, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    orders,
    firstItem,
    lastItem,
    pageCount,
    hasErrors,
    errorMessage,
    getOrders: _getOrders,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
