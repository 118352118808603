const formatAmount = (value) => {
  if (
    value === '' ||
    value === null ||
    value === undefined ||
    value === '0.00' ||
    value === 0
  ) {
    return '-';
  }

  return `${value}€`;
};

export default formatAmount;
