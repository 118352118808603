import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import theme from 'config/theme';

export const GREEN_CARD_TASK_STATUSES_NAMES = {
  0: {
    label: 'Backlog',
    styles: {
      backgroundColor: theme.palette.grays.gray300,
      color: theme.palette.grays.gray900,
    },
  },
  22: {
    label: 'Contact Client',
    styles: {
      backgroundColor: theme.palette.violets.violet200,
      color: theme.palette.violets.violet900,
    },
  },
  23: {
    label: 'Customer Affirmation',
    styles: {
      backgroundColor: theme.palette.blues.babyBlue200,
      color: theme.palette.blues.babyBlue900,
    },
  },
  2: {
    label: 'In Progress',
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  25: {
    label: 'Mail',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
    },
  },
  26: {
    label: 'Vehicle License Return',
    styles: {
      backgroundColor: theme.palette.pinks.pink200,
      color: theme.palette.pinks.pink900,
    },
  },
  3: {
    label: 'Done',
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.greens900,
    },
  },
  27: {
    label: 'Rejected',
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
    },
  },
  12: {
    label: 'Canceled',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
};

export const GREEN_CARD_APPROVAL_OPTIONS = [
  {
    text: 'Approved',
    value: true,
    icon: <CheckBoxIcon color="approved" />,
  },
  {
    text: 'Rejected',
    value: false,
    icon: <CancelIcon color="rejected" />,
  },
  {
    text: 'Pending',
    value: null,
    icon: <RemoveCircleIcon color="pending" />,
    disabled: true,
  },
];

export const GREEN_CARD_CONTROLED_PARKING_OPTIONS = [
  {
    text: 'Yes',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
  {
    text: 'Pending',
    value: null,
    disabled: true,
  },
];

export const GREEN_CARD_TASK_FIELD_NAMES = {
  affirmationProgress: 'Affirmation Progress',
  approval: 'Approval',
  controlledParking: 'Controlled Parking',
  country: 'Country',
  developmentProgress: 'Development Progress',
  endDate: 'Request Ending Date',
  mixedInsuranceExtensionPrice: 'Mixed Insurance Extension Price',
  rejectedBy: 'Rejected By',
  roadsideAssistanceFee: 'Roadside Assistance Fee',
  startDate: 'Request Starting Date',
  'AffirmationProgress.declarationOfReceiptOfVehicleRegistration':
    'Declaration of Receipt of Vehicle Registration',
  'AffirmationProgress.driversLicenseId': 'Driver License ID',
  'AffirmationProgress.proofOfPayment': 'Proof of Payment',
  'AffirmationProgress.requestOfVehicleRegistration':
    'Request for Vehicle Registration',
  'DevelopmentProgress.billing': 'Billing',
  'DevelopmentProgress.greenCard': 'Green Card',
  'DevelopmentProgress.vehicleRegistrationIssued':
    'Vehicle Registration Issued',
  delivered: 'Delivered',
  returned: 'Returned',
  status: 'Status',
  customerCost: 'Customer Cost',
};
