import {
  LOCATION_ASPROPIRGOS,
  LOCATION_GERAKAS,
} from 'components/subscriptions/constants';

/* eslint-disable no-useless-escape */
export * from './orders';
export * from './users';
export const LIMIT = 40;
export const POLLING = 90000;
export const GENERIC_ERROR_MESSAGE =
  'We are having some issues, we will fix them ASAP';
export const SERVICE_POINTS_PAGE_SIZE = 1000;
export const TENANT_NAMES = {
  0: 'instacar',
  1: 'instaride / kineo',
};
export const DEFAULT_STREET_NAME = 'Πηγάσου';
export const DEFAULT_LOCATION = 'instacar HQ';
export const DEFAULT_STREET_NUMBER = '10';
export const DEFAULT_LOCALITY = 'Μαρούσι';
export const DEFAULT_POSTAL_CODE = '151 25';
export const DEFAULT_REGION = 'Περιφερειακή ενότητα Βορείου Τομέα Αθηνών';
export const DEFAULT_COUNTRY = 'Ελλάδα';
export const DEFAULT_LAT = 38.0325463;
export const DEFAULT_LNG = 23.8052333;

export const GRID_COLUMN_DEFAULT_PROPERTIES = {
  width: 160,
  minWidth: 80,
  headerAlign: 'left',
  align: 'left',
};

export const HANDOVER_CARS_TYPE = {
  DELIVERY: 0,
  RETURNS: 1,
};

export const HANDOVER_CARS_SIDE = {
  FRONT: 0,
  BACK: 1,
  LEFT: 2,
  RIGHT: 3,
  ABOVE: 4,
  INTERIOR: 5,
};

export const HANDOVER_CARS_LOCATIONS = {
  0: DEFAULT_LOCATION,
  1: LOCATION_GERAKAS,
  2: LOCATION_ASPROPIRGOS,
  3: 'instadelivery',
  4: 'Athens International Airport "Eleftherios Venizelos"',
};

export const HANDOVER_CARS_STAGE = {
  0: 'Standard',
  2: 'Closed Contract',
  3: 'Temp',
  4: 'Predel',
};

export const AGENT_ROLES = {
  isAdmin: 'Admin',
  isCustomerCareAgent: 'CustomerCareAgent',
  isFleetOpsAssociate: 'FleetOpsAssociate',
  isProjectManager: 'ProjectManager',
  isSalesConsultant: 'SalesConsultant',
  isSupplyChainAssociate: 'SupplyChainAssociate',
  isPerformanceMarketingSpecialist: 'PerformanceMarketingSpecialist',
  isTreasurer: 'Treasurer',
  isGraphicDesigner: 'GraphicDesigner',
  isControllingManager: 'ControllingManager',
  isHeadOfMarketing: 'HeadOfMarketing',
  isAssistantAccountant: 'AssistantAccountant',
  isInsuranceSpecialist: 'InsuranceSpecialist',
  isSocialMediaManager: 'SocialMediaManager',
  isSeniorSalesConsultant: 'SeniorSalesConsultant',
  isCustomerSuccessCoordinator: 'CustomerSuccessCoordinator',
  isAccountant: 'Accountant',
  isARMTeamLead: 'ARMTeamLead',
  isProductDesigner: 'ProductDesigner',
  isCreditManager: 'CreditManager',
  isOperationsDirector: 'OperationsDirector',
  isAccidentsSeniorAssociate: 'AccidentsSeniorAssociate',
  isContentMarketingManager: 'ContentMarketingManager',
  isSeniorAccountant: 'SeniorAccountant',
  isBillingAssociate: 'BillingAssociate',
  isCreditControlAssociate: 'CreditControlAssociate',
  isCFO: 'CFO',
  isRepairsMaintenanceAssociate: 'RepairsMaintenanceAssociate',
  isFinancialController: 'FinancialController',
  isCreditControlLead: 'CreditControlLead',
  isCustomerSuccessTeamLead: 'CustomerSuccessTeamLead',
  isHeadOfProduct: 'HeadOfProduct',
  isFleetOpsTeamLead: 'FleetOpsTeamLead',
  isProductOperationsAssociate: 'ProductOperationsAssociate',
  isReturnsDeliveriesLead: 'ReturnsDeliveriesLead',
  isHRSupervisor: 'HRSupervisor',
  isSalesTeamLead: 'SalesTeamLead',
  isExternalPartner: 'ExternalPartner',
  isProductContentEditor: 'Product Content Editor',
  isHeadOfSourcing: 'HeadOfSourcing',
};

export const AGENT_ROLES_OPTIONS = {
  isAdmin: { text: 'Admin', value: 'Admin' },
  isCustomerCareAgent: {
    text: 'Customer Care Agent',
    value: 'CustomerCareAgent',
  },
  isFleetOpsAssociate: {
    text: 'Fleet Ops Associate',
    value: 'FleetOpsAssociate',
  },
  isProjectManager: { text: 'Project Manager', value: 'ProjectManager' },
  isSalesConsultant: { text: 'Sales Consultant', value: 'SalesConsultant' },
  isSupplyChainAssociate: {
    text: 'Supply Chain Associate',
    value: 'SupplyChainAssociate',
  },
  isPerformanceMarketingSpecialist: {
    text: 'Performance Marketing Specialist',
    value: 'PerformanceMarketingSpecialist',
  },
  isTreasurer: { text: 'Treasurer', value: 'Treasurer' },
  isGraphicDesigner: { text: 'Graphic Designer', value: 'GraphicDesigner' },
  isControllingManager: {
    text: 'Controlling Manager',
    value: 'ControllingManager',
  },
  isHeadOfMarketing: { text: 'Head Of Marketing', value: 'HeadOfMarketing' },
  isAssistantAccountant: {
    text: 'Assistant Accountant',
    value: 'AssistantAccountant',
  },
  isInsuranceSpecialist: {
    text: 'Insurance Specialist',
    value: 'InsuranceSpecialist',
  },
  isSocialMediaManager: {
    text: 'Social Media Manager',
    value: 'SocialMediaManager',
  },
  isSeniorSalesConsultant: {
    text: 'Senior Sales Consultant',
    value: 'SeniorSalesConsultant',
  },
  isCustomerSuccessCoordinator: {
    text: 'Customer Success Coordinator',
    value: 'CustomerSuccessCoordinator',
  },
  isAccountant: { text: 'Accountant', value: 'Accountant' },
  isARMTeamLead: { text: 'ARM Team Lead', value: 'ARMTeamLead' },
  isProductDesigner: { text: 'Product Designer', value: 'ProductDesigner' },
  isCreditManager: { text: 'Credit Manager', value: 'CreditManager' },
  isOperationsDirector: {
    text: 'Operations Director',
    value: 'OperationsDirector',
  },
  isAccidentsSeniorAssociate: {
    text: 'Accidents Senior Associate',
    value: 'AccidentsSeniorAssociate',
  },
  isContentMarketingManager: {
    text: 'Content Marketing Manager',
    value: 'ContentMarketingManager',
  },
  isSeniorAccountant: {
    text: 'Senior Accountant',
    value: 'SeniorAccountant',
  },
  isBillingAssociate: { text: 'Billing Associate', value: 'BillingAssociate' },
  isCreditControlAssociate: {
    text: 'Credit Control Associate',
    value: 'CreditControlAssociate',
  },
  isCFO: { text: 'CFO', value: 'CFO' },
  isRepairsMaintenanceAssociate: {
    text: 'Repairs Maintenance Associate',
    value: 'RepairsMaintenanceAssociate',
  },
  isFinancialController: {
    text: 'Financial Controller',
    value: 'FinancialController',
  },
  isCreditControlLead: {
    text: 'Credit Control Lead',
    value: 'CreditControlLead',
  },
  isCustomerSuccessTeamLead: {
    text: 'Customer Success Team Lead',
    value: 'CustomerSuccessTeamLead',
  },
  isHeadOfProduct: { text: 'Head Of Product', value: 'HeadOfProduct' },
  isFleetOpsTeamLead: {
    text: 'Fleet Ops Team Lead',
    value: 'FleetOpsTeamLead',
  },
  isProductOperationsAssociate: {
    text: 'Product Operations Associate',
    value: 'ProductOperationsAssociate',
  },
  isReturnsDeliveriesLead: {
    text: 'Returns Deliveries Lead',
    value: 'ReturnsDeliveriesLead',
  },
  isHRSupervisor: { text: 'HR Supervisor', value: 'HRSupervisor' },
  isSalesTeamLead: { text: 'Sales Team Lead', value: 'SalesTeamLead' },
  isExternalPartner: { text: 'External Partner', value: 'ExternalPartner' },
  isProductContentEditor: {
    text: 'Product Content Editor',
    value: 'ProductContentEditor',
  },
  isHeadOfSourcing: { text: 'Head Of Sourcing', value: 'HeadOfSourcing' },
};

export const ENTITIES = {
  agents: 'agents',
  bookings: 'bookings',
  catalog: 'catalog',
  configurations: 'configurations',
  ebikes: 'ebikes',
  errors: 'errors',
  graphs: 'graphs',
  insurances: 'insurances',
  maps: 'maps',
  roadsideAssistances: 'roadsideAssistances',
  search: 'search',
  servicePoints: 'servicePoints',
  serviceVehicles: 'serviceVehicles',
  subscribers: 'subscribers',
  subscriptions: 'subscriptions',
  users: 'users',
  utilities: 'utilities',
  vehicles: 'vehicles',
};

export const SCOPES = {
  canCreate: 'write',
  canEdit: 'update',
  canDelete: 'delete',
  canView: 'read',
};

export const PROCUREMENT_STAGES = {
  CREATED: 0,
  ORDERED: 1,
  'IN CUSTOMS': 2,
  'PAYMENT PROCESS': 3,
  'DEALER PREPARATION': 4,
  FLEET: 5,
  DEFLEETED: 6,
  CANCELLED: 7,
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const REGEX_PATTERNS = {
  Boolean: /test. *true,false/,
  Phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
  Email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  String: /^$/,
};

export const FILTER_RULE_ENUMS = {
  AndRuleType: 0,
  OrRuleType: 1,
  EmptyRuleType: 2,
  NotRuleType: 3,
  OrderedRuleType: 4,
  ComparableRuleType: 5,
  DynamicFilterFieldRuleType: 6,
  CompareDateRuleType: 7,
  IsAnyOfRuleType: 8,
  CompareStringRuleType: 9,
  ComparePriceRuleType: 10,
  DateBetween: 11,
  isEmpty: 18,
  noEpmpty: 19,
};

export const FILTER_OPERATORS = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  LOWER_THAN: '<',
  GREATER_OR_EQUAL: '>=',
  LOWER_OR_EQUAL: '<=',
  CONTAINS: 'contains',
  BEGINS_WITH: 'beginsWith',
  ENDS_WITH: 'endsWith',
  BETWEEN: 'between',
};

export const FILTER_OPERATOR_ENUMS = {
  '=': 0,
  '!=': 1,
  '>': 2,
  '<': 3,
  '>=': 4,
  '<=': 5,
  contains: 6,
  beginsWith: 7,
  endsWith: 8,
  between: 9,
};

export const FILTER_RULE_OPERATORS = {
  AndRuleType: 0,
  OrRuleType: 1,
  EmptyRuleType: 2,
  NotRuleType: 3,
  OrderedRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
  ],
  ComparableRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
  ],
  DynamicFilterFieldRuleType: 6,
  CompareDateRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
  ],
  IsAnyOfRuleType: 8,
  CompareStringRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
    { name: FILTER_OPERATORS.CONTAINS, label: 'contains' },
    { name: FILTER_OPERATORS.BEGINS_WITH, label: 'begins with' },
    { name: FILTER_OPERATORS.ENDS_WITH, label: 'ends with' },
  ],
  ComparePriceRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
  ],
  DateBetween: 11,
};

export const FILTER_FIELDS_TYPES = {
  Select: 'dropdown',
  String: 'string',
  Number: 'number',
  Date: 'date',
  Boolean: 'boolean',
  Phone: 'phone',
  Email: 'email',
};

export const FILTER_FIELDS = {
  Price: {
    type: FILTER_FIELDS_TYPES.String,
    ruleType: FILTER_RULE_ENUMS.ComparePriceRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.ComparePriceRuleType,
    inputType: 'number',
  },
  String: {
    type: FILTER_FIELDS_TYPES.String,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.CompareStringRuleType,
  },
  Number: {
    type: FILTER_FIELDS_TYPES.Number,
    ruleType: FILTER_RULE_ENUMS.OrderedRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.OrderedRuleType,
    inputType: 'number',
  },
  Select: {
    type: FILTER_FIELDS_TYPES.Select,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.ComparableRuleType,
    valueEditorType: 'select',
    values: [],
    inputType: 'dropdown',
  },
  Date: {
    ruleType: FILTER_RULE_ENUMS.CompareDateRuleType,
    type: FILTER_FIELDS_TYPES.Date,
    pattern: REGEX_PATTERNS.String,
    operators: [
      ...FILTER_RULE_OPERATORS.CompareDateRuleType,
      { name: 'between', label: 'between' },
    ],
    inputType: 'date',
  },
  Boolean: {
    type: FILTER_FIELDS_TYPES.Boolean,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    pattern: REGEX_PATTERNS.Boolean,
    operators: FILTER_RULE_OPERATORS.ComparableRuleType,
    valueEditorType: 'checkbox',
    defaultValue: false,
    inputType: 'checkbox',
  },
  Phone: {
    type: FILTER_FIELDS_TYPES.Phone,
    pattern: REGEX_PATTERNS.Phone,
    ruleType: FILTER_RULE_ENUMS.CompareStringRuleType,
    inputType: 'number',
    operators: FILTER_RULE_OPERATORS.CompareStringRuleType,
  },
  Email: {
    type: FILTER_FIELDS_TYPES.Email,
    pattern: REGEX_PATTERNS.Email,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    operators: FILTER_RULE_OPERATORS.CompareStringRuleType,
  },
};

export const BOARDS = {
  SERVICES: 0,
  RETURNS: 1,
  BUY_OUT: 4,
  /* PLOP_INJECT_BOARD_VALUE */
  ARM_ACCIDENTS: 5,
  ARM_REPAIRS: 6,
  ARM_MAINTENANCE: 7,
  GREEN_CARD: 8,
  TRAFFIC_OFFENSES: 9,
  SELL_REQUESTS: 10,
  BILLING_PREDEL: 11,
  BILLING_INSTARIDE: 12,
  BILLING_REFUNDS: 13,
  BILLING_DAMAGES: 14,
  BILLING_OTHER: 15,
};

export const BOARDS_ID = {
  RETURNS: 'RET',
  BUY_OUT: 'BUY',
  ARM_ACCIDENTS: 'AC',
  ARM_REPAIRS: 'REP',
  ARM_MAINTENANCE: 'MAIN',
  GREEN_CARD: 'GC',
  TRAFFIC_OFFENSES: 'TROF',
  SELL_REQUESTS: 'VSL',
  BILLING_PREDEL: 'BPR',
  BILLING_INSTARIDE: 'BIR',
  BILLING_REFUNDS: 'BR',
  BILLING_DAMAGES: 'BD',
  BILLING_OTHER: 'BO',
};

export const CHANGELOG_ITEMS = {
  TASK_CREATE: 0,
  TASK_FIELD_UPDATE: 1,
};

export const CATALOG_TABS = {
  '': 0,
  properties: 1,
  media: 2,
  pricing: 3,
  documents: 4,
  vehicles: 5,
};

export const CATALOG_TABS_ENUMS = {
  0: '',
  1: 'properties',
  2: 'media',
  3: 'pricing',
  4: 'documents',
  5: 'vehicles',
};

export const ELECTRIC_VEHICLES_TYPES = ['mhev', 'hev', 'phev', 'bev'];
export const SERVICE_POINT_TABS = {
  '': 0,
  partnershipDetails: 1,
  reviews: 2,
};

export const SERVICE_POINT_TABS_ENUMS = {
  0: '',
  1: 'partnershipDetails',
  2: 'reviews',
};

export const ARNM_REASONS = [
  {
    text: 'Other',
    value: 'OT01',
  },
  {
    text: 'Repair',
    value: 'RE02',
  },
  {
    text: 'Service',
    value: 'SE03',
  },
  {
    text: 'Malfunction',
    value: 'MA04',
  },
  {
    text: 'Change tires',
    value: 'TI05',
  },
  {
    text: 'Vehicle Check',
    value: 'CH06',
  },
  {
    text: 'KTEO',
    value: 'KT07',
  },
  {
    text: 'Κάρτα Καυσαερίων',
    value: 'KK08',
  },
  {
    text: 'Parktronic',
    value: 'PA09',
  },
  {
    text: 'Roof Rack',
    value: 'RR10',
  },
  {
    text: 'Tinted Windows',
    value: 'TW11',
  },
  {
    text: 'Ανάκληση',
    value: 'AN12',
  },
  {
    text: 'Αλυσίδες',
    value: 'AL13',
  },
];

export const ARNM_REASONS_ACCIDENTS = [
  { text: 'Ατύχημα/Συμβάν', value: 'AC14' },
  { text: 'Κλοπή', value: 'ST15' },
  { text: 'Θραύση Κρυστάλλων', value: 'GB16' },
];

export const ARNM_REASONS_MAP = {
  OT01: 'Other',
  RE02: 'Repair',
  SE03: 'Service',
  MA04: 'Malfunction',
  TI05: 'Change tires',
  CH06: 'Vehicle Check',
  KT07: 'KTEO',
  KK08: 'Κάρτα Καυσαερίων',
  PA09: 'Parktronic',
  RR10: 'Roof Rack',
  TW11: 'Tinted Windows',
  AN12: 'Ανάκληση',
  AL13: 'Αλυσίδες',
  AC14: 'Ατύχημα/Συμβάν',
  ST15: 'Κλοπή',
  GB16: 'Θραύση Κρυστάλλων',
};

export const ARNM_WORK_TYPES = [
  {
    text: 'Βαφή',
    value: 'Βαφή',
  },
  {
    text: 'Επισκευή',
    value: 'Επισκευή',
  },
  {
    text: 'Καύσιμο',
    value: 'Καύσιμο',
  },
  {
    text: 'Πλύσιμο',
    value: 'Πλύσιμο',
  },
  {
    text: 'Ανταλλακτικό',
    value: 'Ανταλλακτικό',
  },
  {
    text: 'Εργασία',
    value: 'Εργασία',
  },
  {
    text: 'Έκδοση Κλειδιού',
    value: 'Έκδοση Κλειδιού',
  },
  {
    text: 'Απαλλαγή',
    value: 'Απαλλαγή',
  },
  {
    text: 'Έλεγχος / Malfunction',
    value: 'Έλεγχος / Malfunction',
  },
  {
    text: 'Ευθυγράμμιση',
    value: 'Ευθυγράμμιση',
  },
  {
    text: 'Ασύμφορο',
    value: 'Ασύμφορο',
  },
  {
    text: 'Επισκευή από Πελάτη',
    value: 'Επισκευή από Πελάτη',
  },
  {
    text: 'Μικρό Service',
    value: 'Μικρό Service',
  },
  {
    text: 'Μεγάλο Service',
    value: 'Μεγάλο Service',
  },
  {
    text: 'Service A/C & Καλοριφέρ',
    value: 'Service A/C & Καλοριφέρ',
  },
  {
    text: 'Έκδοση Κάρτας Καυσαερίων',
    value: 'Έκδοση Κάρτας Καυσαερίων',
  },
  {
    text: 'KTEO',
    value: 'KTEO',
  },
  {
    text: 'Ελαστικά',
    value: 'Ελαστικά',
  },
  {
    text: 'Συνδεσιμότητα',
    value: 'Συνδεσιμότητα',
  },
  {
    text: 'Τοποθετήσεις / Προσθήκες',
    value: 'Τοποθετήσεις / Προσθήκες',
  },
  {
    text: 'AD Blue',
    value: 'AD Blue',
  },
  {
    text: 'Ανάκληση',
    value: 'Ανάκληση',
  },
  {
    text: 'Κλοπή',
    value: 'Κλοπή',
  },
  {
    text: 'instadelivery',
    value: 'instadelivery',
  },
  {
    text: 'Γυάλισμα',
    value: 'Γυάλισμα',
  },
  {
    text: 'Τοπική βαφή',
    value: 'Τοπική βαφή',
  },
  {
    text: 'Βαφή / Επισκευή',
    value: 'Βαφή / Επισκευή',
  },
  {
    text: 'Κόστος Temp Car',
    value: 'Κόστος Temp Car',
  },
];

export const ARNM_REPAIR_WORK_TYPES_ENUMS = {
  0: 'Βαφή',
  1: 'Επισκευή',
  2: 'Καύσιμο',
  3: 'Πλύσιμο',
  4: 'Ανταλλακτικό',
  5: 'Εργασία',
  6: 'Έκδοση Κλειδιού',
  7: 'Απαλλαγή',
  8: 'Έλεγχος / Malfunction',
  9: 'Ευθυγράμμιση',
  10: 'Ασύμφορο',
  11: 'Επισκευή από Πελάτη',
  12: 'Μικρό Service',
  13: 'Μεγάλο Service',
  14: 'Service A/C & Καλοριφέρ',
  15: 'Έκδοση Κάρτας Καυσαερίων',
  16: 'KTEO',
  17: 'Ελαστικά',
  18: 'Συνδεσιμότητα',
  19: 'Τοποθετήσεις / Προσθήκες',
  20: 'AD Blue',
  21: 'Ανάκληση',
  22: 'Κλοπή',
  23: 'instadelivery',
  24: 'Γυάλισμα',
  25: 'Τοπική βαφή',
  26: 'Βαφή / Επισκευή',
  27: 'Κόστος Temp Car',
};

export const ARNM_REPAIR_DETAILS = {
  details0: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
    { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
    { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
    { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
    { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
    { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
  ],
  details1: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 6 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 6 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 6 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 6 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 6 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 6 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 6 },
    { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 6 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 6 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 6 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 6 },
    { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 6 },
    { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 6 },
    { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 2 },
    { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 2 },
    { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 2 },
  ],
  details2: [
    { text: 'ΧΛΜ ΕΚΤΟΣ ΟΡΙΟΥ', value: 'ΧΛΜ ΕΚΤΟΣ ΟΡΙΟΥ', repairDuration: 0 },
    { text: 'ΧΡΕΩΣΗ ΚΑΥΣΙΜΟΥ', value: 'ΧΡΕΩΣΗ ΚΑΥΣΙΜΟΥ', repairDuration: 0 },
  ],
  details3: [
    { text: 'ΠΛΥΣΙΜΟ ΑΠΛΟ', value: 'ΠΛΥΣΙΜΟ ΑΠΛΟ', repairDuration: 2 },
    {
      text: 'ΒΙΟΛΟΓΙΚΟΣ ΚΑΘΑΡΙΣΜΟΣ',
      value: 'ΒΙΟΛΟΓΙΚΟΣ ΚΑΘΑΡΙΣΜΟΣ',
      repairDuration: 2,
    },
  ],
  details4: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 15 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 15 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 15 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 15 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 15 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 15 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 15 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 15 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 15 },
    { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 15 },
    { text: 'ΨΑΛΙΔΙ', value: 'ΨΑΛΙΔΙ', repairDuration: 15 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 15 },
    { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 15 },
    { text: 'ΚΑΘΙΣΜΑ', value: 'ΚΑΘΙΣΜΑ', repairDuration: 15 },
    { text: 'ΦΑΝΑΡI / ΦΩΣ', value: 'ΦΑΝΑΡI / ΦΩΣ', repairDuration: 15 },
    { text: 'ΑΜΟΡΤΙΣΕΡ', value: 'ΑΜΟΡΤΙΣΕΡ', repairDuration: 5 },
    { text: 'ΑΞΟΝΑΣ', value: 'ΑΞΟΝΑΣ', repairDuration: 15 },
    { text: 'ΜΠΑΡΑ', value: 'ΜΠΑΡΑ', repairDuration: 15 },
    { text: 'ΡΟΥΛΕΜΑΝ', value: 'ΡΟΥΛΕΜΑΝ', repairDuration: 5 },
    { text: 'ΦΡΕΝΟ', value: 'ΦΡΕΝΟ', repairDuration: 5 },
    { text: 'ΜΙΖΑ', value: 'ΜΙΖΑ', repairDuration: 15 },
    { text: 'ΑΙΣΘΗΤΗΡΑΣ', value: 'ΑΙΣΘΗΤΗΡΑΣ', repairDuration: 5 },
    { text: 'ΨΕΚΑΣΜΟΣ', value: 'ΨΕΚΑΣΜΟΣ', repairDuration: 15 },
    { text: 'ΥΑΛΟΚ/ΡΕΣ', value: 'ΥΑΛΟΚ/ΡΕΣ', repairDuration: 0 },
    { text: 'AIRBAG', value: 'AIRBAG', repairDuration: 15 },
    { text: 'ΖΩΝΗ', value: 'ΖΩΝΗ', repairDuration: 5 },
    { text: 'ΨΥΓΕΙΟ', value: 'ΨΥΓΕΙΟ', repairDuration: 15 },
    { text: 'ΚΟΡΝΑ', value: 'ΚΟΡΝΑ', repairDuration: 15 },
    { text: 'ΣΑΖΜΑΝ', value: 'ΣΑΖΜΑΝ', repairDuration: 15 },
    { text: 'ΦΙΛΤΡΟ', value: 'ΦΙΛΤΡΟ', repairDuration: 2 },
    { text: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', value: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', repairDuration: 15 },
    { text: 'ΕΓΚΕΦΑΛΟΣ', value: 'ΕΓΚΕΦΑΛΟΣ', repairDuration: 15 },
    { text: 'ΤΑΚΑΚΙΑ', value: 'ΤΑΚΑΚΙΑ', repairDuration: 2 },
    { text: 'ΜΠΟΥΖΙ', value: 'ΜΠΟΥΖΙ', repairDuration: 15 },
    { text: 'ΔΙΣΚΟΠΛΑΚΕΣ', value: 'ΔΙΣΚΟΠΛΑΚΕΣ', repairDuration: 2 },
    { text: 'ΤΑΠΑ ΚΑΡΤΕΡ', value: 'ΤΑΠΑ ΚΑΡΤΕΡ', repairDuration: 2 },
    { text: 'ΙΜΑΝΤΑΣ', value: 'ΙΜΑΝΤΑΣ', repairDuration: 2 },
    { text: 'ΜΠΑΤΑΡΙΑ', value: 'ΜΠΑΤΑΡΙΑ', repairDuration: 2 },
    { text: 'ΣΥΜΠΛΕΚΤΗΣ', value: 'ΣΥΜΠΛΕΚΤΗΣ', repairDuration: 15 },
    { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 15 },
    { text: 'ΑΝΤΛΙΑ', value: 'ΑΝΤΛΙΑ', repairDuration: 15 },
    { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 15 },
    { text: 'ΤΣΙΜΟΥΧΑ', value: 'ΤΣΙΜΟΥΧΑ', repairDuration: 2 },
    { text: 'ΕΛΑΤΗΡΙΟ', value: 'ΕΛΑΤΗΡΙΟ', repairDuration: 2 },
    { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 15 },
    { text: 'ΒΑΛΒΙΔΑ', value: 'ΒΑΛΒΙΔΑ', repairDuration: 2 },
    { text: 'ΕΤΑΖΕΡΑ', value: 'ΕΤΑΖΕΡΑ', repairDuration: 2 },
    { text: 'ΚΕΡΑΙΑ', value: 'ΚΕΡΑΙΑ', repairDuration: 2 },
    { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 2 },
    { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 2 },
    { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 2 },
  ],
  details5: [
    {
      text: 'ΕΡΓΑΣΙΑ ΜΗΧΑΝΟΛΟΓΙΚΗ',
      value: 'ΕΡΓΑΣΙΑ ΜΗΧΑΝΟΛΟΓΙΚΗ',
      repairDuration: 1,
    },
    {
      text: 'ΕΡΓΑΣΙΑ ΗΛΕΚΤΡΟΛΟΓΙΚΗ',
      value: 'ΕΡΓΑΣΙΑ ΗΛΕΚΤΡΟΛΟΓΙΚΗ',
      repairDuration: 1,
    },
    {
      text: 'ΕΡΓΑΣΙΑ ΦΑΝΟΠΟΙΙΑΣ',
      value: 'ΕΡΓΑΣΙΑ ΦΑΝΟΠΟΙΙΑΣ',
      repairDuration: 1,
    },
  ],
  details6: [{ text: 'ΜΕ ΧΡΕΩΣΗ', value: 'ΜΕ ΧΡΕΩΣΗ', repairDuration: 0 }],
  details7: [
    { text: 'ΜΗΧΑΝΟΛΟΓΙΚΟΣ', value: 'ΜΗΧΑΝΟΛΟΓΙΚΟΣ', repairDuration: 5 },
    { text: 'ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ', value: 'ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ', repairDuration: 5 },
    { text: 'ΦΑΝΟΠΟΙΙΑΣ', value: 'ΦΑΝΟΠΟΙΙΑΣ', repairDuration: 5 },
  ],
  details8: [
    { text: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', value: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', repairDuration: 0 },
  ],
  details9: [
    { text: 'ΟΛΙΚΗ ΚΑΤΑΣΤΡΟΦΗ', value: 'ΟΛΙΚΗ ΚΑΤΑΣΤΡΟΦΗ' },
    { text: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΕΜΠΡΟΣ', value: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΕΜΠΡΟΣ' },
    { text: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΠΙΣΩ', value: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΠΙΣΩ' },
  ],
  details10: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 0 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 0 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 0 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 0 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 0 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 0 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 0 },
    { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 0 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 0 },
    { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 0 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 0 },
    { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 0 },
    { text: 'ΨΑΛΙΔΙ', value: 'ΨΑΛΙΔΙ', repairDuration: 0 },
    { text: 'ΚΑΘΙΣΜΑΤΑ', value: 'ΚΑΘΙΣΜΑΤΑ', repairDuration: 0 },
    { text: 'ΛΟΙΠΕΣ ΕΠΙΣΚΕΥΕΣ', value: 'ΛΟΙΠΕΣ ΕΠΙΣΚΕΥΕΣ', repairDuration: 0 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 0 },
    { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 0 },
    { text: 'ΦΑΝΑΡI / ΦΩΣ', value: 'ΦΑΝΑΡI / ΦΩΣ', repairDuration: 0 },
    { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 0 },
    { text: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', value: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', repairDuration: 0 },
    { text: 'ΑΜΟΡΤΙΣΕΡ', value: 'ΑΜΟΡΤΙΣΕΡ', repairDuration: 0 },
    { text: 'ΑΞΟΝΑΣ', value: 'ΑΞΟΝΑΣ', repairDuration: 0 },
    { text: 'ΜΠΑΡΑ', value: 'ΜΠΑΡΑ', repairDuration: 0 },
    { text: 'ΡΟΥΛΕΜΑΝ', value: 'ΡΟΥΛΕΜΑΝ', repairDuration: 0 },
    { text: 'ΦΡΕΝΟ', value: 'ΦΡΕΝΟ', repairDuration: 0 },
    { text: 'ΜΙΖΑ', value: 'ΜΙΖΑ', repairDuration: 0 },
    { text: 'ΑΙΣΘΗΤΗΡΑΣ', value: 'ΑΙΣΘΗΤΗΡΑΣ', repairDuration: 0 },
    { text: 'ΨΕΚΑΣΜΟΣ', value: 'ΨΕΚΑΣΜΟΣ', repairDuration: 0 },
    { text: 'FREON', value: 'FREON', repairDuration: 0 },
    { text: 'ΥΑΛΟΚ/ΡΕΣ', value: 'ΥΑΛΟΚ/ΡΕΣ', repairDuration: 0 },
    { text: 'AIRBAG', value: 'AIRBAG', repairDuration: 0 },
    { text: 'ΖΩΝΗ', value: 'ΖΩΝΗ', repairDuration: 0 },
    { text: 'ΨΥΓΕΙΟ', value: 'ΨΥΓΕΙΟ', repairDuration: 0 },
    { text: 'ΚΟΡΝΑ', value: 'ΚΟΡΝΑ', repairDuration: 0 },
    { text: 'ΣΑΖΜΑΝ', value: 'ΣΑΖΜΑΝ', repairDuration: 0 },
    { text: 'ΛΑΔΙΑ', value: 'ΛΑΔΙΑ', repairDuration: 0 },
    { text: 'ΦΙΛΤΡΟ', value: 'ΦΙΛΤΡΟ', repairDuration: 0 },
    { text: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', value: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', repairDuration: 0 },
    { text: 'ΕΓΚΕΦΑΛΟΣ', value: 'ΕΓΚΕΦΑΛΟΣ', repairDuration: 0 },
    { text: 'ΨΥΚΤΙΚΟ', value: 'ΨΥΚΤΙΚΟ', repairDuration: 0 },
    { text: 'ΤΑΚΑΚΙΑ', value: 'ΤΑΚΑΚΙΑ', repairDuration: 0 },
    { text: 'ΜΠΟΥΖΙ', value: 'ΜΠΟΥΖΙ', repairDuration: 0 },
    { text: 'ΔΙΣΚΟΠΛΑΚΕΣ', value: 'ΔΙΣΚΟΠΛΑΚΕΣ', repairDuration: 0 },
    { text: 'ΤΑΠΑ ΚΑΡΤΕΡ', value: 'ΤΑΠΑ ΚΑΡΤΕΡ', repairDuration: 0 },
    { text: 'ΙΜΑΝΤΑΣ', value: 'ΙΜΑΝΤΑΣ', repairDuration: 0 },
    { text: 'ΜΠΑΤΑΡΙΑ', value: 'ΜΠΑΤΑΡΙΑ', repairDuration: 0 },
    { text: 'ΣΥΜΠΛΕΚΤΗΣ', value: 'ΣΥΜΠΛΕΚΤΗΣ', repairDuration: 0 },
    { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 0 },
    { text: 'ΑΝΤΛΙΑ', value: 'ΑΝΤΛΙΑ', repairDuration: 0 },
    { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 0 },
    { text: 'ΤΣΙΜΟΥΧΑ', value: 'ΤΣΙΜΟΥΧΑ', repairDuration: 0 },
    { text: 'ΕΛΑΤΗΡΙΟ', value: 'ΕΛΑΤΗΡΙΟ', repairDuration: 0 },
    { text: 'ΒΑΛΒΙΔΑ', value: 'ΒΑΛΒΙΔΑ', repairDuration: 0 },
    { text: 'AD BLUE', value: 'AD BLUE', repairDuration: 0 },
    {
      text: 'ΑΛΛΑΓΗ ΕΜΠ ΕΛΑΣΤΙΚΩΝ',
      value: 'ΑΛΛΑΓΗ ΕΜΠ ΕΛΑΣΤΙΚΩΝ',
      repairDuration: 0,
    },
    {
      text: 'ΑΛΛΑΓΗ ΠΙΣΩ ΕΛΑΣΤΙΚΩΝ',
      value: 'ΑΛΛΑΓΗ ΠΙΣΩ ΕΛΑΣΤΙΚΩΝ',
      repairDuration: 0,
    },
    {
      text: 'ΑΛΛΑΓΗ ΟΛΩΝ ΤΩΝ ΕΛΑΣΤΙΚΩΝ',
      value: 'ΑΛΛΑΓΗ ΟΛΩΝ ΤΩΝ ΕΛΑΣΤΙΚΩΝ',
      repairDuration: 0,
    },
  ],
  details11: [
    { text: 'ΛΑΔΙ', value: 'ΛΑΔΙ', repairDuration: 0 },
    { text: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', value: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', repairDuration: 0 },
  ],
  details12: [
    { text: 'ΛΑΔΙ', value: 'ΛΑΔΙ', repairDuration: 0 },
    { text: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', value: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', repairDuration: 0 },
    { text: 'ΦΙΛΤΡΟ ΑΕΡΟΣ', value: 'ΦΙΛΤΡΟ ΑΕΡΟΣ', repairDuration: 0 },
    { text: 'ΦΙΛΤΡΟ ΚΑΜΠΙΝΑΣ', value: 'ΦΙΛΤΡΟ ΚΑΜΠΙΝΑΣ', repairDuration: 0 },
    {
      text: 'ΦΙΛΤΡΟ ΠΕΤΡΕΛΑΙΟΥ',
      value: 'ΦΙΛΤΡΟ ΠΕΤΡΕΛΑΙΟΥ',
      repairDuration: 0,
    },
  ],
  details13: [
    { text: 'SERVICE A/C', value: 'SERVICE A/C', repairDuration: 0 },
    { text: 'ΦΡΕΟΝ', value: 'ΦΡΕΟΝ', repairDuration: 0 },
    { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 0 },
  ],
  details14: [
    {
      text: 'ΕΚΔΟΣΗ ΚΑΡΤΑΣ ΚΑΥΣΑΕΡΙΩΝ',
      value: 'ΕΚΔΟΣΗ ΚΑΡΤΑΣ ΚΑΥΣΑΕΡΙΩΝ',
      repairDuration: 0,
    },
  ],
  details15: [{ text: 'ΚΤΕΟ', value: 'ΚΤΕΟ', repairDuration: 0 }],
  details16: [
    { text: 'ΑΛΛΑΓΗ ΕΛΑΣΤΙΚΩΝ', value: 'ΑΛΛΑΓΗ ΕΛΑΣΤΙΚΩΝ', repairDuration: 0 },
  ],
  details17: [
    {
      text: 'ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΜΕ ΤΟ ΚΙΝΗΤΟ',
      value: 'ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΜΕ ΤΟ ΚΙΝΗΤΟ',
      repairDuration: 0,
    },
  ],
  details18: [
    { text: 'PARKTRONIC', value: 'PARKTRONIC', repairDuration: 0 },
    { text: 'ΦΗΜΕ ΤΖΑΜΙΑ', value: 'ΦΗΜΕ ΤΖΑΜΙΑ', repairDuration: 0 },
    { text: 'ΣΧΑΡΑ', value: 'ΣΧΑΡΑ', repairDuration: 0 },
  ],
  details19: [
    {
      text: 'ΠΡΟΒΛΗΜΑ ΜΕ ΣΥΣΤΗΜΑ AD BLUE',
      value: 'ΠΡΟΒΛΗΜΑ ΜΕ ΣΥΣΤΗΜΑ AD BLUE',
      repairDuration: 0,
    },
    { text: 'ΠΛΗΡΩΣΗ AD BLUE', value: 'ΠΛΗΡΩΣΗ AD BLUE', repairDuration: 0 },
  ],
  details20: [
    {
      text: 'ΜΗΧΑΝΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
      value: 'ΜΗΧΑΝΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
      repairDuration: 0,
    },
    {
      text: 'ΗΛΕΚΤΡΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
      value: 'ΗΛΕΚΤΡΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
      repairDuration: 0,
    },
  ],
  details21: [
    { text: 'ΜΕΡΙΚΗ ΚΛΟΠΗ', value: 'ΜΕΡΙΚΗ ΚΛΟΠΗ', repairDuration: 0 },
    { text: 'ΟΛΙΚΗ ΚΛΟΠΗ', value: 'ΟΛΙΚΗ ΚΛΟΠΗ', repairDuration: 0 },
  ],
  details24: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
    { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
    { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
    { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
    { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
    { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
  ],
  details25: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
    { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
    { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
    { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
    { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
    { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
  ],
  details26: [
    { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 6 },
    { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 6 },
    { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 6 },
    { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 6 },
    { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 6 },
    { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 6 },
    { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 6 },
    { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 6 },
    { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 6 },
    { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 6 },
    { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 6 },
    { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 6 },
    { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 6 },
    { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 6 },
    { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 6 },
    { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 6 },
  ],
};

export const MAP_ARNM_REPAIR_WORK_TYPES_WITH_DETAILS = {
  0: 'details0',
  1: 'details1',
  2: 'details2',
  3: 'details3',
  4: 'details4',
  5: 'details5',
  6: 'details6',
  7: undefined,
  8: 'details7',
  9: 'details8',
  10: 'details9',
  11: 'details10',
  12: 'details11',
  13: 'details12',
  14: 'details13',
  15: 'details14',
  16: 'details15',
  17: 'details16',
  18: 'details17',
  19: 'details18',
  20: 'details19',
  21: 'details20',
  22: 'details21',
  23: undefined,
  24: 'details24',
  25: 'details25',
  26: 'details26',
  27: undefined,
};

export const ARNM_SIDE_ONE = {
  sideOne1: [
    { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
    { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
  ],
};

export const ARNM_SIDE_TWO = {
  sideTwo1: [
    { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
    { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
  ],
};

export const REDIRECT_URL_STOREGE_KEY = 'redirectUrl';

export const SUBSCRIPTIONS_TYPES = {
  0: 'Standard',
  1: 'DriveHome',
  2: 'Closed Contract',
  3: 'Temp',
  4: 'Predel',
};

export const SUBSCRIPTIONS_STATUSES = {
  0: 'Created',
  1: 'For Delivery',
  2: 'Active',
  3: 'For Return',
  4: 'Ended',
  5: 'Inactive',
};

export const PRICING_TYPES = {
  open: 0,
  fixed: 1,
};
export const PRICING_TYPES_MAP = {
  0: 'Open',
  1: 'Fixed',
};
export const MONTHLY_KILOMETERS = [
  {
    text: '0.08',
    value: '0.08',
  },
  {
    text: '0.10',
    value: '0.10',
  },
  {
    text: '0.14',
    value: '0.14',
  },
  {
    text: '0.16',
    value: '0.16',
  },
  {
    text: '0.18',
    value: '0.18',
  },
];

export const SUBSCRIPTIONS_COLORS_MAP = {
  0: { bg: '#0AAB7B', color: 'white !important' }, // Standard
  3: { bg: '#FFD217', color: 'black' }, // Temp
  4: { bg: '#552CFF', color: 'white !important' }, // Predel
};

export const PRICING_CONTRACT_PERIOD_OPTIONS = [
  { text: `12 μήνες`, value: 12 },
  { text: `24 μήνες`, value: 24 },
];

export const CONTRACT_TYPES = {
  open: 0,
  fixed: 1,
};

export const SERVICE_POINT_STATUSES = {
  deactive: 0,
  active: 1,
};
export const ARNM_WORK_TYPES_DETAILS = [
  {
    text: 'Βαφή',
    value: 'Βαφή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
      { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Επισκευή',
    value: 'Επισκευή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 6 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 6 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 6 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 6 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 6 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 6 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 6 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 6 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 6 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 6 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 6 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 6 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 6 },
      { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 2 },
      { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 2 },
      { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Καύσιμο',
    value: 'Καύσιμο',
    details: [
      { text: 'ΧΛΜ ΕΚΤΟΣ ΟΡΙΟΥ', value: 'ΧΛΜ ΕΚΤΟΣ ΟΡΙΟΥ', repairDuration: 0 },
      { text: 'ΧΡΕΩΣΗ ΚΑΥΣΙΜΟΥ', value: 'ΧΡΕΩΣΗ ΚΑΥΣΙΜΟΥ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Πλύσιμο',
    value: 'Πλύσιμο',
    details: [
      { text: 'ΠΛΥΣΙΜΟ ΑΠΛΟ', value: 'ΠΛΥΣΙΜΟ ΑΠΛΟ', repairDuration: 2 },
      {
        text: 'ΒΙΟΛΟΓΙΚΟΣ ΚΑΘΑΡΙΣΜΟΣ',
        value: 'ΒΙΟΛΟΓΙΚΟΣ ΚΑΘΑΡΙΣΜΟΣ',
        repairDuration: 2,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ανταλλακτικό',
    value: 'Ανταλλακτικό',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 15 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 15 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 15 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 15 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 15 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 15 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 15 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 15 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 15 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 15 },
      { text: 'ΨΑΛΙΔΙ', value: 'ΨΑΛΙΔΙ', repairDuration: 15 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 15 },
      { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 15 },
      { text: 'ΚΑΘΙΣΜΑ', value: 'ΚΑΘΙΣΜΑ', repairDuration: 15 },
      { text: 'ΦΑΝΑΡI / ΦΩΣ', value: 'ΦΑΝΑΡI / ΦΩΣ', repairDuration: 15 },
      { text: 'ΑΜΟΡΤΙΣΕΡ', value: 'ΑΜΟΡΤΙΣΕΡ', repairDuration: 5 },
      { text: 'ΑΞΟΝΑΣ', value: 'ΑΞΟΝΑΣ', repairDuration: 15 },
      { text: 'ΜΠΑΡΑ', value: 'ΜΠΑΡΑ', repairDuration: 15 },
      { text: 'ΡΟΥΛΕΜΑΝ', value: 'ΡΟΥΛΕΜΑΝ', repairDuration: 5 },
      { text: 'ΦΡΕΝΟ', value: 'ΦΡΕΝΟ', repairDuration: 5 },
      { text: 'ΜΙΖΑ', value: 'ΜΙΖΑ', repairDuration: 15 },
      { text: 'ΑΙΣΘΗΤΗΡΑΣ', value: 'ΑΙΣΘΗΤΗΡΑΣ', repairDuration: 5 },
      { text: 'ΨΕΚΑΣΜΟΣ', value: 'ΨΕΚΑΣΜΟΣ', repairDuration: 15 },
      { text: 'ΥΑΛΟΚ/ΡΕΣ', value: 'ΥΑΛΟΚ/ΡΕΣ', repairDuration: 0 },
      { text: 'AIRBAG', value: 'AIRBAG', repairDuration: 15 },
      { text: 'ΖΩΝΗ', value: 'ΖΩΝΗ', repairDuration: 5 },
      { text: 'ΨΥΓΕΙΟ', value: 'ΨΥΓΕΙΟ', repairDuration: 15 },
      { text: 'ΚΟΡΝΑ', value: 'ΚΟΡΝΑ', repairDuration: 15 },
      { text: 'ΣΑΖΜΑΝ', value: 'ΣΑΖΜΑΝ', repairDuration: 15 },
      { text: 'ΦΙΛΤΡΟ', value: 'ΦΙΛΤΡΟ', repairDuration: 2 },
      { text: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', value: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', repairDuration: 15 },
      { text: 'ΕΓΚΕΦΑΛΟΣ', value: 'ΕΓΚΕΦΑΛΟΣ', repairDuration: 15 },
      { text: 'ΤΑΚΑΚΙΑ', value: 'ΤΑΚΑΚΙΑ', repairDuration: 2 },
      { text: 'ΜΠΟΥΖΙ', value: 'ΜΠΟΥΖΙ', repairDuration: 15 },
      { text: 'ΔΙΣΚΟΠΛΑΚΕΣ', value: 'ΔΙΣΚΟΠΛΑΚΕΣ', repairDuration: 2 },
      { text: 'ΤΑΠΑ ΚΑΡΤΕΡ', value: 'ΤΑΠΑ ΚΑΡΤΕΡ', repairDuration: 2 },
      { text: 'ΙΜΑΝΤΑΣ', value: 'ΙΜΑΝΤΑΣ', repairDuration: 2 },
      { text: 'ΜΠΑΤΑΡΙΑ', value: 'ΜΠΑΤΑΡΙΑ', repairDuration: 2 },
      { text: 'ΣΥΜΠΛΕΚΤΗΣ', value: 'ΣΥΜΠΛΕΚΤΗΣ', repairDuration: 15 },
      { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 15 },
      { text: 'ΑΝΤΛΙΑ', value: 'ΑΝΤΛΙΑ', repairDuration: 15 },
      { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 15 },
      { text: 'ΤΣΙΜΟΥΧΑ', value: 'ΤΣΙΜΟΥΧΑ', repairDuration: 2 },
      { text: 'ΕΛΑΤΗΡΙΟ', value: 'ΕΛΑΤΗΡΙΟ', repairDuration: 2 },
      { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 15 },
      { text: 'ΒΑΛΒΙΔΑ', value: 'ΒΑΛΒΙΔΑ', repairDuration: 2 },
      { text: 'ΕΤΑΖΕΡΑ', value: 'ΕΤΑΖΕΡΑ', repairDuration: 2 },
      { text: 'ΚΕΡΑΙΑ', value: 'ΚΕΡΑΙΑ', repairDuration: 2 },
      { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 2 },
      { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 2 },
      { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Εργασία',
    value: 'Εργασία',
    details: [
      {
        text: 'ΕΡΓΑΣΙΑ ΜΗΧΑΝΟΛΟΓΙΚΗ',
        value: 'ΕΡΓΑΣΙΑ ΜΗΧΑΝΟΛΟΓΙΚΗ',
        repairDuration: 1,
      },
      {
        text: 'ΕΡΓΑΣΙΑ ΗΛΕΚΤΡΟΛΟΓΙΚΗ',
        value: 'ΕΡΓΑΣΙΑ ΗΛΕΚΤΡΟΛΟΓΙΚΗ',
        repairDuration: 1,
      },
      {
        text: 'ΕΡΓΑΣΙΑ ΦΑΝΟΠΟΙΙΑΣ',
        value: 'ΕΡΓΑΣΙΑ ΦΑΝΟΠΟΙΙΑΣ',
        repairDuration: 1,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Έκδοση Κλειδιού',
    value: 'Έκδοση Κλειδιού',
    details: [{ text: 'ΜΕ ΧΡΕΩΣΗ', value: 'ΜΕ ΧΡΕΩΣΗ', repairDuration: 0 }],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Απαλλαγή',
    value: 'Απαλλαγή',
    details: [], // empty
  },
  {
    text: 'Έλεγχος / Malfunction',
    value: 'Έλεγχος / Malfunction',
    details: [
      { text: 'ΜΗΧΑΝΟΛΟΓΙΚΟΣ', value: 'ΜΗΧΑΝΟΛΟΓΙΚΟΣ', repairDuration: 5 },
      { text: 'ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ', value: 'ΗΛΕΚΤΡΟΛΟΓΙΚΟΣ', repairDuration: 5 },
      { text: 'ΦΑΝΟΠΟΙΙΑΣ', value: 'ΦΑΝΟΠΟΙΙΑΣ', repairDuration: 5 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ευθυγράμμιση',
    value: 'Ευθυγράμμιση',
    details: [
      { text: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', value: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ασύμφορο',
    value: 'Ασύμφορο',
    details: [
      { text: 'ΟΛΙΚΗ ΚΑΤΑΣΤΡΟΦΗ', value: 'ΟΛΙΚΗ ΚΑΤΑΣΤΡΟΦΗ' },
      { text: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΕΜΠΡΟΣ', value: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΕΜΠΡΟΣ' },
      { text: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΠΙΣΩ', value: 'ΜΕΡΙΚΗ ΚΑΤΑΣΤΡΟΦΗ ΠΙΣΩ' },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Επισκευή από Πελάτη',
    value: 'Επισκευή από Πελάτη',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 0 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 0 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 0 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 0 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 0 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 0 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 0 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 0 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 0 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 0 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 0 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 0 },
      { text: 'ΨΑΛΙΔΙ', value: 'ΨΑΛΙΔΙ', repairDuration: 0 },
      { text: 'ΚΑΘΙΣΜΑΤΑ', value: 'ΚΑΘΙΣΜΑΤΑ', repairDuration: 0 },
      {
        text: 'ΛΟΙΠΕΣ ΕΠΙΣΚΕΥΕΣ',
        value: 'ΛΟΙΠΕΣ ΕΠΙΣΚΕΥΕΣ',
        repairDuration: 0,
      },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 0 },
      { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 0 },
      { text: 'ΦΑΝΑΡI / ΦΩΣ', value: 'ΦΑΝΑΡI / ΦΩΣ', repairDuration: 0 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 0 },
      { text: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', value: 'ΕΥΘΥΓΡΑΜΜΙΣΗ', repairDuration: 0 },
      { text: 'ΑΜΟΡΤΙΣΕΡ', value: 'ΑΜΟΡΤΙΣΕΡ', repairDuration: 0 },
      { text: 'ΑΞΟΝΑΣ', value: 'ΑΞΟΝΑΣ', repairDuration: 0 },
      { text: 'ΜΠΑΡΑ', value: 'ΜΠΑΡΑ', repairDuration: 0 },
      { text: 'ΡΟΥΛΕΜΑΝ', value: 'ΡΟΥΛΕΜΑΝ', repairDuration: 0 },
      { text: 'ΦΡΕΝΟ', value: 'ΦΡΕΝΟ', repairDuration: 0 },
      { text: 'ΜΙΖΑ', value: 'ΜΙΖΑ', repairDuration: 0 },
      { text: 'ΑΙΣΘΗΤΗΡΑΣ', value: 'ΑΙΣΘΗΤΗΡΑΣ', repairDuration: 0 },
      { text: 'ΨΕΚΑΣΜΟΣ', value: 'ΨΕΚΑΣΜΟΣ', repairDuration: 0 },
      { text: 'FREON', value: 'FREON', repairDuration: 0 },
      { text: 'ΥΑΛΟΚ/ΡΕΣ', value: 'ΥΑΛΟΚ/ΡΕΣ', repairDuration: 0 },
      { text: 'AIRBAG', value: 'AIRBAG', repairDuration: 0 },
      { text: 'ΖΩΝΗ', value: 'ΖΩΝΗ', repairDuration: 0 },
      { text: 'ΨΥΓΕΙΟ', value: 'ΨΥΓΕΙΟ', repairDuration: 0 },
      { text: 'ΚΟΡΝΑ', value: 'ΚΟΡΝΑ', repairDuration: 0 },
      { text: 'ΣΑΖΜΑΝ', value: 'ΣΑΖΜΑΝ', repairDuration: 0 },
      { text: 'ΛΑΔΙΑ', value: 'ΛΑΔΙΑ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ', value: 'ΦΙΛΤΡΟ', repairDuration: 0 },
      { text: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', value: 'ΠΟΛΛΑΠΛΑΣΙΑΣΤΗΣ', repairDuration: 0 },
      { text: 'ΕΓΚΕΦΑΛΟΣ', value: 'ΕΓΚΕΦΑΛΟΣ', repairDuration: 0 },
      { text: 'ΨΥΚΤΙΚΟ', value: 'ΨΥΚΤΙΚΟ', repairDuration: 0 },
      { text: 'ΤΑΚΑΚΙΑ', value: 'ΤΑΚΑΚΙΑ', repairDuration: 0 },
      { text: 'ΜΠΟΥΖΙ', value: 'ΜΠΟΥΖΙ', repairDuration: 0 },
      { text: 'ΔΙΣΚΟΠΛΑΚΕΣ', value: 'ΔΙΣΚΟΠΛΑΚΕΣ', repairDuration: 0 },
      { text: 'ΤΑΠΑ ΚΑΡΤΕΡ', value: 'ΤΑΠΑ ΚΑΡΤΕΡ', repairDuration: 0 },
      { text: 'ΙΜΑΝΤΑΣ', value: 'ΙΜΑΝΤΑΣ', repairDuration: 0 },
      { text: 'ΜΠΑΤΑΡΙΑ', value: 'ΜΠΑΤΑΡΙΑ', repairDuration: 0 },
      { text: 'ΣΥΜΠΛΕΚΤΗΣ', value: 'ΣΥΜΠΛΕΚΤΗΣ', repairDuration: 0 },
      { text: 'ΒΟΛΑΝ', value: 'ΒΟΛΑΝ', repairDuration: 0 },
      { text: 'ΑΝΤΛΙΑ', value: 'ΑΝΤΛΙΑ', repairDuration: 0 },
      { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 0 },
      { text: 'ΤΣΙΜΟΥΧΑ', value: 'ΤΣΙΜΟΥΧΑ', repairDuration: 0 },
      { text: 'ΕΛΑΤΗΡΙΟ', value: 'ΕΛΑΤΗΡΙΟ', repairDuration: 0 },
      { text: 'ΒΑΛΒΙΔΑ', value: 'ΒΑΛΒΙΔΑ', repairDuration: 0 },
      { text: 'AD BLUE', value: 'AD BLUE', repairDuration: 0 },
      {
        text: 'ΑΛΛΑΓΗ ΕΜΠ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΕΜΠ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
      {
        text: 'ΑΛΛΑΓΗ ΠΙΣΩ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΠΙΣΩ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
      {
        text: 'ΑΛΛΑΓΗ ΟΛΩΝ ΤΩΝ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΟΛΩΝ ΤΩΝ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Μικρό Service',
    value: 'Μικρό Service',
    details: [
      { text: 'ΛΑΔΙ', value: 'ΛΑΔΙ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', value: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Μεγάλο Service',
    value: 'Μεγάλο Service',
    details: [
      { text: 'ΛΑΔΙ', value: 'ΛΑΔΙ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', value: 'ΦΙΛΤΡΟ ΛΑΔΙΟΥ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΑΕΡΟΣ', value: 'ΦΙΛΤΡΟ ΑΕΡΟΣ', repairDuration: 0 },
      { text: 'ΦΙΛΤΡΟ ΚΑΜΠΙΝΑΣ', value: 'ΦΙΛΤΡΟ ΚΑΜΠΙΝΑΣ', repairDuration: 0 },
      {
        text: 'ΦΙΛΤΡΟ ΠΕΤΡΕΛΑΙΟΥ',
        value: 'ΦΙΛΤΡΟ ΠΕΤΡΕΛΑΙΟΥ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Service A/C & Καλοριφέρ',
    value: 'Service A/C & Καλοριφέρ',
    details: [
      { text: 'SERVICE A/C', value: 'SERVICE A/C', repairDuration: 0 },
      { text: 'ΦΡΕΟΝ', value: 'ΦΡΕΟΝ', repairDuration: 0 },
      { text: 'ΚΑΛΟΡΙΦΕΡ', value: 'ΚΑΛΟΡΙΦΕΡ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Έκδοση Κάρτας Καυσαερίων',
    value: 'Έκδοση Κάρτας Καυσαερίων',
    details: [
      {
        text: 'ΕΚΔΟΣΗ ΚΑΡΤΑΣ ΚΑΥΣΑΕΡΙΩΝ',
        value: 'ΕΚΔΟΣΗ ΚΑΡΤΑΣ ΚΑΥΣΑΕΡΙΩΝ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'KTEO',
    value: 'KTEO',
    details: [{ text: 'ΚΤΕΟ', value: 'ΚΤΕΟ', repairDuration: 0 }],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ελαστικά',
    value: 'Ελαστικά',
    details: [
      {
        text: 'ΑΛΛΑΓΗ ΕΛΑΣΤΙΚΩΝ',
        value: 'ΑΛΛΑΓΗ ΕΛΑΣΤΙΚΩΝ',
        repairDuration: 0,
      },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [],
  },
  {
    text: 'Συνδεσιμότητα',
    value: 'Συνδεσιμότητα',
    details: [
      {
        text: 'ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΜΕ ΤΟ ΚΙΝΗΤΟ',
        value: 'ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΜΕ ΤΟ ΚΙΝΗΤΟ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Τοποθετήσεις / Προσθήκες',
    value: 'Τοποθετήσεις / Προσθήκες',
    details: [
      { text: 'PARKTRONIC', value: 'PARKTRONIC', repairDuration: 0 },
      { text: 'ΦΗΜΕ ΤΖΑΜΙΑ', value: 'ΦΗΜΕ ΤΖΑΜΙΑ', repairDuration: 0 },
      { text: 'ΣΧΑΡΑ', value: 'ΣΧΑΡΑ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'AD Blue',
    value: 'AD Blue',
    details: [
      {
        text: 'ΠΡΟΒΛΗΜΑ ΜΕ ΣΥΣΤΗΜΑ AD BLUE',
        value: 'ΠΡΟΒΛΗΜΑ ΜΕ ΣΥΣΤΗΜΑ AD BLUE',
        repairDuration: 0,
      },
      { text: 'ΠΛΗΡΩΣΗ AD BLUE', value: 'ΠΛΗΡΩΣΗ AD BLUE', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Ανάκληση',
    value: 'Ανάκληση',
    details: [
      {
        text: 'ΜΗΧΑΝΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        value: 'ΜΗΧΑΝΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        repairDuration: 0,
      },
      {
        text: 'ΗΛΕΚΤΡΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        value: 'ΗΛΕΚΤΡΟΛΟΓΙΚΗΣ ΦΥΣΗΣ',
        repairDuration: 0,
      },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Κλοπή',
    value: 'Κλοπή',
    details: [
      { text: 'ΜΕΡΙΚΗ ΚΛΟΠΗ', value: 'ΜΕΡΙΚΗ ΚΛΟΠΗ', repairDuration: 0 },
      { text: 'ΟΛΙΚΗ ΚΛΟΠΗ', value: 'ΟΛΙΚΗ ΚΛΟΠΗ', repairDuration: 0 },
    ],
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'instadelivery',
    value: 'instadelivery',
    details: [], // empty
    sideOne: [],
    sideTwo: [],
  },
  {
    text: 'Γυάλισμα',
    value: 'Γυάλισμα',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
      { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Τοπική βαφή',
    value: 'Τοπική βαφή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 2 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 2 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 2 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 2 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 2 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 2 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 2 },
      { text: 'ΖΑΝΤΕΣ', value: 'ΖΑΝΤΕΣ', repairDuration: 2 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 2 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 2 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 2 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 2 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 2 },
      { text: 'ΦΑΣΑ', value: 'ΦΑΣΑ', repairDuration: 2 },
      { text: 'ΣΠΟΙΛΕΡ', value: 'ΣΠΟΙΛΕΡ', repairDuration: 2 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
  {
    text: 'Βαφή / Επισκευή',
    value: 'Βαφή / Επισκευή',
    details: [
      { text: 'ΠΟΡΤΑ', value: 'ΠΟΡΤΑ', repairDuration: 6 },
      { text: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', value: 'ΠΡΟΦΥΛΑΚΤΗΡΑΣ', repairDuration: 6 },
      { text: 'ΦΤΕΡΟ', value: 'ΦΤΕΡΟ', repairDuration: 6 },
      { text: 'ΚΑΠΩ', value: 'ΚΑΠΩ', repairDuration: 6 },
      { text: 'ΠΟΡΤ ΠΑΓΚΑΖ', value: 'ΠΟΡΤ ΠΑΓΚΑΖ', repairDuration: 6 },
      { text: 'ΤΟΞΟ', value: 'ΤΟΞΟ', repairDuration: 6 },
      { text: 'ΟΡΟΦΗ', value: 'ΟΡΟΦΗ', repairDuration: 6 },
      { text: 'ΦΡΥΔΙ', value: 'ΦΡΥΔΙ', repairDuration: 6 },
      { text: 'ΚΟΛΟΝΑ', value: 'ΚΟΛΟΝΑ', repairDuration: 6 },
      { text: 'ΚΑΘΡΕΠΤΗΣ', value: 'ΚΑΘΡΕΠΤΗΣ', repairDuration: 6 },
      { text: 'ΤΑΠΕΤΣΑΡΙΑ', value: 'ΤΑΠΕΤΣΑΡΙΑ', repairDuration: 6 },
      { text: 'ΑΕΡΟΤΟΜΗ', value: 'ΑΕΡΟΤΟΜΗ', repairDuration: 6 },
      { text: 'ΜΑΡΣΠΙΕ', value: 'ΜΑΡΣΠΙΕ', repairDuration: 6 },
      { text: 'ΠΑΡΑΘΥΡΟ', value: 'ΠΑΡΑΘΥΡΟ', repairDuration: 6 },
      { text: 'ΤΖΑΜΑΚΙ', value: 'ΤΖΑΜΑΚΙ', repairDuration: 6 },
      { text: 'ΠΑΡΜΠΡΙΖ', value: 'ΠΑΡΜΠΡΙΖ', repairDuration: 6 },
    ],
    sideOne: [
      { text: 'ΕΜΠΡΟΣ', value: 'ΕΜΠΡΟΣ' },
      { text: 'ΠΙΣΩ', value: 'ΠΙΣΩ' },
    ],
    sideTwo: [
      { text: 'ΔΕΞΙΑ', value: 'ΔΕΞΙΑ' },
      { text: 'ΑΡΙΣΤΕΡΑ', value: 'ΑΡΙΣΤΕΡΑ' },
    ],
  },
];

export const FLEET_VEHICLE_DOC_ENUMS = {
  CarLicense: 0,
  KTEO: 23,
  VehicleInsurance: 25,
  Other: 27,
  ExhaustCard: 29,
  VehicleLeaseAgreement: 40,
  RetailPriceBefofeTaxCertificate: 42,
  VehicleCertificateOfConformity: 43,
  CodeRadio: 44,
  CodeVehicle: 45,
};

export const FLEET_VEHICLE_DOC_TYPES = [
  { value: 27, text: 'Άλλο' },
  { value: 0, text: 'Άδεια Κυκλοφορίας Οχήματος' },
  { value: 23, text: 'KTEO' },
  { value: 25, text: 'Ασφάλεια Οχήματος' },
  { value: 29, text: 'Κάρτα Καυσαερίων' },
  { value: 42, text: 'Βεβαίωση Λιανικής Τιμής Προ Φόρων' },
  { value: 45, text: 'Κωδικός Οχήματος' },
  { value: 44, text: 'Κωδικός Ραδιοφώνου' },
  { value: 40, text: 'Μισθωτήριο Συμβόλαιο Οχήματος' },
  { value: 43, text: 'Πιστοποιητικό Ταξινόμησης Οχήματος' },
  { value: 20, text: 'Σύμβαση' },
  { value: 14, text: 'Τιμολόγιο' },
];

export const CAMPAIGN_RULES_TYPES = {
  origin: 11,
  vehicleEngine: 13,
  skus: 15,
  vehicles: 16,
  prices: 17,
};

export const RESERVATIONS_TYPES = {
  free: { value: -1, text: 'Free' },
  reserved: { value: 0, text: 'Reserved' },
  onhold: { value: 1, text: 'On Hold' },
  forsale: { value: 2, text: 'For Sale' },
  defleeted: { value: 3, text: 'Defleeted' },
  predeltemp: { value: 4, text: 'Predel/Temp' },
  booked: { value: 5, text: 'Booked' },
  forreplace: { value: 6, text: 'For Replace' },
};

export const AVAILABILITY_STATUSES = {
  onlease: { value: 0, text: 'On Lease' },
  onleasearm: { value: 1, text: 'On Lease ARM' },
  forreturn: { value: 2, text: 'For Return' },
  arm: { value: 3, text: 'ARM' },
  vehicleinspectionpending: { value: 4, text: 'Vehicle Inspection Pending' },
  available: { value: 5, text: 'Available' },
  fordelivery: { value: 6, text: 'For Delivery' },
  forsale: { value: 7, text: 'For Sale' },
};
export const RESERVATIONS_SUBSCRIPTION_TYPES = {
  3: 'Temp',
  4: 'Predel',
};

export const REASONS_OF_REJECTION = [
  { text: 'Price too low', value: 'Price too low' },
  { text: 'Price too high', value: 'Price too high' },
  { text: 'Data inaccuracy', value: 'Data inaccuracy' },
  { text: 'Vehicle not for sale', value: 'Vehicle not for sale' },
  { text: 'Other (please specify)', value: 'Other' },
];
