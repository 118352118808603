import { omit } from 'lodash';

export const getGreenCardTaskPayload = (values) => {
  const clearableFields = [];

  Object.keys(values)?.forEach((key) => {
    if (values[key] === '' && !clearableFields.includes(key)) {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'changelog',
    'comments',
    'createdAt',
    'createdBy',
    'customFields.carId',
    'customFields.comments',
    'customFields.delivered',
    'customFields.rejectedReason',
    'customFields.servicePoint',
    'customFields.subscriptionId',
    'customFields.userFullName',
    'customFields.userId',
    'customFields.vehicleBrand',
    'customFields.vehicleDescription',
    'customFields.vehicleEdition',
    'customFields.vehicleFuel',
    'customFields.vehicleImage',
    'customFields.vehicleModel',
    'customFields.vehicleOwner',
    'customFields.vehiclePlate',
    'customFields.vehicleTransmission',
    'customFields.vehicleYear',
    'customFields.vin',
    'documents',
    'images',
    'name',
    'parent',
    'status',
    'tags',
    'updatedAt',
    'updatedBy',
  ]);

  return {
    customFields: {
      ...cleanedValues.customFields,
      country: cleanedValues.customFields?.country?.map((c) => c?.value),
      mixedInsuranceExtensionPrice: cleanedValues.customFields
        ?.mixedInsuranceExtensionPrice?.number
        ? `${cleanedValues.customFields?.mixedInsuranceExtensionPrice?.number}`
        : null,
      customerCost: cleanedValues.customFields?.customerCost?.number
        ? `${cleanedValues.customFields?.customerCost?.number}`
        : null,
    },
  };
};
