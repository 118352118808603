import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DynamicForm, Button } from '@components';
import { utilitiesServices } from '@services';
import { formatDate, showError } from '@utils';
import { searchVatForm } from './searchVatForm';

const SearchVAT = () => {
  const [loading, setLoading] = useState(false);
  const [generalInformation, setGeneralInformation] = useState([]);
  const [activites, setActivities] = useState([]);

  const _handleInformationData = (data) => {
    return [
      { label: 'ΑΦΜ', value: data?.afm },
      { label: 'Eπωνυμία', value: data?.onomasia },
      {
        label: 'ΔΟΥ',
        value: data?.doy_description,
      },
      {
        label: 'Διεύθυνση',
        value: `${data?.postal_address?.trim()} ${data?.postal_address_no.trim()} ${data?.postal_area_description?.trim()}, ${data?.postal_zip_code?.trim()}`,
      },
      {
        label: 'Eναρξη',
        value: formatDate(data?.registration_date, 'dddd, MMMM Do YYYY'),
      },
    ];
  };

  const _handleActivitiesData = (data) => {
    return data?.map((activity) => {
      return activity?.description;
    });
  };

  const _onSubmit = async (form) => {
    try {
      setLoading(true);
      const response = await utilitiesServices.getVAT(form);
      const newInformations = _handleInformationData(response?.data?.result);
      const newActivities = _handleActivitiesData(response?.data?.activities);
      setGeneralInformation(newInformations);
      setActivities(newActivities);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            gap: '15px',
          }}
        >
          <DynamicForm
            onSubmit={_onSubmit}
            loading={loading}
            formScheme={searchVatForm}
          />
          <Button
            disabled={loading}
            type="submit"
            form="dynamic-form"
            color="secondary"
          >
            Search VAT
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: '30px',
        }}
      >
        {generalInformation?.length > 0 && (
          <Box>
            <Typography fontSize={20} fontWeight="bold">
              General Information
            </Typography>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                wordWrap: 'break-word',
                backgroundColor: '#fff',
                backgroundClip: 'border-box',
                border: '1px solid rgba(72, 94, 144, 0.16)',
                padding: 2,
                marginBottom: 4,
                borderRadius: '8px',
                boxShadow: '1px 3px 5px rgb(0 0 0 / 10%)',
              }}
            >
              {generalInformation?.map((information) => {
                return (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                  >
                    <Typography fontWeight="bold">
                      {information?.label}:
                    </Typography>
                    <Typography>{information?.value}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        {activites?.length > 0 && (
          <Box>
            <Typography fontSize={20} fontWeight="bold">
              Company activities
            </Typography>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                wordWrap: 'break-word',
                backgroundColor: '#fff',
                backgroundClip: 'border-box',
                border: '1px solid rgba(72, 94, 144, 0.16)',
                padding: 2,
                marginBottom: 4,
                borderRadius: '8px',
                boxShadow: '1px 3px 5px rgb(0 0 0 / 10%)',
              }}
            >
              {activites?.map((activity, index) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      backgroundColor: !(index % 2) && '#eee',
                      padding: '8px',
                    }}
                  >
                    <Typography>{activity}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const memoizedSearchVAT = React.memo(SearchVAT);
export { memoizedSearchVAT as SearchVAT };
