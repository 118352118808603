import { request } from '@utils';

const getDailyBookings = async () => {
  const results = await request.get(`/v1/graphs/bookings/daily`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getDailyBookings };
