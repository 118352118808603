export const ORDERS_STATUS = {
  0: 'Created',
  1: 'Paid',
  2: 'Completed',
  3: 'Canceled',
  4: 'Refunded',
  5: 'Failed',
};

export const TRANSACTIONS_STATUS = {
  0: 'Success',
  1: 'Failed',
  2: 'Refund',
};
