import { formatVehicleStage } from '@utils';

export const handleVehicles = (array = []) => {
  if (!array) {
    return [];
  }
  return array.map((item) => ({
    id: item.id,
    internalId: item.internalId,
    sku: item.skuId,
    description: item.sku?.name,
    vin: item.vin,
    plate: item.plate,
    tenant: item.tenant,
    color: item.properties?.color,
    condition: item.condition,
    kilometersAtPurchase: item.procurement.kilometersAtPurchase,
    procurementStage: formatVehicleStage(item.procurement.currentStageId),
    vehicleOwnership: item.procurement.vehicleOwnership,
    orderDate: item.procurement.orderDate,
    cancelledDate: item.procurement.cancelledDate,
    dealer: item.procurement.dealer,
    supplierCarId: item.procurement.supplierCarId,
    agreedCustomsDate: item.procurement.agreedCustomsDate,
    retailPrice: item.procurement.retailPrice,
    retailPriceBeforeTax: item.procurement.retailPriceBeforeTax,
    vat: item.procurement.vat,
    extras: item.procurement.extras,
    extrasPrice: item.procurement.extrasPrice,
    specialClassificationFee: item.procurement.specialClassificationFee,
    discountWithoutVat: item.procurement.discountWithoutVat,
    discountPercentage: item.procurement.discountPercentage,
    agreedPurchasePrice: item.procurement.agreedPurchasePrice,
    finalPurchasePrice: item.procurement.finalPurchasePrice,
    estimatedCustomsDate: item.procurement.estimatedCustomsDate,
    customsEntryDate: item.procurement.customsEntryDate,
    financingProvider: item.procurement.financingProvider,
    plannedDownpaymentDate: item.procurement.plannedDownpaymentDate,
    licenseIssuanceDate: item.procurement.licenseIssuanceDate,
    insuranceProvider: item.procurement.insuranceProvider,
    insuranceDate: item.procurement.insuranceDate,
    insuranceRenewalDate: item.procurement.insuranceRenewalDate,
    plannedReceiptDate: item.procurement.plannedReceiptDate,
    receiptDate: item.procurement.receiptDate,
    sourcingType: item.procurement.sourcingType,
    financingType: item.procurement.financingType,
    vehiclePurchaseDate: item.procurement.vehiclePurchaseDate,
    leaseStartDate: item.procurement.leaseStartDate,
    leaseMonthlyInstallment: item.procurement.leaseMonthlyInstallment,
    currentLocation: item.procurement.currentLocation,
    defleetedDate: item.procurement.defleetedDate,
    invoiceNumber: item.invoiceDetails?.invoiceNumber,
    netPrice: item.invoiceDetails?.netPrice,
    registrationTax: item.invoiceDetails?.registrationTax,
    invoiceDetailsVat: item.invoiceDetails?.vat,
    transferFee: item.invoiceDetails?.transferFee,
    plateReleaseFee: item.invoiceDetails?.plateReleaseFee,
  }));
};

export const handleEnums = (results = []) => {
  const dealers = results[1]?.data.map((item) => ({
    value: item.slug,
    label: item.name,
  }));
  const colors = results[2]?.data.map((item) => ({
    value: item.slug,
    label: item.name,
  }));
  const insurances = results[3]?.data.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const financing = Object.keys(results[4]?.data?.financingProviders)?.map(
    (item) => ({
      value: results[4]?.data?.financingProviders[item].name,
      label: results[4]?.data?.financingProviders[item].name,
    })
  );
  return {
    sourcingTypes: results[0]?.data || [],
    dealers,
    colors,
    insurances,
    financing,
  };
};
