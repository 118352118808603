import dynamic from 'next/dynamic';

const SellRequestsGrid = dynamic(() =>
  import('./SellRequestsGrid/SellRequestsGrid')
);

const AddSellRequestsModal = dynamic(() =>
  import('./AddSellRequestsModal/AddSellRequestsModal')
);

const SellRequestsForm = dynamic(() =>
  import('./SellRequestsForm/SellRequestsForm')
);

const SellRequestsVehicleHeader = dynamic(() =>
  import('./SellRequestsVehicleHeader/SellRequestsVehicleHeader')
);

export {
  SellRequestsGrid,
  AddSellRequestsModal,
  SellRequestsForm,
  SellRequestsVehicleHeader,
};
