import { request } from '@utils';

const getConfigurations = async () => {
  const params = {
    page: 1,
    pageSize: 100,
  };
  const results = await request.get(`/v1/configurations`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addConfiguration = async (payload) => {
  const params = {
    body: payload,
  };

  const results = await request.post(`/v1/configurations`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateConfiguration = async (slug, payload) => {
  const params = {
    body: payload,
  };

  const results = await request.patch(`/v1/configurations/${slug}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getConfigurations, addConfiguration, updateConfiguration };
