import { request } from '@utils';
import { LIMIT } from '@constants';

const getEbikes = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }
  const results = await request.get(`/v1/instafleet,catalog/ebikes`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikesFilters = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }

  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}&order=${payload?.order}&orderBy=${payload?.orderBy}`;
  delete payload?.pageSize;
  delete payload?.page;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/catalog/ebikes/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikesEnums = async () => {
  const results = await request.get(`/v1/catalog/ebikes/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbike = async (id) => {
  const results = await request.get(`/v1/instafleet/catalog/ebikes/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikeEnums = async () => {
  const results = await request.get(`/v1/catalog/ebikes/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikeProviders = async () => {
  const results = await request.get(`/v1/vehicles/config/providers`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikesModels = async () => {
  const results = await request.get(`/v1/ebikes/config/models`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postEbike = async (params) => {
  const payload = {
    body: params,
  };
  const results = await request.post(`/v1/catalog/ebikes`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editEbikeImage = async (
  skuId,
  imageId,
  imageTag,
  caption,
  description,
  order
) => {
  const body = {
    body: {
      tags: imageTag,
      caption,
      description,
      order,
    },
  };
  const results = await request.patch(
    `/v1/catalog/ebikes/${skuId}/images/${imageId}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postEbikeImage = async (
  skuId,
  file,
  imageTag,
  caption,
  description,
  order
) => {
  const fd = new FormData();
  fd.append('images', file);
  fd.append('tags', imageTag);
  fd.append('caption', caption);
  fd.append('description', description);
  fd.append('order', order);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/catalog/ebikes/${skuId}/images`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postEbikeFile = async (skuId, file) => {
  const fd = new FormData();
  fd.append('specs_sheet', file);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(`/v1/catalog/ebikes/${skuId}/docs`, body);
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const updateEbike = async (skuId, body) => {
  const params = {
    body,
  };
  const results = await request.patch(`/v1/catalog/ebikes/${skuId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteEbikeImage = async (payload) => {
  const results = await request.remove(
    `/v1/catalog/ebikes/${payload?.skuId}/images/${payload?.id}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteEbikeFile = async (payload) => {
  const results = await request.remove(
    `/v1/catalog/ebikes/${payload?.skuId}/docs/${payload?.id}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getEbikesBySku = async (id, params = {}) => {
  const results = await request.get(
    `/v1/vehicles/sku/${id}?${new URLSearchParams(params)}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postEbikePrimaryImage = async (skuId, imageId) => {
  const results = await request.post(
    `/v1/catalog/ebikes/${skuId}/images/${imageId}/select`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addPricing = async (skuId, payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/catalog/ebikes/${skuId}/pricing`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updatePricing = async (skuId, pricingId, body) => {
  const params = {
    body,
  };
  const results = await request.patch(
    `/v1/catalog/ebikes/${skuId}/pricing/${pricingId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getEbikes,
  getEbikeEnums,
  getEbikeProviders,
  getEbike,
  getEbikesModels,
  postEbike,
  postEbikeImage,
  editEbikeImage,
  updateEbike,
  deleteEbikeImage,
  deleteEbikeFile,
  getEbikesEnums,
  postEbikeFile,
  getEbikesFilters,
  getEbikesBySku,
  postEbikePrimaryImage,
  addPricing,
  updatePricing,
};
