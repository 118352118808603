import { request } from '@utils';
import { LIMIT } from '@constants';

const getOrdersFilters = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }

  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}&order=${payload?.order}&orderBy=${payload?.orderBy}`;
  delete payload?.pageSize;
  delete payload?.page;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/orders/filter${queryParams}`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getOrdeById = async (orderId) => {
  const results = await request.get(`/v1/orders/${orderId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getTransactionsByOrderId = async (orderId) => {
  const params = {
    body: {
      field: 'orderId',
      operator: 0,
      type: 5,
      value: orderId,
    },
  };
  const results = await request.post(`/v1/transactions/filter`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const generateReceipt = async (orderId) => {
  const results = await request.post(`/v1/orders/${orderId}/receipts/generate`);
  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const markOrderAsPaid = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(
    `/v1/marketplace/orders/transactions`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const refundTransaction = async ({ orderId, trnsId }, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.remove(
    `/v1/marketplace/orders/${orderId}/transactions/${trnsId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getOrdersFilters,
  getOrdeById,
  getTransactionsByOrderId,
  generateReceipt,
  markOrderAsPaid,
  refundTransaction,
};
