import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { generateShortId } from '@utils';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Box } from '@mui/material';
import DraggableImage from './DraggableImage';

const Images = ({
  images,
  selectedOption,
  toggleShowImage,
  toggleFormModal,
  onDeleteImage,
  setImage,
  reorderImages,
  onOrderChange,
}) => {
  const [items, setItems] = useState(images);

  const _moveImage = (imageId, index) => {
    let newImages = _.cloneDeep(items);
    const draggingItem = newImages
      ?.filter((image) => image?.id === imageId)
      ?.pop();
    if (index > draggingItem?.order) return;
    newImages = newImages
      ?.map((image) => {
        if (image?.id === imageId) {
          image.order = index;

          if (onOrderChange && !image?.localFile) {
            onOrderChange({
              ...image,
              index,
            });
          }
        } else if (image?.order >= index && index <= newImages?.length) {
          image.order += 1;
        }
        return image;
      })
      .sort((a, b) => a.order - b.order)
      ?.map((image, i) => {
        return { ...image, order: i };
      });
    setItems(newImages);
    reorderImages(newImages);
  };

  useEffect(() => {
    setItems(images);
  }, [images, images.length]);

  return (
    <Box
      sx={{
        maxWidth: '1000px',
        width: '-webkit-fill-available',
      }}
    >
      {items?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginBottom: '84px',
            width: '100%',
          }}
        >
          {items
            ?.filter((img) => {
              return img?.tags?.includes(selectedOption);
            })
            ?.map?.((image) => {
              return (
                <DraggableImage
                  key={`draggableImage-${generateShortId()}`}
                  onMoveImage={_moveImage}
                  image={image}
                  index={image?.order}
                  toggleShowImage={() => {
                    setImage(image);
                    toggleShowImage();
                  }}
                  toggleFormModal={() => {
                    setImage(image);
                    toggleFormModal();
                  }}
                  onDeleteImage={() => {
                    setImage(image);
                    onDeleteImage();
                  }}
                />
              );
            })}
        </Box>
      )}
    </Box>
  );
};

const dragDropContext = DragDropContext(HTML5Backend)(Images);
export { dragDropContext as Images };
