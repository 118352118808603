import dynamic from 'next/dynamic';

const OrdersGrid = dynamic(() => import('./OrdersGrid/OrdersGrid'));
const OrderFilters = dynamic(() => import('./OrderFilters/OrderFilters'));
const TransactionsGrid = dynamic(() =>
  import('./TransactionsGrid/TransactionsGrid')
);

const MarkAsPaidModal = dynamic(() =>
  import('./MarkAsPaidModal/MarkAsPaidModal')
);

const RefundTransaction = dynamic(() =>
  import('./RefundTransaction/RefundTransaction')
);

export {
  OrdersGrid,
  OrderFilters,
  TransactionsGrid,
  MarkAsPaidModal,
  RefundTransaction,
};
