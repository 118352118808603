import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectVendorsState, getVendors } from '@store';
import { showError } from '@utils';
import { useLayout } from 'hooks/layout';

export const useVendorsOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    vendors,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectVendorsState);
  const {
    push,
    query: { page = 1, orderBy = '', order = '', query = '' },
    isReady,
  } = useRouter();
  const { tenant } = useLayout();
  const dispatch = useDispatch();

  const _getVendors = useCallback(async () => {
    await dispatch(getVendors({ orderBy, order, page, query, tenant }));
  }, [orderBy, order, page, query, tenant, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/vendors?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}&query=${query}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/vendors?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, query]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/vendors?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}&query=${query}`;
      if (!orderBy || !order) {
        url = `/vendors?page=${currentPage}&query=${query}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, query, push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getVendors();
    }
  }, [_getVendors, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    vendors,
    pageCount,
    hasErrors,
    errorMessage,
    getVendors: _getVendors,
    nextPage: _nextPage,
    sortBy: _sortBy,
    query,
  };
};
