const sortArray = (array, sortBy = 'text', order = 'asc') => {
  return array.sort((a, b) => {
    if (order === 'asc') {
      return a[sortBy].localeCompare(b[sortBy]);
    }
    return b[sortBy].localeCompare(a[sortBy]);
  });
};

export default sortArray;
