import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectServiceVehiclesState, getServiceVehicles } from '@store';
import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';

export const useServiceVehiclesOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    serviceVehicles,
    serviceVehiclesTypes,
    pageSize,
    hasErrors,
    errorMessage,
  } = useSelector(selectServiceVehiclesState);
  const {
    push,
    query: { page = 1, orderBy = '', order = '', token, ...others },
    isReady,
  } = useRouter();
  const [filters, setFilters] = useState(null);
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _getServiceVehicles = useCallback(
    async (payload = {}) => {
      let { dropdownServiceVehiclesFilterRules } = localStorage;
      if (dropdownServiceVehiclesFilterRules) {
        dropdownServiceVehiclesFilterRules = JSON.parse(
          dropdownServiceVehiclesFilterRules
        );
      } else {
        dropdownServiceVehiclesFilterRules = [];
      }
      if (dropdownServiceVehiclesFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: dropdownServiceVehiclesFilterRules,
        };
      } else {
        payload = {
          type: FILTER_RULE_ENUMS.EmptyRuleType,
        };
      }
      await dispatch(getServiceVehicles({ ...payload, orderBy, order, page }));
    },
    [page, orderBy, order, dispatch]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/serviceVehicles?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy && sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel.orderBy || ''}&order=${
          sortingModel.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/serviceVehicles?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/serviceVehicles?page=${currentPage}&${_otherQueries}`;

      if (orderBy && order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/serviceVehicles?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getServiceVehicles();
      }
    }, 100);
  }, [page, token, _getServiceVehicles, fetchOnMount, isReady]);

  useEffect(() => {
    if (filters) {
      _getServiceVehicles(filters);
    }
  }, [filters, _getServiceVehicles, fetchOnMount]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    serviceVehicles,
    serviceVehiclesTypes,
    pageSize,
    hasErrors,
    errorMessage,
    getServiceVehicles: _getServiceVehicles,
    nextPage: _nextPage,
    sortBy: _sortBy,
    setFilters,
  };
};
