import { useState } from 'react';

export const useColumnVisibility = (apiRef, initialGridColumns) => {
  const [gridColumns, setGridColumns] = useState(initialGridColumns);

  const handleChangeVisibility = (column) => {
    // reset columns functionality

    if (!column) {
      setGridColumns(initialGridColumns);
      initialGridColumns.forEach((col) => {
        apiRef.current.setColumnVisibility(col.key, col.value);
      });
      return;
    }

    const visibleColumns = apiRef.current.getVisibleColumns();
    const isSelectedColumnVisible = visibleColumns.find(
      (col) => col.field === column.key
    );

    setGridColumns(
      gridColumns.map((col) => ({
        ...col,
        value: col.key === column.key ? !col.value : col.value,
      }))
    );
    apiRef.current.setColumnVisibility([column.key], !isSelectedColumnVisible);
  };

  return { handleChangeVisibility, gridColumns };
};

export default useColumnVisibility;
