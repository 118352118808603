import { request } from '@utils';

const getRoutes = async () => {
  const results = await request.get(`/v1/routes`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getRoutes };
