import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { permissionsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { handlePermissionsData } from './permissions.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  permissions: [],
  total: 0,
  pageCount: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    fetchPermissions: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchPermissionssSuccess: (state, { payload }) => {
      state.permissions = handlePermissionsData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.permissions.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.permissions?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchPermisionsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchPermissions,
  fetchPermissionssSuccess,
  fetchPermisionsFailure,
} = permissionsSlice.actions;

export const selectPermissionsState = (state) => state.permissions;

export const getPermissions = (payload) => {
  return async (dispatch) => {
    dispatch(fetchPermissions());

    try {
      const page = parseInt(payload?.page, 10);

      const permissions = await permissionsServices?.getPermissions(payload);
      permissions.meta.page = page;

      dispatch(fetchPermissionssSuccess(permissions));
    } catch (error) {
      dispatch(fetchPermisionsFailure(error));
    }
  };
};

export default permissionsSlice.reducer;
