import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { agentsServices, boardsServices } from '@services';
import { AGENT_ROLES, BOARDS_ID, GENERIC_ERROR_MESSAGE } from '@constants';
import { groupBy } from 'lodash';
import { TRAFFIC_OFFENSES_TASK_STAGES } from 'components/trafficOffenses/constants';
import {
  handleTrafficOffensesData,
  handleAssigneeData,
} from './trafficOffenses.handlers';
// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  boadId: BOARDS_ID.TRAFFIC_OFFENSES,
  data: {
    tasks: [],
    columns: [],
  },
  assignees: [],
  total: 0,
  pageCount: 0,
  pageSize: 100,
};
// Actual Slice
export const trafficOffensesSlice = createSlice({
  name: 'trafficOffenses',
  initialState,
  reducers: {
    fetchTrafficOffenses: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTrafficOffensesSuccess: (state, { payload }) => {
      state.data = handleTrafficOffensesData(
        payload?.response?.data,
        payload?.assignees
      );
      state.assignees = handleAssigneeData(payload?.assignees);
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTrafficOffensesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchTrafficOffenses,
  fetchTrafficOffensesSuccess,
  fetchTrafficOffensesFailure,
} = trafficOffensesSlice.actions;

export const selectTrafficOffensesState = (state) => state.trafficOffenses;
export const selectTrafficOffensesColumnsState = ({ trafficOffenses }) => {
  const { tasks } = trafficOffenses?.data || {};
  const order = [0, 2, 15, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');
  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: TRAFFIC_OFFENSES_TASK_STAGES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: TRAFFIC_OFFENSES_TASK_STAGES[cur].styles,
      value: true,
      label: TRAFFIC_OFFENSES_TASK_STAGES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};
export const getTrafficOffenses = (payload) => {
  return async (dispatch) => {
    dispatch(fetchTrafficOffenses());
    const page = parseInt(payload?.page, 10);

    try {
      const { data: customerSuccessCoordinatorAssignees } =
        await agentsServices.getAgents({
          pageSize: 100,
          role: AGENT_ROLES.isCustomerSuccessCoordinator,
        });
      const { data: customerSuccessTeamLeadAssignees } =
        await agentsServices.getAgents({
          pageSize: 100,
          role: AGENT_ROLES.isCustomerSuccessTeamLead,
        });
      const response = await boardsServices.getBoardsFilters(payload);
      response.meta.page = page;

      dispatch(
        fetchTrafficOffensesSuccess({
          response,
          assignees: [
            ...customerSuccessCoordinatorAssignees,
            ...customerSuccessTeamLeadAssignees,
          ],
        })
      );
    } catch (error) {
      dispatch(fetchTrafficOffensesFailure(error));
    }
  };
};

export default trafficOffensesSlice.reducer;
