import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVehiclesState,
  getVehicle,
  addVehicle,
  resetVehicle,
  editVehicle,
  deleteVehicleImage,
  deleteVehicleFile,
  getVehiclesBySku,
} from '@store';
import { showError } from '@utils';
import { LIMIT } from '@constants';

export const useVehicleOperations = () => {
  const {
    loading,
    vehicle,
    hasErrors,
    errorMessage,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
  } = useSelector(selectVehiclesState);
  const {
    query: { vehicleId, tab },
  } = useRouter();
  const dispatch = useDispatch();

  const _getVehicle = useCallback(async () => {
    await dispatch(getVehicle(vehicleId));
    await dispatch(getVehiclesBySku(vehicleId));
  }, [dispatch, vehicleId]);

  const _postVehicle = useCallback(
    async (payload) => {
      await dispatch(addVehicle(payload));
    },
    [dispatch]
  );

  const _deleteVehicleImage = useCallback(
    async (payload) => {
      await dispatch(deleteVehicleImage(payload));
    },
    [dispatch]
  );

  const _deleteVehicleFile = useCallback(
    async (payload) => {
      await dispatch(deleteVehicleFile(payload));
    },
    [dispatch]
  );

  const _editVehicle = useCallback(
    async (payload) => {
      await dispatch(editVehicle(payload));
    },
    [dispatch]
  );

  const _nextPageVehiclesBySku = (currentPage) => {
    dispatch(
      getVehiclesBySku(vehicleId, { page: currentPage, pageSize: LIMIT })
    );
  };

  const _resetVehicle = useCallback(async () => {
    dispatch(resetVehicle());
  }, [dispatch]);

  useEffect(() => {
    _getVehicle();
  }, [_getVehicle]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (postErrorMessage || errorMessage) {
      showError(postErrorMessage || errorMessage);
    }
    if (vehicle?.vehiclesBySku?.errorMessage) {
      showError(vehicle.vehiclesBySku.errorMessage);
    }
  }, [postErrorMessage, errorMessage, vehicle?.vehiclesBySku?.errorMessage]);

  return {
    loading,
    vehicle,
    hasErrors,
    errorMessage,
    getVehicle: _getVehicle,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
    vehicleId,
    postVehicle: _postVehicle,
    resetVehicle: _resetVehicle,
    editVehicle: _editVehicle,
    deleteVehicleImage: _deleteVehicleImage,
    deleteVehicleFile: _deleteVehicleFile,
    nextPageVehiclesBySku: _nextPageVehiclesBySku,
    tab,
  };
};
