import { timeFromNow } from '@utils';

export const handleNotifications = (array) => {
  if (!array) {
    return [];
  }
  return array?.map((notification) => {
    return {
      timeAgo: timeFromNow(notification?.createdAt),
      id: notification?.id,
      objectId: notification?.objectId,
      objectType: notification?.objectType,
      message: notification?.message,
      viewed: notification?.viewed,
    };
  });
};
