import { mediaServices } from '@services';
import { cloneDeep } from 'lodash';
import { useState } from 'react';

export const useVehicleImages = ({
  images,
  vehicle,
  callback,
  variant = 'vehicle',
}) => {
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const getImages = () => {
    const {
      selectedImageId,
      images: vehicleImages,
      mainImage,
    } = vehicle[variant];
    callback(
      vehicleImages.map((image) => {
        if (image.id === selectedImageId || image.id === mainImage) {
          return {
            ...image,
            isPrimary: true,
          };
        }
        return {
          ...image,
          isPrimary: false,
        };
      })
    );
  };

  const handleMoveImage = (imageId, index) => {
    const imagesArray = cloneDeep(images);
    const draggingItemIndex = imagesArray.findIndex(
      (image) => image.id === imageId
    );
    if (draggingItemIndex === -1) {
      return;
    }

    const draggingItem = imagesArray.splice(draggingItemIndex, 1)[0];

    imagesArray.splice(index, 0, draggingItem);

    imagesArray.forEach((image, idx) => {
      image.order = idx;
    });
    callback(imagesArray);
  };

  const handleDeleteImage = (image) => {
    callback((prevState) =>
      prevState.filter((imageItem) => imageItem.id !== image.id)
    );
  };

  const handleUpdateImage = (values) => {
    callback((prevState) =>
      prevState.map((image) => {
        // replace the updated image
        if (image.id === values.id) {
          return { ...values };
        }
        // if the updated image is primary, set all other images to not primary
        if (values.isPrimary) {
          return { ...image, isPrimary: false };
        }
        // otherwise, return the image as is (no change to the other images)
        return { ...image };
      })
    );
  };

  const handleAddImage = async (newImages) => {
    const postImage = (image) =>
      mediaServices.postImage({
        file: image.file,
        entityType: 'catalog',
        entityId: image.id,
        publicAccess: true,
      });
    setIsLoadingImages(true);
    const results = await Promise.all(newImages.map(postImage));

    if (results) {
      results.forEach((result, index) => {
        newImages[index].id = result?.data?.[0];
        newImages[index].isPrimary = false;
      });
      callback((prevState) => [...prevState, ...newImages]);
      setIsLoadingImages(false);
    }
  };
  return {
    getImages,
    handleUpdateImage,
    handleDeleteImage,
    handleMoveImage,
    handleAddImage,
    isLoadingImages,
  };
};
