import dynamic from 'next/dynamic';

const ServiceVehiclesGrid = dynamic(() =>
  import('./ServiceVehiclesGrid/ServiceVehiclesGrid')
);
const ServiceVehicleΙnformation = dynamic(() =>
  import('./ServiceVehicleΙnformation/ServiceVehicleΙnformation')
);
const ServiceVehicleSubscriber = dynamic(() =>
  import('./ServiceVehicleSubscriber/ServiceVehicleSubscriber')
);
const ServiceVehicleSubscription = dynamic(() =>
  import('./ServiceVehicleSubscription/ServiceVehicleSubscription')
);
const ServiceVehicleRequest = dynamic(() =>
  import('./ServiceVehicleRequest/ServiceVehicleRequest')
);
const SlotTimeline = dynamic(() => import('./SlotTimeline/SlotTimeline'));
const SlotsGrid = dynamic(() => import('./SlotsGrid/SlotsGrid'));
const ServiceVehicleComments = dynamic(() =>
  import('./ServiceVehicleComments/ServiceVehicleComments')
);
const SlotsList = dynamic(() => import('./SlotsList/SlotsList'));
const ServiceVehicleFilters = dynamic(() =>
  import('./ServiceVehicleFilters/ServiceVehicleFilters')
);

export {
  ServiceVehiclesGrid,
  ServiceVehicleΙnformation,
  ServiceVehicleSubscriber,
  ServiceVehicleSubscription,
  ServiceVehicleRequest,
  SlotTimeline,
  SlotsGrid,
  ServiceVehicleComments,
  SlotsList,
  ServiceVehicleFilters,
};

/* PLOP_INJECT_COMPONENT_EXPORT */
