import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
} from '@services';
import { AGENT_ROLES, BOARDS_ID, GENERIC_ERROR_MESSAGE } from '@constants';
import {
  handleTrafficOffensesTaskData,
  handleVehicleData,
  handleAssigneeData,
} from './trafficOffensesTask.handlers';

export const INITIAL_TASK_VALUES = {
  changelog: [],
  images: [],
  assignee: '',
  documents: [],
  comments: [],
  customFields: {
    subscriptionId: '',
    userFirstName: '',
    userId: '',
    status: '',
    reason: '',
    platesRemoved: '',
    dateOfViolation: '',
    dateOfCompletion: '',
    violationNumber: '',
    issuedBy: '',
    authorityContactPerson: '',
    authorityContactPhone: '',
    authorityContactEmail: '',
    violationFee: '',
    totalChargeClientAmount: '',
    invoiceDate: '',
    noInstacarCost: '',
  },
};
// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  boadId: BOARDS_ID.TRAFFIC_OFFENSES,
  task: INITIAL_TASK_VALUES,
  assignees: [],
};
// Actual Slice
export const trafficOffensesTaskSlice = createSlice({
  name: 'trafficOffensesTask',
  initialState,
  reducers: {
    fetchTrafficOffensesTask: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTrafficOffensesTaskSuccess: (state, { payload }) => {
      state.task = handleTrafficOffensesTaskData(
        payload?.task,
        payload.assignees
      );
      state.vehicle = handleVehicleData(payload?.vehicle);
      state.assignees = handleAssigneeData(payload.assignees);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTrafficOffensesTaskFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    resetTrafficOffensesTask: (state) => {
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';

      state.task = initialState.task;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchTrafficOffensesTask,
  fetchTrafficOffensesTaskSuccess,
  fetchTrafficOffensesTaskFailure,
  resetTrafficOffensesTask,
} = trafficOffensesTaskSlice.actions;

export const selectTrafficOffensesTaskState = (state) =>
  state.trafficOffensesTask;

export const getTrafficOffensesTask = (taskId) => {
  return async (dispatch) => {
    dispatch(fetchTrafficOffensesTask());

    try {
      const { data: customerSuccessCoordinatorAssignees } =
        await agentsServices.getAgents({
          pageSize: 100,
          role: AGENT_ROLES.isCustomerSuccessCoordinator,
        });
      const { data: customerSuccessTeamLeadAssignees } =
        await agentsServices.getAgents({
          pageSize: 100,
          role: AGENT_ROLES.isCustomerSuccessTeamLead,
        });
      const task = await boardsServices.getTaskById(taskId);
      const carId = task?.data?.customFields?.carId;
      let vehicle = {};
      if (carId) {
        vehicle = await fleetVehiclesServices.getVehicleByInternalId(carId);
      }
      dispatch(
        fetchTrafficOffensesTaskSuccess({
          task: task?.data,
          vehicle: vehicle?.data,
          assignees: [
            ...customerSuccessCoordinatorAssignees,
            ...customerSuccessTeamLeadAssignees,
          ],
        })
      );
    } catch (error) {
      dispatch(fetchTrafficOffensesTaskFailure(error));
    }
  };
};

export default trafficOffensesTaskSlice.reducer;
