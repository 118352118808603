import { request } from '@utils';

const updateProduct = async (productId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/marketplace/products/${productId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { updateProduct };
