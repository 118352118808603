import dynamic from 'next/dynamic';

const DriverCarList = dynamic(() => import('./DriverCarList/DriverCarList'));
const HandOverVehicleDetails = dynamic(() =>
  import('./HandOverVehicleDetails/HandOverVehicleDetails')
);
const DriversOverview = dynamic(() =>
  import('./DriversOverview/DriversOverview')
);
const DriversLastStep = dynamic(() =>
  import('./DriversLastStep/DriversLastStep')
);
const DriverFilters = dynamic(() => import('./DriverFilters/DriverFilters'));
const DriversStep = dynamic(() => import('./DriversStep/DriversStep'));
export {
  DriverCarList,
  HandOverVehicleDetails,
  DriversOverview,
  DriversLastStep,
  DriverFilters,
  DriversStep,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
