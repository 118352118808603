import { request } from '@utils';
import { LIMIT } from '@constants';

const getVendors = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/marketplace/vendors`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVendorById = async (vendorId) => {
  const results = await request.get(`/v1/marketplace/vendors/${vendorId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateVendor = async (vendorId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/marketplace/vendors/${vendorId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getProductsByVendorId = async (vendorId) => {
  const results = await request.get(
    `/v1/marketplace/products?vendorId=${vendorId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getVendors, getVendorById, updateVendor, getProductsByVendorId };
