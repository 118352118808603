import { request } from '@utils';

const postSearch = async (query) => {
  const results = await request.post(`/v1/search?query=${query}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { postSearch };
