import dynamic from 'next/dynamic';

const ReturnsInboxGrid = dynamic(() =>
  import('./ReturnsInboxGrid/ReturnsInboxGrid')
);
const AddReturnModal = dynamic(() => import('./AddReturnModal/AddReturnModal'));

const ReturnsSubTaskList = dynamic(() =>
  import('./ReturnsSubTaskList/ReturnsSubTaskList')
);
const ChangeStatus = dynamic(() => import('./ChangeStatus/ChangeStatus'));
const ChangeTaskTag = dynamic(() => import('./ChangeTaskTag/ChangeTaskTag'));
export {
  ReturnsInboxGrid,
  AddReturnModal,
  ReturnsSubTaskList,
  ChangeStatus,
  ChangeTaskTag,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
