import { formatDate } from '@utils';

export const handleCampaigns = (array) => {
  if (!array) {
    return [];
  }
  const currentDate = new Date().toISOString();
  return array?.map((campaign) => {
    return {
      id: campaign?.id,
      createdAt: campaign?.createdAt
        ? formatDate(campaign?.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
      createdBy: campaign?.createdBy || '-',
      updatedAt: campaign?.updatedAt
        ? formatDate(campaign?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      updatedBy: campaign?.updatedBy || '-',
      startingAt: campaign?.startingAt
        ? formatDate(campaign?.startingAt, 'DD/MM/YYYY HH:mm')
        : '-',
      endingAt: campaign?.endingAt
        ? formatDate(campaign?.endingAt, 'DD/MM/YYYY HH:mm')
        : '-',
      description: campaign?.description || '-',
      isActive:
        campaign?.endingAt === null ||
        (currentDate > campaign?.startingAt &&
          currentDate < campaign?.endingAt),
    };
  });
};
