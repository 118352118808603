import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { agentsServices, teamsServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import {
  handleTeamsData,
  handleTeamData,
  handleAgentsData,
} from './teams.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  teams: [],
  team: {},
  agents: [],
  insurance: {},
  total: 0,
  pageSize: 1000,
};
// Actual Slice
export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    fetchTeams: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTeamsSuccess: (state, { payload }) => {
      const { agents, response } = payload;
      state.teams = handleTeamsData(response?.data, agents?.data);
      state.agents = handleAgentsData(agents?.data);
      state.total = response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTeamsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchTeam: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTeamSuccess: (state, { payload }) => {
      const { agents, response } = payload;
      state.team = handleTeamData(response?.data);
      state.agents = handleAgentsData(agents?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchTeamFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchTeams,
  fetchTeamsSuccess,
  fetchTeamsFailure,
  fetchTeam,
  fetchTeamSuccess,
  fetchTeamFailure,
} = teamsSlice.actions;

export const selectTeamsState = (state) => state.teams;

export const getTeams = (params, payload) => {
  return async (dispatch) => {
    dispatch(fetchTeams());

    try {
      const page = parseInt(payload?.page, 10);
      const [response, agents] = await Promise.all([
        teamsServices?.getTeams(params, payload),
        agentsServices?.getAgents({ pageSize: 1000 }),
      ]);
      response.meta.page = page;

      dispatch(fetchTeamsSuccess({ response, agents }));
    } catch (error) {
      dispatch(fetchTeamsFailure(error));
    }
  };
};

export const getTeam = (teamId) => {
  return async (dispatch) => {
    dispatch(fetchTeam());

    try {
      const [response, agents] = await Promise.all([
        teamsServices?.getTeam(teamId),
        agentsServices?.getAgents({ pageSize: 1000 }),
      ]);

      dispatch(fetchTeamSuccess({ response, agents }));
    } catch (error) {
      dispatch(fetchTeamFailure(error));
    }
  };
};

export default teamsSlice.reducer;
