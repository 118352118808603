import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { communicationServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';

import { handleMailBoxes } from './mailBoxes.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  mailBoxes: [],
  total: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const mailBoxesSlice = createSlice({
  name: 'mailBoxes',
  initialState,
  reducers: {
    fetchMailBoxes: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchMailBoxesSuccess: (state, { payload }) => {
      state.mailBoxes = handleMailBoxes(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchMailBoxesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchMailBoxes, fetchMailBoxesSuccess, fetchMailBoxesFailure } =
  mailBoxesSlice.actions;

export const selectMailBoxesState = (state) => state.mailBoxes;

export default mailBoxesSlice.reducer;

export const getMailBoxes = (params, payload) => {
  return async (dispatch) => {
    dispatch(fetchMailBoxes());

    try {
      const page = parseInt(params?.page, 10);
      const response = await communicationServices?.getEmailBoxes(
        params,
        payload
      );
      response.meta.page = page;

      dispatch(fetchMailBoxesSuccess(response));
    } catch (error) {
      dispatch(fetchMailBoxesFailure(error));
    }
  };
};
