import dynamic from 'next/dynamic';

const FleetVehiclesGrid = dynamic(() =>
  import('./FleetVehiclesGrid/FleetVehiclesGrid')
);
const FleetVehicleMenu = dynamic(() =>
  import('./FleetVehicleMenu/FleetVehicleMenu')
);
const VehicleProcurement = dynamic(() =>
  import('./VehicleProcurement/VehicleProcurement')
);
const VehicleServices = dynamic(() =>
  import('./VehicleServices/VehicleServices')
);
const AddVehicleService = dynamic(() =>
  import('./AddVehicleService/AddVehicleService')
);
const VehicleCosts = dynamic(() => import('./VehicleCosts/VehicleCosts'));
const AddVehicleCost = dynamic(() => import('./AddVehicleCost/AddVehicleCost'));
const VehicleInsurances = dynamic(() =>
  import('./VehicleInsurances/VehicleInsurances')
);
const AddVehicleInsurance = dynamic(() =>
  import('./AddVehicleInsurance/AddVehicleInsurance')
);
const AddVehiclePlate = dynamic(() =>
  import('./AddVehiclePlate/AddVehiclePlate')
);

const AddVehicleMileage = dynamic(() =>
  import('./AddVehicleMileage/AddVehicleMileage')
);
const ConfigureServicePointModal = dynamic(() =>
  import('./ConfigureServicePointModal/ConfigureServicePointModal')
);
const VehicleServicePoints = dynamic(() =>
  import('./VehicleServicePoints/VehicleServicePoints')
);
const VehicleDocuments = dynamic(() =>
  import('./VehicleDocuments/VehicleDocuments')
);
const AddVehicleDocument = dynamic(() =>
  import('./AddVehicleDocument/AddVehicleDocument')
);
const CancelVehicleModal = dynamic(() =>
  import('./CancelVehicleModal/CancelVehicleModal')
);
const DefleetVehicleModal = dynamic(() =>
  import('./DefleetVehicleModal/DefleetVehicleModal')
);
const FleetVehiclesFilters = dynamic(() =>
  import('./FleetVehiclesFilters/FleetVehiclesFilters')
);
const VehiclePage = dynamic(() => import('./VehiclePage/VehiclePage'));
const AddVehicleForm = dynamic(() => import('./AddVehicleForm/AddVehicleForm'));
const FleetVehiceOverview = dynamic(() =>
  import('./FleetVehiceOverview/FleetVehiceOverview')
);
const FleetVehicleHeader = dynamic(() =>
  import('./FleetVehicleHeader/FleetVehicleHeader')
);
const FleetVehicleChangelog = dynamic(() =>
  import('./FleetVehicleChangelog/FleetVehicleChangelog')
);
const VehicleInvoiceInfo = dynamic(() =>
  import('./VehicleInvoiceInfo/VehicleInvoiceInfo')
);

export {
  FleetVehiclesGrid,
  FleetVehicleMenu,
  VehicleProcurement,
  VehicleServices,
  AddVehicleService,
  VehicleCosts,
  AddVehicleCost,
  VehicleInsurances,
  AddVehicleInsurance,
  AddVehiclePlate,
  AddVehicleMileage,
  ConfigureServicePointModal,
  VehicleServicePoints,
  VehicleDocuments,
  AddVehicleDocument,
  CancelVehicleModal,
  DefleetVehicleModal,
  FleetVehiclesFilters,
  VehiclePage,
  AddVehicleForm,
  FleetVehiceOverview,
  FleetVehicleHeader,
  FleetVehicleChangelog,
  VehicleInvoiceInfo,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
