import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { FormModal, Link } from '@components';
import { showError } from '@utils';
import { agentsServices } from '@services';

import { editAgentModalForm } from './editAgentModalForm';

const EditAgentModal = ({ getAgents, agent }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);

  const _toggleModal = () => {
    setOpen(!open);
  };

  const _initializeForm = useCallback(() => {
    setTimeout(() => {
      modalRef.current._setValue('firstName', agent?.firstName);
      modalRef.current._setValue('lastName', agent?.lastName);
      modalRef.current._setValue('email', agent?.email);
      modalRef.current._setValue('role', agent?.role);
    }, 1);
  }, [agent]);

  const _onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        role: data?.role,
      };

      await agentsServices.editAgent(agent?.id, payload);
      await getAgents();
      _toggleModal();
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      _initializeForm();
    }
  }, [open, _initializeForm]);

  return (
    <>
      <Box color="primary" onClick={_toggleModal}>
        <Link text={agent?.id} />
      </Box>
      <FormModal
        ref={modalRef}
        id="add-agent-modal"
        title="Edit Agent"
        open={open}
        formScheme={editAgentModalForm}
        loading={loading}
        handleClose={_toggleModal}
        onSubmit={_onSubmit}
        withCancel
        size="sm"
        submitText="Add"
      />
    </>
  );
};

export default EditAgentModal;
