import { omit } from 'lodash';
import { formatDate } from '@utils';

const getAssignee = (assignees, assignee) => {
  const selected = assignees.find((item) => item.email === assignee);

  if (!selected) {
    return '';
  }

  return {
    value: selected.email,
    text:
      selected.firstName && selected.lastName
        ? `${selected?.firstName} ${selected?.lastName}`
        : selected.email,
  };
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
  };
  return vehicle;
};

export const handleTrafficOffensesTaskData = (data = {}, assignees = []) => {
  if (!data) {
    return {};
  }

  const assignee = getAssignee(assignees, data.assignee);

  const task = {
    ...data,
    assignee,
    customFields: {
      ...omit(data.customFields, ['comments']),
      reason: data.customFields?.reason || '-',
      totalChargeClientAmount:
        data.customFields.totalChargeClientAmount?.number || null,
      totalInvoiceCost: data.customFields.totalInvoiceCost?.number || null,
      violationFee: data.customFields.violationFee?.number || null,
    },
    changelog: data?.changelog?.reverse(),
    comments: data?.comments?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents?.filter(
      (item) => !item?.tags?.includes('chargeEmailPhotosAndDocs')
    ),
    chargeEmailPhotosAndDocs: data?.documents?.filter((item) =>
      item?.tags?.includes('chargeEmailPhotosAndDocs')
    ),
  };
  return task;
};

export const handleAssigneeData = (data = []) =>
  data.map((item) => ({
    value: item.email,
    text:
      item.firstName && item.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : item.email,
  }));
