import { request } from '@utils';
import { LIMIT } from '@constants';

const getServiceVehicles = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/serviceVehicles`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServiceVehiclesFilters = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }

  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}&order=${payload?.order}&orderBy=${payload?.orderBy}`;
  delete payload?.pageSize;
  delete payload?.page;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/serviceVehicles/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServiceVehiclesEnums = async () => {
  const results = await request.get(`/v1/serviceVehicles/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServiceVehicle = async (id) => {
  const results = await request.get(`/v1/serviceVehicles/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServiceVehiclesTypes = async () => {
  const results = await request.get(`/v1/serviceVehicles/config/types`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const patchServiceVehicle = async (id, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/serviceVehicles/${id}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postServiceVehiclesAssignee = async (id, payload) => {
  const param = {
    body: payload,
  };
  const results = await request.post(
    `/v1/serviceVehicles/${id}/actions/assign`,
    param
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postServiceVehiclesSelectedSlot = async (id, payload) => {
  const param = {
    body: payload,
  };
  const results = await request.post(
    `/v1/serviceVehicles/${id}/actions/selectSlot`,
    param
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postServiceVehiclesStatus = async (id, payload) => {
  const param = {
    body: payload,
  };
  const results = await request.post(
    `/v1/serviceVehicles/${id}/actions/status`,
    param
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postServiceVehiclesContactEmail = async (id) => {
  const param = {
    body: {},
  };
  const results = await request.post(
    `/v1/serviceVehicles/${id}/appointment-email`,
    param
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getServiceVehicles,
  getServiceVehicle,
  getServiceVehiclesTypes,
  postServiceVehiclesAssignee,
  postServiceVehiclesSelectedSlot,
  postServiceVehiclesStatus,
  getServiceVehiclesEnums,
  patchServiceVehicle,
  getServiceVehiclesFilters,
  postServiceVehiclesContactEmail,
};
