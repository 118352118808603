import dynamic from 'next/dynamic';

const ServicePointsGrid = dynamic(() =>
  import('./ServicePointsGrid/ServicePointsGrid')
);
const AddServicePointModal = dynamic(() =>
  import('./AddServicePointModal/AddServicePointModal')
);
const ServicePointDetails = dynamic(() =>
  import('./ServicePointDetails/ServicePointDetails')
);
const ServicePointMenu = dynamic(() =>
  import('./ServicePointMenu/ServicePointMenu')
);
const AddServicePointAddressModal = dynamic(() =>
  import('./AddServicePointAddressModal/AddServicePointAddressModal')
);
const AddressesGrid = dynamic(() => import('./AddressesGrid/AddressesGrid'));
const EventsGrid = dynamic(() => import('./EventsGrid/EventsGrid'));
const EditServicePointFieldsModal = dynamic(() =>
  import('./EditServicePointFieldsModal/EditServicePointFieldsModal')
);
const SendServicePointStatusModal = dynamic(() =>
  import('./SendServicePointStatusModal/SendServicePointStatusModal')
);
const AddServicePointWorkdaysModal = dynamic(() =>
  import('./AddServicePointWorkdaysModal/AddServicePointWorkdaysModal')
);
const AddServicePointContactModal = dynamic(() =>
  import('./AddServicePointContactModal/AddServicePointContactModal')
);
const EditServicePointContactModal = dynamic(() =>
  import('./EditServicePointContactModal/EditServicePointContactModal')
);
const ContactsGrid = dynamic(() => import('./ContactsGrid/ContactsGrid'));

const PartnershipDetails = dynamic(() =>
  import('./PartnershipDetails/PartnershipDetails')
);
const ServicePointsFilter = dynamic(() =>
  import('./ServicePointsFilter/ServicePointsFilter')
);

export {
  ServicePointsGrid,
  AddServicePointModal,
  ServicePointDetails,
  ServicePointMenu,
  AddServicePointAddressModal,
  AddressesGrid,
  EventsGrid,
  EditServicePointFieldsModal,
  SendServicePointStatusModal,
  AddServicePointWorkdaysModal,
  AddServicePointContactModal,
  EditServicePointContactModal,
  ContactsGrid,
  PartnershipDetails,
  ServicePointsFilter,
};
