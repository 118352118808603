import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getArnmAccidentsTask,
  selectArnmAccidentsState,
  getArnmAccidentsAssignees,
} from '@store';
import { showError } from '@utils';

export const useArnmAccidentsTaskOperations = () => {
  const {
    arnmAccidentsTask,
    loading,
    hasErrors,
    errorMessage,
    vehicle,
    assignees,
  } = useSelector(selectArnmAccidentsState);
  const {
    query: { arnmAccidentsTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getArnmAccidentsTask = useCallback(async () => {
    await dispatch(getArnmAccidentsTask(arnmAccidentsTaskId));
  }, [arnmAccidentsTaskId, dispatch]);

  useEffect(() => {
    if (arnmAccidentsTaskId !== undefined) {
      _getArnmAccidentsTask();
    }
  }, [arnmAccidentsTaskId, _getArnmAccidentsTask, token]);

  useEffect(() => {
    dispatch(getArnmAccidentsAssignees({ page: 1, pageSize: 1000 }));
    sessionStorage.lastRoute = window.location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    task: arnmAccidentsTask,
    taskId: arnmAccidentsTaskId,
    hasErrors,
    errorMessage,
    getArnmAccidentsTask: _getArnmAccidentsTask,
    token,
    vehicle,
    assignees,
  };
};
