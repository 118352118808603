/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { Box, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Modal from '@mui/material/Modal';
import { css } from 'aphrodite';
import { useForm } from 'react-hook-form';

import { Button } from '@components';

import styles from './editARNMDocumentModal.style';

export const EditARNMDocumentModal = ({
  isOpen,
  document,
  onClose,
  onSubmit,
}) => {
  const { register, handleSubmit, formState, reset } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      customer: document?.tags?.includes('customer'),
      servicePoint: document?.tags?.includes('service-point'),
      insurance: document?.tags?.includes('insurance'),
    },
  });

  useEffect(() => {
    if (document) {
      reset({
        customer: document?.tags?.includes('customer'),
        servicePoint: document?.tags?.includes('service-point'),
        insurance: document?.tags?.includes('insurance'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      disableAutoFocus
    >
      <Box className={css(styles.modal)}>
        <DialogTitle id="alert-dialog-title">Attach to</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={css(styles.formWrapper)}
          >
            <Box className={css(styles.fieldWrapper)}>
              <label htmlFor="customer" className={css(styles.label)}>
                Customer
              </label>
              <input
                className={css(styles.field, styles.fullWidth)}
                type="checkbox"
                name="customer"
                {...register('customer')}
              />
            </Box>
            <Box className={css(styles.fieldWrapper)}>
              <label htmlFor="servicePoint" className={css(styles.label)}>
                Service Point
              </label>
              <input
                className={css(styles.field, styles.fullWidth)}
                type="checkbox"
                name="servicePoint"
                {...register('servicePoint')}
              />
            </Box>
            <Box className={css(styles.fieldWrapper)}>
              <label htmlFor="insurance" className={css(styles.label)}>
                Insurance
              </label>
              <input
                className={css(styles.field, styles.fullWidth)}
                type="checkbox"
                name="insurance"
                {...register('insurance')}
              />
            </Box>

            <DialogActions>
              <Button color="cancel" onClick={onClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                loading={formState.isSubmitting}
              >
                OK
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Box>
    </Modal>
  );
};
export default EditARNMDocumentModal;
