import { request } from '@utils';

const postImage = async ({
  file,
  entityType,
  entityId,
  tags = [],
  publicAccess = false,
}) => {
  const fd = new FormData();
  fd.append('images', file);
  fd.append('tags', tags);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/media/images?entityType=${entityType}&entityID=${entityId}&publicAccess=${publicAccess}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postDoc = async ({
  file,
  entityType,
  entityId,
  tags = [],
  publicAccess = false,
  documentType,
}) => {
  const fd = new FormData();
  fd.append('documents', file);
  fd.append('tags', tags);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/media/docs?entityType=${entityType}&entityID=${entityId}&publicAccess=${publicAccess}&documentType=${documentType}`,
    body
  );
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const deleteImage = async (imageId) => {
  const results = await request.remove(`/v1/media/images/${imageId}`);
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const deleteDoc = async (docId) => {
  const results = await request.remove(`/v1/media/docs/${docId}`);
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

export { postImage, postDoc, deleteImage, deleteDoc };
