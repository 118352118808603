const formatVehicleStage = (stageId) => {
  const field = {
    0: 'CREATED',
    1: 'ORDERED',
    2: 'IN CUSTOMS',
    3: 'PAYMENT PROCESS',
    4: 'DEALER PREPARATION',
    5: 'FLEET',
    6: 'DEFLEETED',
    7: 'CANCELLED',
  };
  return field[stageId] || '-';
};

export default formatVehicleStage;
