const authHeader = (headers) => {
  const localToken = localStorage.token;

  if (!headers) {
    headers = { 'Content-Type': 'application/json' };
  }
  if (localToken) {
    return {
      Authorization: localToken,
      ...headers,
    };
  }
  return { 'Content-Type': 'application/json' };
};

export default authHeader;
