import dynamic from 'next/dynamic';

const SellVehiclesGrid = dynamic(() =>
  import('./SellVehiclesGrid/SellVehiclesGrid')
);
const SellVehiclesFilters = dynamic(() =>
  import('./SellVehiclesFilters/SellVehiclesFilters')
);
const SellVehiclePageContent = dynamic(() =>
  import('./SellVehiclePageContent/SellVehiclePageContent')
);
const SellVehiclesForm = dynamic(() =>
  import('./SellVehiclesForm/SellVehiclesForm')
);
const SellVehiclePropertiesSection = dynamic(() =>
  import('./SellVehiclePropertiesSection/SellVehiclePropertiesSection')
);
const SellVehicleHeader = dynamic(() =>
  import('./SellVehicleHeader/SellVehicleHeader')
);
const SellVehiclePricingSection = dynamic(() =>
  import('./SellVehiclePricingSection/SellVehiclePricingSection')
);
const SellVehicleOverview = dynamic(() =>
  import('./SellVehicleOverview/SellVehicleOverview')
);
const AddSellVehicleHistory = dynamic(() =>
  import('./AddSellVehicleHistory/AddSellVehicleHistory')
);
const SellHistory = dynamic(() => import('./SellHistory/SellHistory'));

export {
  SellVehiclesGrid,
  SellVehiclesFilters,
  SellVehiclePageContent,
  SellVehiclesForm,
  SellVehiclePropertiesSection,
  SellVehicleHeader,
  SellVehiclePricingSection,
  SellVehicleOverview,
  AddSellVehicleHistory,
  SellHistory,
};
