import { request } from '@utils';
import { LIMIT } from '@constants';

const getTeams = async (params, payload) => {
  if (!params?.pageSize) {
    params.pageSize = LIMIT;
  }
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/teams/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postTeam = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/teams`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editTeamById = async (teamId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/teams/${teamId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getTeam = async (teamId) => {
  const results = await request.get(`/v1/teams/${teamId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getTeams, postTeam, editTeamById, getTeam };
