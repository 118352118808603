import dynamic from 'next/dynamic';

const ArnmMaintenanceGrid = dynamic(() =>
  import('./ArnmMaintenanceGrid/ArnmMaintenanceGrid')
);
const AddArnmMaintenanceModal = dynamic(() =>
  import('./AddArnmMaintenanceModal/AddArnmMaintenanceModal')
);
const UploadArnmMaintenanceDocument = dynamic(() =>
  import('./UploadArnmMaintenanceDocument/UploadArnmMaintenanceDocument')
);
const ArnmMaintenanceVehicleHeader = dynamic(() =>
  import('./ArnmMaintenanceVehicleHeader/ArnmMaintenanceVehicleHeader')
);
const ChangeArnmMaintenanceStage = dynamic(() =>
  import('./ChangeArnmMaintenanceStage/ChangeArnmMaintenanceStage')
);
const ArnmMaintenanceFilter = dynamic(() =>
  import('./ArnmMaintenanceFilter/ArnmMaintenanceFilter')
);
const MaintenanceForm = dynamic(() =>
  import('./MaintenanceForm/MaintenanceForm')
);

export {
  ArnmMaintenanceGrid,
  AddArnmMaintenanceModal,
  UploadArnmMaintenanceDocument,
  ArnmMaintenanceVehicleHeader,
  ChangeArnmMaintenanceStage,
  ArnmMaintenanceFilter,
  MaintenanceForm,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
