const findNameColor = (colors, color) => {
  let name = '';
  for (let i = 0; colors?.length >= i; i++) {
    if (
      colors[i]?.slug.toLowerCase() === color.toLowerCase() ||
      colors[i]?.name.toLowerCase() === color.toLowerCase()
    ) {
      name = colors[i]?.name;

      break;
    }
  }
  return name;
};

export default findNameColor;
