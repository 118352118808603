/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pickBy, identity } from 'lodash';
import { useRouter } from 'next/router';

import {
  selectAvailabilityState,
  getAvailabilities,
  getConfigData,
} from '@store';
import { showError } from '@utils';

const getPayload = (params) => pickBy(params, identity);

export const useAvailabilitiesOperations = (fetchOnMount) => {
  const {
    loading,
    availabilities,
    timeRange,
    hasErrors,
    errorMessage,
    configData,
    pageCount,
  } = useSelector(selectAvailabilityState);

  const dispatch = useDispatch();

  const { push, query, isReady } = useRouter();
  const {
    page = 1,
    fuelType,
    model,
    brand,
    plate,
    internalId,
    dateFrom,
    dateTo,
  } = query;
  const _getAvailabilities = useCallback(
    async (payload = {}) => {
      await dispatch(getAvailabilities(getPayload(payload)));
    },
    [page, fuelType, model, brand, plate, internalId, dispatch]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      const url = `/availability?${new URLSearchParams({
        ...query,
        page: currentPage,
      }).toString()}`;
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [push]
  );

  const _getConfigData = useCallback(async () => {
    await dispatch(getConfigData());
  }, [dispatch]);

  const _initialize = async () => {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    const currentMonth = new Date(y, m, d);
    const nextMonths = new Date(y, m + 3, 0);

    await _getConfigData();
    await _getAvailabilities(
      getPayload({
        dateFrom: dateFrom || currentMonth.getTime() / 1000,
        dateTo: dateTo || nextMonths.getTime() / 1000,
        page,
        fuelType,
        model,
        brand,
        plate,
        internalId,
      })
    );
  };

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _initialize();
    }
  }, [_getConfigData, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    availabilities,
    timeRange,
    hasErrors,
    errorMessage,
    getAvailabilities: _getAvailabilities,
    getConfigData: _getConfigData,
    configData,
    nextPage: _nextPage,
    page,
    pageCount,
  };
};
