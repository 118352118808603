import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ordersServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import {
  handleOrders,
  handleOrder,
  handleTransactions,
} from './orders.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  orders: [],
  order: {},
  transactions: {},
  total: 0,
  pageCount: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageSize: LIMIT,
};
// Actual Slice
export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    fetchOrders: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchOrdersSuccess: (state, { payload }) => {
      state.orders = handleOrders(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.orders.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.orders?.length || 0);

      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchOrdersFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchOrder: (state) => {
      state.loading = true;
      state.getOrderhasErrors = false;
      state.getOrderErrorMessage = '';
    },
    fetchOrderSuccess: (state, { payload }) => {
      state.order = handleOrder(payload?.order?.data);
      state.transactions = handleTransactions(payload?.transactions?.data);
      state.loading = false;
    },
    fetchOrderFailure: (state, { payload }) => {
      state.loading = false;
      state.getOrderhasErrors = true;
      state.getOrderErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchOrders,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  fetchOrder,
  fetchOrderSuccess,
  fetchOrderFailure,
} = ordersSlice.actions;

export const selectOrdersState = (state) => state.orders;

export const getOrders = (payload) => {
  return async (dispatch) => {
    dispatch(fetchOrders());
    const page = parseInt(payload?.page, 10);
    try {
      const response = await ordersServices?.getOrdersFilters(payload);
      response.meta.page = page;
      dispatch(fetchOrdersSuccess(response));
    } catch (error) {
      dispatch(fetchOrdersFailure(error));
    }
  };
};

export const getOrder = (orderId) => {
  return async (dispatch) => {
    dispatch(fetchOrder());

    try {
      const order = await ordersServices?.getOrdeById(orderId);
      const transactions = await ordersServices?.getTransactionsByOrderId(
        orderId
      );

      dispatch(fetchOrderSuccess({ order, transactions }));
    } catch (error) {
      dispatch(fetchOrderFailure(error));
    }
  };
};

export default ordersSlice.reducer;
