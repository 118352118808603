import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectInsurancesState, getInsurance } from '@store';
import { showError } from '@utils';

export const useInsuranceOperations = () => {
  const { total, loading, insurance, hasErrors, errorMessage } = useSelector(
    selectInsurancesState
  );
  const {
    query: { insuranceId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getInsurance = useCallback(async () => {
    await dispatch(getInsurance(insuranceId));
  }, [insuranceId, dispatch]);

  useEffect(() => {
    if (insuranceId !== undefined) {
      _getInsurance();
    }
  }, [insuranceId, _getInsurance, token]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    insurance,
    insuranceId,
    hasErrors,
    errorMessage,
    getInsurance: _getInsurance,
    token,
  };
};
