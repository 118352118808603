import { max as lodashMax, omit } from 'lodash';
import moment from 'moment';
import {
  ARNM_DETAILS,
  ARNM_WORK_TYPES_ENUMS,
  MAP_ARNM_WORK_TYPES_WITH_DETAILS,
} from '@constants';

export const getRepairDuration = (detailKey, detail) =>
  ARNM_DETAILS[detailKey]?.find((item) => item.value === detail)
    ?.repairDuration;

export const updateExpectedOutFromServicePointValue = (
  loading,
  formRef,
  lastFormState
) => {
  if (!formRef?.current?.values || loading) {
    return;
  }

  const workTypes = Object.keys(formRef.current?.values).filter((stateKey) =>
    stateKey.includes('workType_')
  );

  if (!workTypes.length) {
    return;
  }

  const details = Object.keys(formRef.current?.values).filter((stateKey) =>
    stateKey.includes('details_')
  );
  if (!details.length) {
    return;
  }

  const durations = workTypes.reduce((acc, cur) => {
    const workTypeValue = formRef.current.values[cur];

    const selectedWorkTypeKey = Object.keys(ARNM_WORK_TYPES_ENUMS).find(
      (_key) => ARNM_WORK_TYPES_ENUMS[_key] === workTypeValue
    );

    const selectedDetail =
      MAP_ARNM_WORK_TYPES_WITH_DETAILS[selectedWorkTypeKey];

    const index = cur.split('_')?.[1];

    let duration = getRepairDuration(
      selectedDetail,
      formRef.current.values[`details_${index}`]
    );
    // Απαλλαγή & instadelivery have no details
    if (
      Number(selectedWorkTypeKey) === 7 ||
      Number(selectedWorkTypeKey) === 23
    ) {
      duration = 0;
    }
    if (duration >= 0) {
      acc.push(duration);
    }
    return acc;
  }, []);
  const maxDuration = lodashMax(durations);

  const date = moment().add(maxDuration, 'days');

  const shouldUpdate =
    durations.length &&
    maxDuration >= 0 &&
    moment(lastFormState.expectedOutFromServicePoint).format('DD-MM-YYYY') !==
      moment(date).format('DD-MM-YYYY');

  if (shouldUpdate) {
    formRef.current._setValue('expectedOutFromServicePoint', date);
  }

  if (!durations.length) {
    formRef.current._setValue('expectedOutFromServicePoint', '');
  }
};

export const servicePointEmail = (state) => {
  if (
    typeof state.servicePoint === 'object' &&
    state.servicePoint !== null &&
    'email' in state.servicePoint
  ) {
    return state?.servicePoint?.email;
  }
  return state?.servicePointEmail;
};

export const computedPayload = (values) => {
  const clearableFields = ['dot', 'tires'];

  Object.keys(values)?.forEach((key) => {
    if (values[key] === '' && !clearableFields.includes(key)) {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'documents',
    'tags',
    'status',
    'updatedAt',
    'updatedBy',
    'changelog',
    'servicePoint',
    'images',
    'parent',
    'name',
    'createdAt',
    'comments',
    'createdBy',
    'servicePoint',
    'subscriptionId',
    'customFields.vehicleDescription',
    'customFields.vehicleImage',
    'customFields.vehicleOwner',
    'customFields.vehiclePlate',
  ]);

  return {
    assignee: values.assignee?.value,
    customFields: {
      ...cleanedValues.customFields,
      servicePoint: values.customFields.servicePoint?.value,
      expectedOutFromServicePoint:
        values.customFields.expectedOutFromServicePoint || null,
    },
  };
};
