import { request } from '@utils';
import { LIMIT } from '@constants';

const getBookingById = async (bookingId) => {
  const results = await request.get(`/v1/bookings/${bookingId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getBookingByOffer = async (bookingId) => {
  const params = {
    responseType: 'arraybuffer',
  };
  const results = await request.get(`/v1/bookings/${bookingId}/offer`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }
  const blob = new Blob([results], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${bookingId}.pdf`;
  link.click();
  return results;
};

const getBookings = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/bookings`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getBookingsEnums = async () => {
  const results = await request.get(`/v1/bookings/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addBooking = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/bookings`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getWWWVehicles = async () => {
  const results = await request.get(`/v1/www/vehicles`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getDownpayment = async (skuId, pricingId, downPayment) => {
  const results = await request.get(
    `/v1/instafleet/catalog/vehicles/downpayment?skuId=${skuId}&pricingId=${pricingId}&downpayment=${downPayment}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getBookings,
  addBooking,
  getWWWVehicles,
  getBookingById,
  getBookingByOffer,
  getBookingsEnums,
  getDownpayment,
};
