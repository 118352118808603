import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { vehiclesServices, fleetVehiclesServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { asyncForEach } from '@utils';
import {
  handleVehicles,
  handleVehicleData,
  handleVehiclesBySku,
} from './vehicle.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  vehicles: [],
  vehicle: {
    vehiclesBySku: {
      loading: false,
      data: [],
      errorMessage: '',
      total: 0,
      page: 1,
      pageCount: 0,
      firstItem: 1,
      lastItem: LIMIT,
      pageSize: LIMIT,
    },
  },
  total: 0,
  pageCount: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    fetchVehicles: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchVehiclesSuccess: (state, { payload }) => {
      state.vehicles = handleVehicles(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.vehicles.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.vehicles?.length || 0);

      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchVehiclesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchVehicle: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.vehicle = initialState.vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postSuccess = false;
      state.postErrorMessage = '';
    },
    fetchVehicleSuccess: (state, { payload }) => {
      state.vehicle = handleVehicleData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchVehicleBySku: (state) => {
      if (!state.vehicle.vehiclesBySku) {
        state.vehicle.vehiclesBySku = { ...initialState.vehicle.vehiclesBySku };
      }
      state.vehicle.vehiclesBySku.errorMessage = '';
      state.vehicle.vehiclesBySku.loading = true;
    },
    fetchVehicleBySkuSuccess: (state, { payload }) => {
      state.vehicle.vehiclesBySku.data = handleVehiclesBySku(payload.data);
      state.vehicle.vehiclesBySku.errorMessage = '';
      state.vehicle.vehiclesBySku.loading = false;
      state.vehicle.vehiclesBySku.total = payload?.meta?.total || 0;
      state.vehicle.vehiclesBySku.page = payload?.meta?.page;

      state.vehicle.vehiclesBySku.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) *
              state.vehicle.vehiclesBySku.pageSize +
            1;
      state.vehicle.vehiclesBySku.lastItem =
        payload?.meta?.page === 1
          ? state.vehicle.vehiclesBySku.data.length
          : ((payload?.meta?.page || 1) - 1) *
              state.vehicle.vehiclesBySku.pageSize +
            (state.vehicle.vehiclesBySku.data.length || 0);

      state.vehicle.vehiclesBySku.pageCount =
        payload?.meta?.total < state.vehicle.vehiclesBySku.pageSize
          ? 1
          : Math.ceil(
              (payload?.meta?.total || 1) / state.vehicle.vehiclesBySku.pageSize
            );
    },
    fetchVehicleBySkuFailure: (state, { payload }) => {
      state.vehicle.vehiclesBySku.errorMessage =
        payload || GENERIC_ERROR_MESSAGE;
      state.vehicle.vehiclesBySku.loading = false;
    },
    fetchVehicleFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    resetVehicle: (state) => {
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
      const vehicle = { ...state.vehicle };
      if (vehicle?.vehicle) {
        vehicle.vehicle = initialState.vehicle;
      }
      state.vehicle = vehicle;
    },
    postVehicle: (state) => {
      state.postLoading = true;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
    },
    postVehicleSuccess: (state) => {
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = true;
    },
    postVehicleFailure: (state, { payload }) => {
      state.postLoading = false;
      state.postHasErrors = true;
      state.postErrorMessage = payload;
    },
    deleteVehicleImageSuccess: (state, { payload }) => {
      const vehicle = { ...state?.vehicle };

      if (vehicle?.vehicle?.images?.length) {
        const images = vehicle?.vehicle?.images?.filter((image) => {
          return image?.id !== payload;
        });
        vehicle.vehicle.images = images;
      }
      state.vehicle = vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
    },

    deleteVehicleFileSuccess: (state, { payload }) => {
      const vehicle = { ...state?.vehicle };

      if (vehicle?.vehicle?.docs?.length) {
        const docs = vehicle?.vehicle?.docs?.filter((doc) => {
          return doc?.id !== payload;
        });
        vehicle.vehicle.docs = docs;
      }
      state.vehicle = vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchVehicles,
  fetchVehiclesSuccess,
  fetchVehiclesFailure,
  fetchVehicle,
  fetchVehicleSuccess,
  fetchVehicleFailure,
  fetchVehicleBySkuSuccess,
  fetchVehicleBySku,
  fetchVehicleBySkuFailure,
  postVehicle,
  postVehicleSuccess,
  postVehicleFailure,
  resetVehicle,
  deleteVehicleImageSuccess,
  deleteVehicleFileSuccess,
} = vehiclesSlice.actions;

export const selectVehiclesState = (state) => state.vehicles;

export const getVehicles = (payload) => {
  return async (dispatch) => {
    dispatch(fetchVehicles());
    const page = parseInt(payload?.page, 10);
    try {
      const response = await vehiclesServices?.getVehiclesFilters(payload);
      response.meta.page = page;
      dispatch(fetchVehiclesSuccess(response));
    } catch (error) {
      dispatch(fetchVehiclesFailure(error));
    }
  };
};

export const getVehiclesBySku = (
  vehicleId,
  params = { page: 1, pageSize: LIMIT }
) => {
  return async (dispatch) => {
    dispatch(fetchVehicleBySku());
    const page = parseInt(params?.page, 10);

    try {
      if (vehicleId) {
        const response = await vehiclesServices?.getVehiclesBySku(
          vehicleId,
          params
        );
        response.meta.page = page;
        dispatch(fetchVehicleBySkuSuccess(response));
      }
    } catch (error) {
      dispatch(fetchVehicleBySkuFailure(error));
    }
  };
};

export const getVehicle = (vehicleId) => {
  return async (dispatch) => {
    dispatch(fetchVehicle());
    try {
      const data = {};
      if (vehicleId) {
        data.vehicle = await vehiclesServices?.getVehicle(vehicleId);
      } else {
        data.vehicle = initialState.vehicle;
      }

      data.enums = await vehiclesServices?.getVehicleEnums();
      data.colors = await fleetVehiclesServices?.getVehicleColors();
      data.providers = await fleetVehiclesServices?.getVehicleProviders();
      data.models = await fleetVehiclesServices?.getVehiclesModels();
      dispatch(fetchVehicleSuccess(data));
    } catch (error) {
      dispatch(fetchVehicleFailure(error));
    }
  };
};

export const addVehicle = (payload) => {
  return async (dispatch) => {
    dispatch(postVehicle());

    try {
      const { skuId } = payload.form;

      await vehiclesServices.postVehicle(payload.form);

      if (payload.pricing?.length) {
        await asyncForEach(payload?.pricing, async (prices) => {
          if (!prices?.insert) {
            await vehiclesServices.updatePricing(skuId, prices?.id, prices);
          } else {
            await vehiclesServices.addPricing(skuId, [prices]);
          }
        });
      }
      if (payload.docs?.length) {
        await asyncForEach(payload?.docs, async (doc) => {
          vehiclesServices.postVehicleFile(skuId, doc.file);
        });
      }

      dispatch(postVehicleSuccess());
    } catch (error) {
      dispatch(postVehicleFailure(error));
    }
  };
};

export const editVehicle = (payload) => {
  return async (dispatch) => {
    dispatch(postVehicle());

    try {
      const { skuId } = payload.form;
      const { primaryImageId } = payload;
      const data = {
        ...payload.form,
        images: [...payload.images],
        documents: [...payload.docs],
      };
      await vehiclesServices.updateVehicle(skuId, data);
      if (primaryImageId) {
        await vehiclesServices.postVehiclePrimaryImage(skuId, primaryImageId);
      }
      if (payload.pricing?.length) {
        await asyncForEach(payload?.pricing, async (prices) => {
          if (!prices?.insert) {
            await vehiclesServices.updatePricing(skuId, prices?.id, prices);
          } else {
            await vehiclesServices.addPricing(skuId, [prices]);
          }
        });
      }
      dispatch(postVehicleSuccess());
    } catch (error) {
      dispatch(postVehicleFailure(error));
    }
  };
};

export const deleteVehicleImage = (payload) => {
  return async (dispatch) => {
    dispatch(postVehicle());

    try {
      await vehiclesServices.deleteVehicleImage(payload);

      dispatch(deleteVehicleImageSuccess(payload?.id));
    } catch (error) {
      dispatch(postVehicleFailure(error));
    }
  };
};

export const deleteVehicleFile = (payload) => {
  return async (dispatch) => {
    dispatch(postVehicle());

    try {
      await vehiclesServices.deleteVehicleFile(payload);

      dispatch(deleteVehicleFileSuccess(payload?.id));
    } catch (error) {
      dispatch(postVehicleFailure(error));
    }
  };
};

export default vehiclesSlice.reducer;
