import dynamic from 'next/dynamic';

const AvailabilityTableGrid = dynamic(() =>
  import('./AvailabilityTableGrid/AvailabilityTableGrid')
);
const AvailabilityTableFilters = dynamic(() =>
  import('./AvailabilityTableFilters/AvailabilityTableFilters')
);

export { AvailabilityTableGrid, AvailabilityTableFilters };
