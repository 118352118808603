export const monthDiff = (firstMonth, lastMonth) => {
  let months;
  months = (lastMonth.getFullYear() - firstMonth.getFullYear()) * 12;
  months -= firstMonth.getMonth();
  months += lastMonth.getMonth();
  return months <= 0 ? 0 : months;
};

export const dayDiff = (startDate, endDate) => {
  return endDate.diff(startDate, 'days');
};

export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const getDayOfWeek = (year, month, day) => {
  const daysOfTheWeekArr = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const dayOfTheWeekIndex = new Date(year, month, day).getDay();
  return daysOfTheWeekArr[dayOfTheWeekIndex];
};

export const createFormattedDateFromStr = (year, month, day) => {
  let monthStr = month.toString();
  let dayStr = day.toString();

  if (monthStr.length === 1) {
    monthStr = `0${monthStr}`;
  }
  if (dayStr.length === 1) {
    dayStr = `0${dayStr}`;
  }
  return `${year}-${monthStr}-${dayStr}`;
};

export const createFormattedDateFromDate = (date) => {
  let monthStr = (date.getMonth() + 1).toString();
  let dayStr = date.getDate().toString();

  if (monthStr.length === 1) {
    monthStr = `0${monthStr}`;
  }
  if (dayStr.length === 1) {
    dayStr = `0${dayStr}`;
  }
  return `${date.getFullYear()}-${monthStr}-${dayStr}`;
};

export const computeDays = (numDays, currMonth, currYear, timeRange) => {
  const days = [];
  const fromSelectedDate = createFormattedDateFromStr(
    timeRange.fromSelectYear,
    timeRange.fromSelectMonth + 1,
    timeRange.fromSelectDay
  );
  const toSelectedDate = createFormattedDateFromStr(
    timeRange.toSelectYear,
    timeRange.toSelectMonth + 1,
    timeRange.toSelectDay
  );
  for (let j = 1; j <= numDays; j++) {
    const currentDate = createFormattedDateFromStr(currYear, currMonth, j);
    if (
      new Date(currentDate).getTime() >= new Date(fromSelectedDate).getTime() &&
      new Date(currentDate).getTime() <= new Date(toSelectedDate).getTime()
    ) {
      days.push(j);
    }
  }
  return days;
};
