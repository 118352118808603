import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  tenant: 0,
};
// Actual Slice
export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setTenant: (state, { payload }) => {
      localStorage.tenant = `${payload}`;
      state.tenant = payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { setTenant } = layoutSlice.actions;

export const selectLayoutState = (state) => state.layout;

export default layoutSlice.reducer;
