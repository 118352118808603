import theme from 'config/theme';

export const TRAFFIC_OFFENSES_TASK_STAGES = {
  0: {
    label: 'Backlog',
    styles: {
      backgroundColor: theme.palette.grays.gray200,
      color: theme.palette.grays.gray900,
    },
  },
  2: {
    label: 'In Progress',
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  15: {
    label: 'Billing',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
    },
  },
  3: {
    label: 'Done',
    styles: {
      backgroundColor: theme.palette.greens.green200,
      color: theme.palette.greens.green900,
    },
  },
  12: {
    label: 'Cancelled',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
};

export const TRAFFIC_OFFENSES_TASK_STAGES_MAP = {
  backlog: { text: 'Backlog', value: 0 },
  inProgress: { text: 'In Progress', value: 2 },
  billing: { text: 'Billing', value: 15 },
  done: { text: 'Done', value: 3 },
  cancelled: { text: 'Cancelled', value: 12 },
};

export const TRAFFIC_OFFENSES_STATUSES = {
  SC01: 'Settled by client',
  TR02: 'Transferred',
  EP03: 'Element Promotion',
  OB04: 'Objection',
  CC05: 'Customer Charges',
};

export const TRAFFIC_OFFENSES_REASONS = {
  SP01: 'Speeding',
  SB02: 'Seatbelt',
  PD03: 'Parking on Disability Bay',
  IP04: 'Illegal parking',
  DD05: 'Distracted Driving',
  VL06: 'Vehicle License missing',
  DUI07: 'DUI',
  OT08: 'Other',
};

export const TRAFFIC_OFFENSES_ISSUEDBY = {
  MP01: 'Municipal Police',
  TP02: 'Traffic Police',
  TO03: 'Tourist Police',
  DR04: 'Department of Revenue / County',
  HC05: 'Hellenic Coast Guard',
};

export const TRAFFIC_OFFENSES_EMAIL_TEMPLATES = {
  ET01: 'Plate Retrieval',
  ET02: 'Vehicle Recovery',
  ET03: 'Traffic Violation Ticket Transfer',
  ET04: 'Disclosure of Information',
  ET05: 'Traffic Violation Fee',
};

export const TRAFFIC_OFFENSES_DOCUMENT_TYPES = [
  {
    text: 'Κλήση',
    value: 48,
  },
  {
    text: 'Αποδεικτικό Πληρωμής',
    value: 18,
  },
  {
    text: 'Ταυτότητα',
    value: 16,
  },
  {
    text: 'Υπεύθυνη Δήλωση',
    value: 21,
  },
  {
    text: 'Εκπροσώπηση Εταιρείας',
    value: 49,
  },
  {
    text: 'Καταστατικό Εταιρείας',
    value: 50,
  },
  {
    text: 'Άλλο',
    value: 27,
  },
];

export const TRAFFIC_OFFENSES_TASK_FIELD_NAMES = {
  status: 'Status',
  platesRemoved: 'Plates Removed',
  reason: 'Reason',
  authorityContactEmail: 'Authority Contact Email',
  authorityContactPerson: 'Authority Contact Person',
  authorityContactPhone: 'Authority Contact Phone',
  dateOfCompletion: 'Date Of Completion',
  dateOfViolation: 'Date Of Violation',
  invoiceDate: 'Invoice Date',
  issuedBy: 'Issued By',
  noInstacarCost: 'No Instacar Cost',
  sendEmailDocs: 'Send Email Docs',
  sendEmailPhotos: 'Send Email Photos',
  sendEmailTemplate: 'Send Email Template',
  totalChargeClientAmount: 'Total ChargeClient Amount',
  totalInvoiceCost: 'Total Invoice Cost',
  userEmail: 'User Email',
  userFirstName: 'User Firstname',
  userLastName: 'User Lastname',
  violationFee: 'Violation Fee',
  violationNumber: 'Violation Number',
};
