import dynamic from 'next/dynamic';

const TextField = dynamic(() => import('./TextField/TextField'));
const BoxSection = dynamic(() => import('./BoxSection/BoxSection'));
const Dropdown = dynamic(() => import('./Dropdown/Dropdown'));
const TextArea = dynamic(() => import('./TextArea/TextArea'));
const Checkbox = dynamic(() => import('./Checkbox/Checkbox'));
const Autocomplete = dynamic(() => import('./Autocomplete/Autocomplete'));
const TagsInput = dynamic(() => import('./TagsInput/TagsInput'));
const Field = dynamic(() => import('./Field/Field'));
const FormLabel = dynamic(() => import('./FormLabel/FormLabel'));
const Date = dynamic(() => import('./Date/Date'));
const DateTime = dynamic(() => import('./DateTime/DateTime'));
const SectionTitle = dynamic(() => import('./SectionTitle/SectionTitle'));
const Multiselect = dynamic(() => import('./Multiselect/Multiselect'));

export {
  TextField,
  BoxSection,
  Dropdown,
  TextArea,
  Checkbox,
  Autocomplete,
  TagsInput,
  Field,
  FormLabel,
  Date,
  DateTime,
  SectionTitle,
  Multiselect,
};
