const computeInteriorTypeData = (interiorTypes) => {
  if (!interiorTypes) {
    return [];
  }
  const interiorType = [];
  Object.keys(interiorTypes)?.forEach((key) => {
    interiorType?.push({
      value: interiorTypes[key].slug,
      text: interiorTypes[key].name,
    });
  });
  return interiorType;
};

export default computeInteriorTypeData;
