import dynamic from 'next/dynamic';

const AddRoadsideAssistanceModal = dynamic(() =>
  import('./AddRoadsideAssistanceModal/AddRoadsideAssistanceModal')
);
const RoadsideAssistanceDetails = dynamic(() =>
  import('./RoadsideAssistanceDetails/RoadsideAssistanceDetails')
);

const RoadsideAssistancesGrid = dynamic(() =>
  import('./RoadsideAssistancesGrid/RoadsideAssistancesGrid')
);
const EditRoadsideAssistanceModal = dynamic(() =>
  import('./EditRoadsideAssistanceModal/EditRoadsideAssistanceModal')
);
export {
  AddRoadsideAssistanceModal,
  RoadsideAssistanceDetails,
  RoadsideAssistancesGrid,
  EditRoadsideAssistanceModal,
};
