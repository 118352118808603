import { request } from '@utils';

const getGooglePlaces = async (address) => {
  const results = await request.get(`/v1/maps/query?address=${address}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const geocodeSinglePoint = async (payload) => {
  const results = await request.get(`/v2/maps/geocode`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const geocodeGooglePlace = async (payload) => {
  const results = await request.get(`/v1/maps/geocode`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getGooglePlaces, geocodeGooglePlace, geocodeSinglePoint };
