import { computeModelData, dayDiff, formatDate } from '@utils';
import moment from 'moment';

const durationTypes = {
  subscription: 'SUBSCRIPTIONS',
  service: 'SERVICE',
  booking: 'BOOKING',
  cancel: 'CANCELLED',
  defleet: 'DEFLEETED',
  incoming: 'INCOMING',
  'accident/claim': 'ACCIDENT/CLAIM',
  repair: 'REPAIR',
  maintenance: 'MAINTENANCE',
};

const calculateTaskDate = (carDate, selectionDate) => {
  const selectedDate = new Date(selectionDate * 1000);

  if (!carDate) {
    return formatDate(selectedDate, 'YYYY-MM-DD');
  }

  return formatDate(carDate, 'YYYY-MM-DD');
};

export const handleTimeRange = (array) => {
  if (!array) {
    return {};
  }
  let minDate = new Date().getTime();
  let maxDate = new Date().getTime();

  array?.forEach((vehicle) => {
    vehicle.unavailable?.forEach((availability) => {
      const fromDate = new Date(availability.from).getTime();
      const toDate = new Date(availability.to).getTime();
      if (!minDate || fromDate < minDate) {
        minDate = fromDate;
      }
      if (!maxDate || toDate > maxDate) {
        maxDate = toDate;
      }
    });
  });

  return {
    fromSelectMonth: new Date(minDate).getMonth(),
    fromSelectYear: new Date(minDate).getFullYear(),
    toSelectMonth: new Date(maxDate).getMonth(),
    toSelectYear: new Date(maxDate).getFullYear(),
  };
};

const getType = (item) =>
  item.type === 'accident/claim' ? 'accident_claim' : item.type;

const normalizedAvailability = (data, payload) => {
  const payloadStart = formatDate(
    new Date(payload.dateFrom * 1000),
    'YYYY-MM-DD'
  );
  const payloadEnd = formatDate(new Date(payload.dateTo * 1000), 'YYYY-MM-DD');
  const startDates = data.unavailable
    .map((item) =>
      item.from ? formatDate(item.from, 'YYYY-MM-DD') : payloadStart
    )
    .sort();
  const endDates = data.unavailable
    .map((item) => (item.to ? formatDate(item.to, 'YYYY-MM-DD') : payloadEnd))
    .sort();
  // const startDate = startDates[0] < payloadStart ? payloadStart : startDates[0];
  // const endDate =
  //   endDates[endDates.length - 1] > payloadEnd
  //     ? payloadEnd
  //     : endDates[endDates.length - 1];

  const startDate = startDates[0]; // || payloadStart;
  const endDate = endDates[endDates.length - 1]; // || payloadEnd;

  const duration = dayDiff(moment(startDate), moment(endDate));

  const hasOnlyOneUnavailable = data.unavailable.length === 1;

  const type = hasOnlyOneUnavailable ? getType(data.unavailable[0]) : 0;

  return {
    id: data.id,
    plate: data.plate,
    start_date: startDate || payloadStart,
    duration: !startDates[0] ? -1 : duration,
    title: `${data?.brand} ${data?.model} ${data?.edition} ${data?.fuelType} ${data?.year}`,
    text: `${data?.brand} ${data?.model} ${data?.edition} ${data?.fuelType} ${data?.year}`,
    render: hasOnlyOneUnavailable ? 'split' : '',
    type,
    stage: data.stage,
    unavailabilityType: hasOnlyOneUnavailable
      ? durationTypes[data.unavailable[0].type]
      : '',
  };
};

export const handleAvailabilities = (data, payload) => {
  return data.reduce((acc, cur) => {
    acc.push({
      ...normalizedAvailability(cur, payload),
    });

    // When its only one, we dont want to be expandble.
    // We want to show only one row.
    if (cur.unavailable.length > 1) {
      cur.unavailable.forEach((unavailability, index) => {
        const calendarStart = calculateTaskDate(
          unavailability.from,
          payload?.dateFrom
        );
        const calendarEnd = calculateTaskDate(
          unavailability.to,
          payload?.dateTo
        );
        // const payloadStart = formatDate(
        //   new Date(payload.dateFrom * 1000),
        //   'YYYY-MM-DD'
        // );
        const payloadEnd = formatDate(
          new Date(payload.dateTo * 1000),
          'YYYY-MM-DD'
        );

        // const startDate =
        //   calendarStart < payloadStart ? payloadStart : calendarStart;
        // const endDate = calendarEnd > payloadEnd ? payloadEnd : calendarEnd;

        const startDate = calendarStart; // || payloadStart;
        const endDate = calendarEnd; // || payloadEnd;

        const duration = dayDiff(moment(startDate), moment(endDate));

        if (duration >= 0) {
          acc.push({
            id: `${cur.id} - ${index}`,
            plate: cur.plate,
            start_date: startDate,
            end_date: endDate,
            duration,
            title: '',
            text: `${durationTypes[unavailability.type]} UNTIL ${
              unavailability.to || payloadEnd
            }`,
            unavailabilityType: durationTypes[unavailability.type],
            parent: cur.id,
            type: getType(unavailability),
          });
        }
      });
    }
    return acc;
  }, []);
};

export const handleConfigData = (data) => {
  return { brands: computeModelData(data?.brands), enums: data?.enums || [] };
};
