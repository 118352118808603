import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { selectPartnersState, getPartners } from '@store';
import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';

export const usePartnersOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    partners,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectPartnersState);
  const {
    push,
    query: { page = 1, token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getPartners = useCallback(async () => {
    await dispatch(
      getPartners({
        params: { page, pageSize: 300 },
        payload: { type: FILTER_RULE_ENUMS.EmptyRuleType },
      })
    );
  }, [page, dispatch]);

  const _nextPage = useCallback(
    (currentPage) => {
      if (currentPage === 1) {
        return push('/partners');
      }
      push(`/partners?page=${currentPage}`, undefined, { shallow: true });
    },
    [push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getPartners();
    }
  }, [_getPartners, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    partners,
    pageCount,
    hasErrors,
    errorMessage,
    getPartners: _getPartners,
    nextPage: _nextPage,
    token,
  };
};
