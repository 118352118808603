/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { FileDropZone, ToggleButton, Button, CSVGrid } from '@components';

import { utilitiesServices } from '@services';
import { generateShortId, showError } from '@utils';

const DocumentAnalyzer = () => {
  const [documents, setDocuments] = useState({});
  const [documentType, setDocumentType] = useState(1);
  const [analyzedData, setAnalyzedData] = useState({});
  const [loading, setLoading] = useState(false);

  const _handleDocuments = (files) => {
    const newDocuments = { ...documents };
    newDocuments[documentType] = files;
    setDocuments(newDocuments);
  };

  const _handleOCRResponse = (rowData) => {
    const rows = rowData?.split('\n');
    const data = {
      table: [],
      scheme: [],
    };
    const firstColumns = rows?.[0]?.split(';');
    firstColumns?.forEach((field, index) => {
      data.scheme.push({
        field: `field${index}`,
        headerName: `field${index}`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        customHeadRender: () => null,
      });
    });
    rows?.forEach((row) => {
      const columns = row?.split(';');
      const columnValue = {
        id: generateShortId(),
      };
      columns?.forEach((column, index) => {
        columnValue[`field${index}`] = column;
      });
      data.table?.push(columnValue);
    });

    return data;
  };

  const _ocr = useCallback(async () => {
    try {
      setLoading(true);
      const response = await utilitiesServices.ocr(
        documentType,
        documents[documentType]
      );
      const data = { ...analyzedData };
      data[documentType] = {
        rawData: response,
        table: _handleOCRResponse(response),
      };

      setAnalyzedData(data);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  }, [documentType, documents]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
        ['@media (max-width:1020px)']: {
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          width: '50%',
          ['@media (max-width:1020px)']: {
            width: '100%',
          },
        }}
      >
        <FileDropZone
          callBack={_handleDocuments}
          currentFiles={documents[documentType]}
          accept={{
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/pdf': ['.pdf'],
          }}
        />
        <Box
          sx={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
          }}
        >
          <ToggleButton
            initialValue={1}
            buttons={[
              {
                value: 1,
                label: 'Loan',
              },
              {
                value: 0,
                label: 'License',
              },
            ]}
            callBack={(type) => {
              setDocumentType(type);
            }}
          />
          <Button
            onClick={_ocr}
            color="secondary"
            disabled={!documents[documentType]?.length || loading}
          >
            Analyze
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: '50%',
          ['@media (max-width:1020px)']: {
            width: '100%',
          },
        }}
      >
        <CSVGrid
          data={analyzedData[documentType]?.table}
          csv={analyzedData[documentType]?.rawData}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

const memoizedDocumentAnalyzer = React.memo(DocumentAnalyzer);
export { memoizedDocumentAnalyzer as DocumentAnalyzer };
