import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectVendorsState, getVendor } from '@store';
import { showError } from '@utils';

export const useVendorOperations = (onMount) => {
  const { total, loading, vendor, hasErrors, errorMessage } =
    useSelector(selectVendorsState);
  const {
    query: { vendorId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getVendor = useCallback(async () => {
    await dispatch(getVendor(vendorId));
  }, [vendorId, dispatch]);

  useEffect(() => {
    if (vendorId !== undefined && onMount) {
      _getVendor();
    }
  }, [vendorId, _getVendor, token, onMount]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    vendor,
    vendorId,
    hasErrors,
    errorMessage,
    getVendor: _getVendor,
    token,
  };
};
