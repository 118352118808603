import { pickBy } from 'lodash';

export const getCleanedUrlParams = (params) =>
  pickBy(params, (item) => item !== null && item !== undefined && item !== '');

export const getUrl = (params) => {
  const urlParams = getCleanedUrlParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `/subscriptions?${searchParams}`;
};
