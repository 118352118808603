import { formatDate, removeHtmlTags, timeFromNow } from '@utils';

export const handleMailBoxes = (data) => {
  if (!data?.length) return [];
  return data?.map((item) => {
    return {
      value: item?.id,
      text: item?.address,
    };
  });
};

export const handleCommunicationData = (data) => {
  if (!data.length > 0) {
    return [];
  }
  const emails = [];
  data?.forEach((item) => {
    const newEmail = {
      ...item,
      createdAt: item?.createdAt ? formatDate(item?.createdAt, 'MMMM DD') : '',
      updatedAt: item?.updatedAt ? formatDate(item?.updatedAt, 'MMMM DD') : '',
      messageDate: item?.messageDate
        ? formatDate(item?.messageDate, 'MMMM DD')
        : '',
      assignee: item?.assignee,
      snippet: removeHtmlTags(item?.snippet),
    };
    emails.push(newEmail);
  });
  return emails;
};

export const handleMarkAsReadUnread = (data, emailsId, isRead) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        read: isRead,
      };
    }
    return item;
  });
  return data;
};

export const handleMoveToTeam = (data, emailsId, team) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        team,
      };
    }
    return item;
  });
  return data;
};

export const handleSetStatus = (data, emailsId, status) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        status,
      };
    }
    return item;
  });
  return data;
};

export const handleAssignPerson = (data, emailsId, person) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        assignee: person?.id
          ? {
              id: person?.id,
              firstName: person?.firstName,
              lastName: person?.lastName,
              email: person?.email,
            }
          : null,
      };
    }
    return item;
  });
  return data;
};

const _handleUserData = (to) => {
  let toString = '';
  to?.forEach((item) => {
    if (item?.linkedUserFirstName) {
      toString = `${toString} ${item?.linkedUserFirstName}`;
    }
    if (item?.linkedUserLastName) {
      toString = `${toString} ${item?.linkedUserLastName}`;
    }
    if (item?.emailAddress) {
      toString = `${toString} <${item?.emailAddress}>`;
    }
  });
  return toString;
};

export const handleEmail = (data) => {
  if (!data) {
    return {};
  }
  const content =
    data?.fullText &&
    data?.fullText?.replace?.(
      'prefers-color-scheme: dark',
      'prefers-color-scheme: light'
    );

  return {
    ...data,
    fullText: content,
    updatedAt: data?.updatedAt
      ? `${formatDate(data?.updatedAt)} (${timeFromNow(data?.updatedAt)})`
      : '',
    createdAt: data?.createdAt
      ? `${formatDate(data?.createdAt)} (${timeFromNow(data?.createdAt)})`
      : '',
    date: data?.messageDate
      ? `${formatDate(data?.messageDate)} (${timeFromNow(data?.messageDate)})`
      : '',

    to: _handleUserData(data?.to),
    cc: _handleUserData(data?.cc),
    bcc: _handleUserData(data?.bcc),
  };
};

export const handleHistory = (data) => {
  if (!data) {
    return [];
  }
  return data?.map((item) => {
    let to = item?.to !== null ? item?.to : '-';
    if (item?.field?.includes('Assignee') && Array.isArray(item?.to)) {
      to = item?.to?.[1]?.Value;
    }
    return {
      ...item,
      to,
    };
  });
};
