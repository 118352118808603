export const flatternErrors = (errors, newErrorList = []) => {
  Object.keys(errors).forEach((field) => {
    if (errors[field].message) {
      newErrorList.push(field);
    } else {
      newErrorList = flatternErrors(errors[field], newErrorList);
    }
  });

  return newErrorList;
};

export default flatternErrors;
