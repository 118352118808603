const readFile = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve({});
    }
    const reader = new FileReader();

    reader.onload = () => {
      resolve({ file, uri: reader.result });
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export default readFile;
