import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectReservationsState, getReservations } from '@store';
import { showError } from '@utils';
import { getReservationPayload } from '@helpers';

export const useReservationsOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    reservations,
    reservationsEnums,
    agents,
    pageSize,
    hasErrors,
    errorMessage,
  } = useSelector(selectReservationsState);
  const {
    push,
    query: { page = 1, orderBy = 'createdAt', order = '-1', token, ...others },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _getReservations = useCallback(async () => {
    const payload = getReservationPayload(others);

    await dispatch(getReservations({ orderBy, order, page, ...payload }));
  }, [page, orderBy, order, others, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/reservations?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/reservations?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/reservations?&page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/reservations?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getReservations();
    }
  }, [_getReservations, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []); // eslint-disable-line

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    reservations,
    reservationsEnums,
    agents,
    pageSize,
    hasErrors,
    errorMessage,
    filters: others,
    getReservations: _getReservations,
    sortBy: _sortBy,
    nextPage: _nextPage,
  };
};
