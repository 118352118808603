import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsersState, getUsers } from '@store';
import { showError } from '@utils';

export const useUsersOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    users,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectUsersState);
  const {
    push,
    query: { page = 1, orderBy = '', order = '', token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getUsers = useCallback(async () => {
    await dispatch(getUsers({ orderBy, order, page }));
  }, [page, orderBy, order, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/users?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/users?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/users?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/users?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getUsers();
    }
  }, [_getUsers, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    users,
    pageCount,
    hasErrors,
    errorMessage,
    getUsers: _getUsers,
    nextPage: _nextPage,
    token,
    sortBy: _sortBy,
  };
};
