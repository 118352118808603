export const QUERY_KEYS = {
  GET_BILLING_KEY: 'billing',
  GET_BILLING_TASK_KEY: 'billing-task',
  GET_BILLING_PREDEL_KEY: 'billing-predel',
  GET_BILLING_INSTARIDE_KEY: 'billing-instaride',
  GET_BILLING_REFUNDS_KEY: 'billing-refunds',
  GET_BILLING_DAMAGES_KEY: 'billing-damages',
  GET_BILLING_OTHER_KEY: 'billing-other',
  GET_BILLING_ASSIGNEES_KEY: 'billing-assignees',

  GET_VEHICLE_BY_INTERNAL_ID_KEY: 'vehicle-by-internal-id',
};
