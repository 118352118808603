import { request } from '@utils';
import { LIMIT } from '@constants';

const getErrors = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/errors`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getErrors };
