import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { boardsServices, fleetVehiclesServices } from '@services';
import { GENERIC_ERROR_MESSAGE, BOARDS_ID } from '@constants';
import { groupBy } from 'lodash';
import { GREEN_CARD_TASK_STATUSES_NAMES } from 'components/greenCard/constants';
import {
  handleGreenCardData,
  handleGreenCardTaskData,
  handleVehicleData,
} from './greenCard.handlers';
// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  greenCard: {
    boardId: BOARDS_ID.GREEN_CARD,
    tasks: [],
    columns: [],
  },
  greenCardTask: {},
  vehicle: {},
  total: 0,
  pageSize: 3000,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const greenCardSlice = createSlice({
  name: 'greenCard',
  initialState,
  reducers: {
    fetchGreenCard: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
      state.greenCardTask = {};
    },
    fetchGreenCardSuccess: (state, { payload }) => {
      state.greenCard = handleGreenCardData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchGreenCardTaskSuccess: (state, { payload }) => {
      state.greenCardTask = handleGreenCardTaskData(payload?.task);
      state.vehicle = handleVehicleData(payload?.vehicle);
      state.loading = false;
      state.errorMessage = '';
    },
    fetchGreenCardFailure: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = error?.message || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchGreenCard,
  fetchGreenCardSuccess,
  fetchGreenCardTaskSuccess,
  fetchGreenCardFailure,
} = greenCardSlice.actions;

export const selectGreenCardState = (state) => state.greenCard;
export const selectGreenCardsColumnsState = ({ greenCard }) => {
  const { tasks } = greenCard?.greenCard || {};
  const order = [0, 22, 23, 2, 25, 26, 3, 27, 12];
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: GREEN_CARD_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: GREEN_CARD_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: GREEN_CARD_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const getGreenCard = (payload) => {
  return async (dispatch) => {
    dispatch(fetchGreenCard());
    const page = parseInt(payload?.page, 10);

    try {
      const response = await boardsServices.getBoardsFilters(payload);
      response.meta.page = page;
      dispatch(fetchGreenCardSuccess(response));
    } catch (error) {
      dispatch(fetchGreenCardFailure(error));
    }
  };
};

export const getGreenCardTask = (taskId) => {
  return async (dispatch) => {
    dispatch(fetchGreenCard());

    try {
      const task = await boardsServices.getTaskById(taskId);
      const carId = task?.data?.customFields?.carId;
      let vehicle = {};
      if (carId) {
        vehicle = await fleetVehiclesServices.getVehicleByInternalId(carId);
      }

      dispatch(
        fetchGreenCardTaskSuccess({
          task: task?.data,
          vehicle: vehicle?.data,
        })
      );
    } catch (error) {
      dispatch(fetchGreenCardFailure(error));
    }
  };
};

export default greenCardSlice.reducer;
