import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { loggerServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { formatDate } from '@utils';

const handleData = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const error = {
      created_at: item.created_at
        ? formatDate(item.created_at, 'DD/MM/YYYY')
        : '-',
      deal_stage: item.deal_stage || '-',
      elorus_contact_id: item.elorus_contact_id || '-',
      message: item.message || '-',
      organization_id: item.organization_id || '-',
      type: item.type || '-',
      user_email: item.user_email || '-',
      id: item._id,
    };
    newArray[index] = error;

    return newArray;
  });
  return array;
};

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  errors: [],
  total: 0,
  firstItem: 0,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    fetchErrors: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchErrorsSuccess: (state, { payload }) => {
      state.errors = handleData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.errors.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.errors?.length || 0);
      state.pageCount =
        payload?.meta?.total && payload.meta.total < state.pageSize
          ? 1
          : Math.ceil(payload.meta.total / state.pageSize);
    },
    fetchErrorsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchErrors, fetchErrorsSuccess, fetchErrorsFailure } =
  errorsSlice.actions;

export const getErrors = (payload) => {
  return async (dispatch) => {
    dispatch(fetchErrors());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await loggerServices?.getErrors(payload);
      response.meta.page = page;

      dispatch(fetchErrorsSuccess(response));
    } catch (error) {
      dispatch(fetchErrorsFailure(error));
    }
  };
};

export const selectErrorsState = (state) => state.errors;

export default errorsSlice.reducer;
