import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { agentsServices } from '@services';
import { LIMIT } from '@constants';
import { getAgentsRequestPayload } from 'helpers';
import { handleAgentsData } from './agents.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  agents: [],
  total: 0,
  firstItem: 0,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: 1000,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    fetchAgents: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchAgentsSuccess: (state, { payload }) => {
      state.agents = handleAgentsData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.agents.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.agents?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchAgentsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { fetchAgents, fetchAgentsSuccess, fetchAgentsFailure } =
  agentsSlice.actions;

export const selectAgentsState = (state) => state.agents;

export const getAgents = (requestParams) => {
  return async (dispatch) => {
    dispatch(fetchAgents());

    try {
      const page = parseInt(requestParams?.page, 10);
      const params = {
        page,
        pageSize: initialState.pageSize,
      };
      const payload = getAgentsRequestPayload(requestParams);
      const agents = await agentsServices.getFilterAgents({ params, payload });
      agents.meta.page = page;

      dispatch(fetchAgentsSuccess(agents));
    } catch (error) {
      dispatch(fetchAgentsFailure(error));
    }
  };
};

export default agentsSlice.reducer;
