/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArnmAccidentsState,
  getArnmAccidents,
  getArnmAccidentsAssignees,
  selectArnmAccidentsColumnsState,
} from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';
import { BOARDS, BOARDS_ID } from '@constants';

export const useArnmAccidentsOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    arnmAccidents,
    pageSize,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectArnmAccidentsState);
  const {
    isReady,
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', token },
  } = useRouter();
  const dispatch = useDispatch();
  const columns = useSelector(selectArnmAccidentsColumnsState);
  const _getArnmAccidents = useCallback(async () => {
    let payload = localStorage.arnmAccidentsQuery;
    let { arnmAccidentsFilterRules } = localStorage;
    const defaultRule = {
      type: 5,
      field: 'boardId',
      operator: 0,
      value: BOARDS_ID.ARM_ACCIDENTS,
    };

    if (arnmAccidentsFilterRules) {
      arnmAccidentsFilterRules = JSON.parse(arnmAccidentsFilterRules);
    } else {
      arnmAccidentsFilterRules = [];
    }
    arnmAccidentsFilterRules = [defaultRule, ...arnmAccidentsFilterRules];
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = payload.value.map((item) => {
          if (item.field === 'customFields.vehiclePlate') {
            return { ...item, value: item.value.toUpperCase() };
          }
          return item;
        });
        payload.value = [...payload.value, ...arnmAccidentsFilterRules];
      } else if (arnmAccidentsFilterRules?.length) {
        const newRules = arnmAccidentsFilterRules?.filter(
          (item) => !!item?.value
        );
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: newRules,
        };
      }
    } else if (!payload && arnmAccidentsFilterRules?.length) {
      const newRules = arnmAccidentsFilterRules?.filter(
        (item) => !!item?.value
      );
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: newRules,
      };
    } else {
      payload = defaultRule;
    }
    await dispatch(
      getArnmAccidents({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.ARM_ACCIDENTS,
        boardType: BOARDS.ARM_ACCIDENTS,
        ...payload,
      })
    );
  }, [page, orderBy, order, dispatch]);

  const _addTask = useCallback(
    async (payload = {}) => {
      await boardsServices.addTask(
        payload?.parent
          ? payload?.parent?.split('-')[0]
          : arnmAccidents?.boardId,
        { ...payload }
      );
      await _getArnmAccidents();
    },
    [arnmAccidents]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload?.id, payload);
        }
      } catch (error) {
        showError(error);
      }
    },
    [arnmAccidents]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/arnmAccidents?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/arnmAccidents?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/arnmAccidents?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/arnmAccidents?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getArnmAccidents();
      }
    }, 100);
  }, [page, token, _getArnmAccidents, fetchOnMount, isReady]);

  useEffect(() => {
    dispatch(getArnmAccidentsAssignees({ page: 1, pageSize: 1000 }));
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  const normalizedAssignees = [
    { value: '', text: '-' },
    ...assignees.data.map((assignee) => ({
      value: assignee.value,
      text: assignee.label,
    })),
  ];

  return {
    page,
    total,
    pageSize,
    loading,
    arnmAccidents,
    columns,
    hasErrors,
    errorMessage,
    assignees: normalizedAssignees,
    addTask: _addTask,
    updateTask: _updateTask,
    getArnmAccidents: _getArnmAccidents,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
