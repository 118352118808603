import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubscriptionHistoryState, getSubscriptionHistory } from '@store';
import { showError } from '@utils';

export const useSubscriptionHistoryOperations = () => {
  const history = useSelector(selectSubscriptionHistoryState);

  const {
    query: { subscriptionId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getSubscriptionHistory = useCallback(() => {
    if (subscriptionId) {
      dispatch(getSubscriptionHistory(subscriptionId));
    }
  }, [subscriptionId, dispatch]);

  useEffect(() => {
    if (history?.errorMessage) {
      showError(history.errorMessage);
    }
  }, [history?.errorMessage]);

  return {
    loading: history?.loading,
    history,
    hasErrors: history?.hasErrors,
    errorMessage: history?.errorMessage,
    getSubscriptionHistory: _getSubscriptionHistory,
  };
};
