import { request } from '@utils';
import { LIMIT } from '@constants';

const getRoadsideAssistances = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/roadsideAssistances`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getRoadsideAssistancesEnums = async () => {
  const results = await request.get(`/v1/roadsideAssistances/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getRoadsideAssistance = async (id) => {
  const results = await request.get(`/v1/roadsideAssistances/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteRoadsideAssistance = async (roadsideAssistanceId) => {
  const results = await request.remove(
    `/v1/roadsideAssistances/${roadsideAssistanceId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addRoadsideAssistance = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/roadsideAssistances`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editRoadsideAssistance = async (roadsideAssistanceId, payload) => {
  const body = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/roadsideAssistances/${roadsideAssistanceId}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getRoadsideAssistances,
  deleteRoadsideAssistance,
  addRoadsideAssistance,
  getRoadsideAssistance,
  getRoadsideAssistancesEnums,
  editRoadsideAssistance,
};
