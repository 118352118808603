const generateYears = (startYear, extraYears = 0) => {
  const years = [];
  const endYear = new Date().getFullYear() + extraYears;

  for (let i = startYear; i <= endYear; i++) {
    years.push({
      text: i,
      value: i,
    });
  }

  return years;
};

export default generateYears;
