import { AGENT_ROLES } from '@constants';

export const rolesOptions = [
  {
    label: 'Admin',
    value: AGENT_ROLES.isAdmin,
  },
  {
    label: 'Customer Care Agent',
    value: AGENT_ROLES.isCustomerCareAgent,
  },
  {
    label: 'Fleet Ops Associate',
    value: AGENT_ROLES.isFleetOpsAssociate,
  },
  {
    label: 'Project Manager',
    value: AGENT_ROLES.isProjectManager,
  },
  {
    label: 'Sales Consultant',
    value: AGENT_ROLES.isSalesConsultant,
  },
  {
    label: 'Supply Chain Associate',
    value: AGENT_ROLES.isSupplyChainAssociate,
  },
  {
    label: 'Performance Marketing Specialist',
    value: AGENT_ROLES.isPerformanceMarketingSpecialist,
  },
  {
    label: 'Treasurer',
    value: AGENT_ROLES.isTreasurer,
  },
  {
    label: 'Graphic Designer',
    value: AGENT_ROLES.isGraphicDesigner,
  },
  {
    label: 'Controlling Manager',
    value: AGENT_ROLES.isControllingManager,
  },
  {
    label: 'Head Of Marketing',
    value: AGENT_ROLES.isHeadOfMarketing,
  },
  {
    label: 'Assistant Accountant',
    value: AGENT_ROLES.isAssistantAccountant,
  },
  {
    label: 'Insurance Specialist',
    value: AGENT_ROLES.isInsuranceSpecialist,
  },
  {
    label: 'Social Media Manager',
    value: AGENT_ROLES.isSocialMediaManager,
  },
  {
    label: 'Senior Sales Consultant',
    value: AGENT_ROLES.isSeniorSalesConsultant,
  },
  {
    label: 'Customer Success Coordinator',
    value: AGENT_ROLES.isCustomerSuccessCoordinator,
  },
  {
    label: 'Accountant',
    value: AGENT_ROLES.isAccountant,
  },
  {
    label: 'ARM Team Lead',
    value: AGENT_ROLES.isARMTeamLead,
  },
  {
    label: 'Product Designer',
    value: AGENT_ROLES.isProductDesigner,
  },
  {
    label: 'Credit Manager',
    value: AGENT_ROLES.isCreditManager,
  },
  {
    label: 'Operations Director',
    value: AGENT_ROLES.isOperationsDirector,
  },
  {
    label: 'Accidents Senior Associate',
    value: AGENT_ROLES.isAccidentsSeniorAssociate,
  },
  {
    label: 'Content Marketing Manager',
    value: AGENT_ROLES.isContentMarketingManager,
  },
  {
    label: 'Senior Accountant',
    value: AGENT_ROLES.isSeniorAccountant,
  },
  {
    label: 'Product Content Editor',
    value: AGENT_ROLES.isProductContentEditor,
  },
  {
    label: 'Billing Associate',
    value: AGENT_ROLES.isBillingAssociate,
  },
  {
    label: 'Credit Control Associate',
    value: AGENT_ROLES.isCreditControlAssociate,
  },
  {
    label: 'CFO',
    value: AGENT_ROLES.isCFO,
  },
  {
    label: 'Repairs Maintenance Associate',
    value: AGENT_ROLES.isRepairsMaintenanceAssociate,
  },
  {
    label: 'Financial Controller',
    value: AGENT_ROLES.isFinancialController,
  },
  {
    label: 'Credit Control Lead',
    value: AGENT_ROLES.isCreditControlLead,
  },
  {
    label: 'Customer Success Team Lead',
    value: AGENT_ROLES.isCustomerSuccessTeamLead,
  },
  {
    label: 'Head Of Product',
    value: AGENT_ROLES.isHeadOfProduct,
  },
  {
    label: 'Fleet Ops Team Lead',
    value: AGENT_ROLES.isFleetOpsTeamLead,
  },
  {
    label: 'Product Operations Associate',
    value: AGENT_ROLES.isProductOperationsAssociate,
  },
  {
    label: 'Returns Deliveries Lead',
    value: AGENT_ROLES.isReturnsDeliveriesLead,
  },
  {
    label: 'HR Supervisor',
    value: AGENT_ROLES.isHRSupervisor,
  },
  {
    label: 'Sales Team Lead',
    value: AGENT_ROLES.isSalesTeamLead,
  },
  {
    label: 'External Partner',
    value: AGENT_ROLES.isExternalPartner,
  },
  {
    label: 'Head Of Sourcing',
    value: AGENT_ROLES.isHeadOfSourcing,
  },
];
