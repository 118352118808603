import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeamsState, getTeam } from '@store';
import { showError } from '@utils';

export const useTeamOperations = (fetchOnMount) => {
  const { total, loading, team, agents, hasErrors, errorMessage } =
    useSelector(selectTeamsState);
  const {
    query: { teamId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getTeam = useCallback(async () => {
    await dispatch(getTeam(teamId));
  }, [teamId, dispatch]);

  useEffect(() => {
    if (teamId !== undefined && fetchOnMount) {
      _getTeam();
    }
  }, [teamId, _getTeam, token, fetchOnMount]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    team,
    agents,
    hasErrors,
    errorMessage,
    getTeam: _getTeam,
  };
};
