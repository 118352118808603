import { formatDate } from '@utils';

export const handleMailBoxes = (array) => {
  if (!array) {
    return [];
  }
  return array?.map((mailbox) => {
    return {
      id: mailbox?.id,
      createdAt: mailbox?.createdAt
        ? formatDate(mailbox?.createdAt, 'DD/MM/YYYY')
        : '-',
      createdBy: mailbox?.createdBy || '-',
      address: mailbox?.address || '-',
      internalId: mailbox?.internalId || '-',
      isActive: mailbox?.isActive,
      lastHistoryId: mailbox?.lastHistoryId || '-',
    };
  });
};
