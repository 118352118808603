/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import YouTube from 'react-youtube';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { readFile, generateShortId, showError } from '@utils';
import { Button, DynamicForm, FormModal, Image } from '@components';
import Modal from '@mui/material/Modal';
import { useDropzone } from 'react-dropzone';
import { addVideoForm } from './addVideoForm';
import { addDescriptionForm } from './addDescriptionForm';
import { Images } from './Images';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const UploadImage = forwardRef(
  (
    {
      onChange,
      onDeleteImage,
      images,
      loading,
      caption = '',
      description = '',
      video = '',
      onVideoChange,
      customStyles,
      onOrderChange,
    },
    ref
  ) => {
    const formRef = useRef(null);
    const videoFormRef = useRef(null);
    const [localImages, setLocalImages] = useState([]);
    const [currentVideo, setCurrentVideo] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentFormState, setCurrentFormState] = useState({});
    const [currentVideoFormState, setCurrentVideoFormState] = useState({});
    const [openForm, setOpenForm] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [selectedOption, setOption] = useState('exterior');
    const [value, setValue] = React.useState(0);
    const [openDialog, setOpenDialog] = useState(false);

    const { getRootProps, getInputProps, acceptedFiles, isDragAccept } =
      useDropzone({
        noClick: loading,
        noKeyboard: loading,
        multiple: true,
        accept: {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
        },
      });

    const _videoOptions = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    };

    const _computeVideoId = (link) => {
      if (!link) return '';
      return link.split('v=')[1];
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
      let option = 'exterior';
      if (newValue === 1) {
        option = 'interior';
      } else if (newValue === 2) {
        option = 'video';
      }
      setOption(option);
    };
    useImperativeHandle(ref, () => ({
      _reset() {},
    }));

    const _toggleModal = () => {
      setOpenImage(!openImage);
    };

    const _toggleFormModal = () => {
      setOpenForm(!openForm);
    };

    const _setImage = (image) => {
      setSelectedImage(image);
    };

    const _reorderImages = (items) => {
      setLocalImages(items);
      onChange(items);
    };

    const _getImage = async (imageFiles) => {
      try {
        if (loading) return;
        const fileArray = [...imageFiles];
        const data = await Promise.all(
          fileArray.map(async (file) => {
            const fileContents = await readFile(file);
            fileContents.id = generateShortId();
            fileContents.localFile = true;
            fileContents.tags = [selectedOption];
            fileContents.caption = caption;
            fileContents.description = description;
            fileContents.order = localImages.length - 1;
            return fileContents;
          })
        );
        setLocalImages([...localImages, ...data]);
        const imagesToupLoad = localImages?.filter((img) => {
          return img?.localFile;
        });
        onChange([...imagesToupLoad, ...data]);
      } catch (error) {
        showError(error);
      }
    };
    //
    const _onFormStateChange = (formState) => {
      if (
        selectedImage &&
        formState.caption &&
        JSON.stringify(currentFormState) !== JSON.stringify(formState)
      ) {
        const newImages = localImages?.map((img) => {
          const image = JSON.parse(JSON.stringify(img));
          if (img?.file && img?.id === selectedImage?.id) {
            img.caption = formState.caption;
            image.description = formState.description;
          }
          if (!img?.file && image?.id === selectedImage?.id) {
            image.caption = formState.caption;
            image.isPatch = true;
            image.description = formState.description;
            return image;
          }
          return img;
        });
        setLocalImages(newImages);
        onChange(newImages);
        setCurrentFormState(formState);
      }
    };

    const _onVideoFormStateChange = (formState) => {
      if (JSON.stringify(currentVideoFormState) !== JSON.stringify(formState)) {
        onVideoChange(formState?.videoUrl);
        setCurrentVideo(formState?.videoUrl);
        setCurrentVideoFormState(formState);
      }
    };

    useEffect(() => {
      if (images?.length) {
        setLocalImages([...images]);
      }
    }, [images]);

    const _handleCloseDialog = (e) => {
      e?.stopPropagation();
      setOpenDialog(false);
    };

    const _onDeleteImage = async () => {
      try {
        if (!selectedImage.localFile) {
          await onDeleteImage(selectedImage?.id);
        }
        const newImages = localImages?.filter((img) => {
          return selectedImage?.id !== img?.id;
        });
        setLocalImages(newImages);
        onChange(newImages);
      } catch (error) {
        showError(error);
      }
    };

    const _onConfirmation = () => {
      if (_onDeleteImage) {
        _onDeleteImage();
      }
      _handleCloseDialog();
    };

    const _computeSize = useCallback((files) => {
      let fileSize = 0;
      files?.forEach((file) => {
        fileSize += file.size;
      });
      return (fileSize / 1024 ** 2).toFixed(2);
    }, []);

    const _onVideoReady = (e) => {
      e.target.pauseVideo();
    };

    const _onOrderChange = (image) => {
      if (onOrderChange) {
        onOrderChange(image);
      }
    };

    useEffect(() => {
      if (_computeSize(acceptedFiles) <= 10) {
        _getImage(acceptedFiles);
        setSelectedImage(null);
      } else {
        showError('You cannot upload files more than 30MB');
      }
    }, [acceptedFiles]);

    useEffect(() => {
      setTimeout(() => {
        if (selectedImage && openForm) {
          formRef?.current?._setValue?.('caption', selectedImage.caption);
          formRef?.current?._setValue?.(
            'description',
            selectedImage.description
          );
        } else {
          formRef?.current?._setValue?.('caption', '');
          formRef?.current?._setValue?.('description', '');
        }
      }, 10);
    }, [selectedImage, openForm]);

    useEffect(() => {
      if (selectedOption === 'video' && video) {
        videoFormRef?.current?._setValue('videoUrl', video);
        setCurrentVideo(video);
      }
    }, [video, selectedOption]);

    return (
      <div>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.white.paper,
            borderRadius: '8px',
            padding: '8px 16px',
            marginBottom: '32px',
            border: '1px solid rgb(199, 199, 199)',
          }}
        >
          <Box
            sx={{
              borderBottom: (theme) =>
                customStyles
                  ? `1px solid ${theme.palette.secondary.main}`
                  : '1px solid black',
              paddingBottom: '8px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Media</Typography>
          </Box>
          <Box>
            <Box sx={{ width: 'fit-content' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: { background: 'black', marginTop: '-5px' },
                  }}
                >
                  <Tab label="Exterior photos" {...a11yProps(0)} />
                  <Tab label="Interior photos" {...a11yProps(1)} />
                  <Tab label="Video" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '32px',
              marginTop: '16px',
              flexWrap: 'wrap',
            }}
          >
            {selectedOption !== 'video' && (
              <Box sx={{ minWidth: 240, width: 'auto' }} {...getRootProps()}>
                <label htmlFor="raised-button-file">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      marginBottom: 4,
                    }}
                  >
                    <input {...getInputProps()} />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          maWidth: '292px',
                          height: '259px',
                          border: (theme) =>
                            `${isDragAccept ? 2 : 1}px solid ${
                              isDragAccept
                                ? theme.palette.primary.main
                                : theme.palette.secondary.main
                            }`,
                          borderRadius: '8px',
                          backgroundColor: (theme) =>
                            theme.palette.backgroundGray.paper,
                          display: 'flex',
                          justifyContent: 'center',
                          gap: 2,
                          padding: 1,
                          backgroundImage: "url('/images/file.svg')",
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            width: '100%',
                            maxWidth: '156px',
                            textAlign: 'center',
                            fontSize: '14px',
                          }}
                        >
                          Επιλέξτε τα αρχεία ή σύρε και άφησέ τα εδώ
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            width: '48px',
                            height: '48px',
                            position: 'absolute',
                            bottom: '-24px',
                            border: (theme) =>
                              `1px solid ${theme.palette.lightGray.main}`,
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
                            borderRadius: '50%',
                            backgroundImage: "url('/images/add.svg')",
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          marginTop: '30px',
                        }}
                      >
                        Μέγιστο μέγεθος φωτογραφίας 10 Mb
                      </Typography>
                    </Box>
                  </Box>
                </label>
              </Box>
            )}
            {selectedOption !== 'video' && (
              <Box
                sx={{
                  maxWidth: '1000px',
                  width: '-webkit-fill-available',
                }}
              >
                {localImages?.length > 0 && (
                  <Images
                    reorderImages={_reorderImages}
                    images={localImages}
                    selectedOption={selectedOption}
                    toggleShowImage={_toggleModal}
                    toggleFormModal={_toggleFormModal}
                    onDeleteImage={() => {
                      setOpenDialog(!openDialog);
                    }}
                    onOrderChange={_onOrderChange}
                    setImage={_setImage}
                  />
                )}
              </Box>
            )}
            {selectedOption === 'video' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  marginBottom: '84px',
                  maxWidth: '365px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <YouTube
                    videoId={_computeVideoId(currentVideo)}
                    opts={_videoOptions}
                    onReady={_onVideoReady}
                  />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '23px',
                      marginBottom: '8px',
                      fontWeight: 'bold',
                    }}
                  >
                    video preview
                  </Typography>
                </Box>

                <DynamicForm
                  id="video-form"
                  ref={videoFormRef}
                  fieldsToWatch={['videoUrl']}
                  loading={loading}
                  formScheme={addVideoForm}
                  defaultValues={{}}
                  onFormStateChange={_onVideoFormStateChange}
                  customStyles={customStyles}
                />
              </Box>
            )}
          </Box>
          <FormModal
            id="set-image-modal"
            title="Add Content"
            ref={formRef}
            open={openForm}
            formScheme={addDescriptionForm}
            handleClose={_toggleFormModal}
            onSubmit={_toggleFormModal}
            withCancel
            disabled
            size="xs"
            customStyles
            onFormStateChange={_onFormStateChange}
          />
          <Modal open={openImage} onClose={_toggleModal} disableAutoFocus>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: '466px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '4px',
              }}
            >
              <Image
                src={selectedImage?.url || selectedImage?.uri}
                alt="car_image"
                layout="fill"
                objectFit="contain"
                quality="100"
                wrapperStyle={{
                  width: '100%',
                  height: '466px',
                }}
              />
            </Box>
          </Modal>
        </Box>
        <Modal open={openDialog} onClose={_toggleModal} disableAutoFocus>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: '4px',
              borderRadius: '4px',
            }}
          >
            <DialogTitle id="alert-dialog-title">Delete image?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="cancel"
                onClick={_handleCloseDialog}
                loading={loading}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={_onConfirmation}
                autoFocus
                loading={loading}
              >
                Delete
              </Button>
            </DialogActions>
          </Box>
        </Modal>
      </div>
    );
  }
);

const memoizedUploadImage = React.memo(UploadImage);
export { memoizedUploadImage as UploadImage };
export default memoizedUploadImage;
