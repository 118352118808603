const formatVehicleType = (type) => {
  const types = {
    CAR: 'CAR',
    SCOOTER: 'SCOOTER',
    ELECTRONIC_BIKE: 'EBIKE',
  };
  return types[type];
};

export default formatVehicleType;
