/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { pickBy, identity } from 'lodash';

import {
  selectFleetVehiclesState,
  getFleetVehicles,
  changeSearchTerm,
  getFleetVehiclesEnums,
} from '@store';
import { showError } from '@utils';
import { useLayout } from 'hooks/layout';

const getUrl = (params) => {
  const urlParams = pickBy(params, identity);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `/vehicles?${searchParams}`;
};

export const useFleetVehiclesOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    vehicles,
    pageCount,
    hasErrors,
    errorMessage,
    filters,
    enums,
  } = useSelector(selectFleetVehiclesState);

  const {
    push,
    query: { page = '1', orderBy = '', order = '', stage, token },
    isReady,
  } = useRouter();

  const { tenant } = useLayout();
  const dispatch = useDispatch();
  const _getVehicles = useCallback(
    (extraParams = {}) => {
      const currentPage = page || '1';
      const params = pickBy(
        {
          orderBy,
          order,
          page: currentPage,
          tenant: tenant !== null ? tenant.toString() : undefined,
          stage,
          ...extraParams,
        },
        identity
      );
      dispatch(getFleetVehicles({ ...params }));
    },
    [orderBy, order, page, tenant, stage, dispatch]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      const params = {
        ...sortingModel,
        page,
        tenant: tenant !== null ? tenant.toString() : undefined,
        stage,
      };

      const url = getUrl(params);

      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      const params = {
        orderBy,
        order,
        page: currentPage,
        tenant: tenant !== null ? tenant.toString() : undefined,
        stage,
      };
      const url = getUrl(params);

      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, filters.stage, push]
  );
  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getVehicles();
    }
  }, [page, _getVehicles, token, isReady]);

  useEffect(() => {
    dispatch(getFleetVehiclesEnums());
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    dispatch(changeSearchTerm(''));
  }, [stage]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
    if (enums.errorMessage) {
      showError(enums.errorMessage);
    }
  }, [errorMessage, enums.errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    vehicles,
    pageCount,
    hasErrors,
    errorMessage,
    filters,
    enums,
    changeSearchTerm,
    getVehicles: _getVehicles,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
