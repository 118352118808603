import { useState, useEffect } from 'react';

export const useIframeHeight = () => {
  const [iframeHeight, setIframeHeight] = useState('100%');

  const resizeIframe = () => {
    setIframeHeight(`${window.innerHeight - 135}px`);
  };

  useEffect(() => {
    resizeIframe(); // Initial resize

    // Attach event listeners for resizing
    window.addEventListener('resize', resizeIframe);
    return () => {
      // remove event listener when the component unmounts
      window.removeEventListener('resize', resizeIframe);
    };
  }, []);

  return { iframeHeight };
};

export default useIframeHeight;
