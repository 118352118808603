import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInspectionedCarsState,
  getInspectionedCars,
  getMoreInspectionedCars,
  inspectionChangePage,
  inspectionInitState,
} from '@store';
import { showError } from '@utils';
import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';

export const useInspectionCarsOperations = (inspectionType, fetchOnMount) => {
  const {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    pageCount,
    pageSize,
    groups,
  } = useSelector(selectInspectionedCarsState);
  const { isReady } = useRouter();
  const dispatch = useDispatch();

  const _computePayload = (currentPage) => {
    const payload = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      field: null,
      operator: null,
      value: [
        {
          type: FILTER_RULE_ENUMS.AndRuleType,
          field: null,
          operator: null,
          value: [
            {
              field: 'deliveryInspectionInfo',
              operator: FILTER_OPERATOR_ENUMS['='],
              type: FILTER_RULE_ENUMS.noEpmpty,
            },
          ],
        },
        {
          type: FILTER_RULE_ENUMS.AndRuleType,
          field: null,
          operator: null,
          value: [
            {
              field: 'returnInspectionInfo',
              operator: FILTER_OPERATOR_ENUMS['='],
              type: FILTER_RULE_ENUMS.noEpmpty,
            },
          ],
        },
      ],
    };
    return {
      inspectionType,
      page: currentPage,
      pageSize,
      order: -1,
      orderBy: `inspectionDate`,
      ...payload,
    };
  };

  const _getInspectionedCars = useCallback(
    async (currentPage = 1) => {
      const payload = _computePayload(currentPage);

      await dispatch(getInspectionedCars(payload));
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _getMoreInspectionedCars = useCallback(
    async (currentPage) => {
      const payload = _computePayload(currentPage);

      await dispatch(getMoreInspectionedCars(payload));
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchMore = () => {
    const nextPage = page + 1;

    if (pageCount >= nextPage) {
      _getMoreInspectionedCars(nextPage);
    }
  };

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
    return () => dispatch(inspectionInitState());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getInspectionedCars();
    }
  }, [fetchOnMount, isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    vehicles,
    hasErrors,
    errorMessage,
    page,
    inspectionType,
    pageSize,
    groups,
    getInspectionedCars: _getInspectionedCars,
    fetchMore,
    inspectionChangePage,
  };
};
