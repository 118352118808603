import { formatDate } from '@utils';

export const handlePartnersData = (data) => {
  if (!data) {
    return [];
  }

  return data?.map((item) => {
    const defaultPhoneNumber =
      item.phoneNumbers?.find((phone) => phone.isDefault) ||
      item.phoneNumbers?.[0];

    return {
      id: item.id,
      name: item.name,
      email: item.email,
      password: item.password,
      isActive: item.isActive,
      internalId: item.internalId,
      phoneNumbers: defaultPhoneNumber ? [defaultPhoneNumber] : [],
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
    };
  });
};
