import dynamic from 'next/dynamic';
import EditAgentModal from './EditAgentModal/EditAgentModal';

const AgentGrid = dynamic(() => import('./AgentGrid/AgentGrid'));
const AddAgentModal = dynamic(() => import('./AddAgentModal/AddAgentModal'));
const DeleteAgent = dynamic(() => import('./DeleteAgent/DeleteAgent'));
const AgentsFilters = dynamic(() => import('./AgentsFilters/AgentsFilters'));

export { AgentGrid, AddAgentModal, DeleteAgent, EditAgentModal, AgentsFilters };
/* PLOP_INJECT_COMPONENT_EXPORT */
