import { PRICING_TYPES } from '@constants';
import {
  computeModelData,
  fixedNumber,
  formatAmount,
  formatDate,
  formatVehicleStage,
  generateShortId,
} from '@utils';
import {
  getAvailability,
  getBodySize,
  getBodyType,
  getCategory,
  getEngineType,
  getEuroClass,
  getFuelType,
  getProductLines,
  getTransmission,
  getWheelDrives,
} from './vehicles.helpers';
import { INITIAL_VALUES } from './constants';

export const handleVehicles = (array) => {
  if (!array) {
    return [];
  }

  array?.forEach((item, index, newArray) => {
    const itemPrice = item.pricing?.find((pricingItem) => {
      return pricingItem?.type === PRICING_TYPES.open;
    });

    const row = {
      availability: item.availability || '-',
      brand: item?.properties?.brand || '-',
      category: item?.properties?.category || '-',
      edition: item?.properties?.edition?.trim() || '-',
      year: item?.properties?.year || '-',
      engineDisplacement: item?.properties?.engine?.displacement,
      fuelType: item?.properties?.engine?.fuelType || '-',
      image: item?.image?.url ? item.image?.url : '',
      initialPrice: itemPrice.initialPrice
        ? formatAmount(fixedNumber(itemPrice.initialPrice))
        : '-',
      instastart: itemPrice.instastart
        ? formatAmount(fixedNumber(itemPrice.instastart))
        : '-',
      kilometers: item.kilometers || '-',
      model: item?.properties?.model || '-',
      monthlyKilometers: item.monthlyKilometers || '-',
      name: item.name || '-',
      price: itemPrice.price ? formatAmount(fixedNumber(itemPrice.price)) : '-',
      status: item.published ? 'Published' : 'Unpublished',
      published: item.published,
      skuId: item.skuId || '-',
      tags: item.tags,
      transmission: item?.properties?.transmission || '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      vendors: item.vendors,
      id: item.skuId,
      bodyType: item?.properties?.body?.type || '-',
      type: item?.type || '-',
      powerHp: item?.properties?.engine?.powerHp || '-',
      batterySize: item?.properties?.engine?.batterySize || '-',
      euroClass: item?.properties?.engine?.euroClass || '-',
      signUpFee: formatAmount(fixedNumber(item.signUpFee)) || '-',
    };
    newArray[index] = row;

    return newArray;
  });
  return array;
};

export const handleVehicleData = (data) => {
  if (
    !('enums' in data) &&
    !('colors' in data) &&
    !('providers' in data) &&
    !('vehicle' in data) &&
    !('models' in data)
  ) {
    return {};
  }
  if (data?.enums?.data?.interiorTypes) {
    data.enums.data.interiorTypes = Object.keys(
      data?.enums?.data?.interiorTypes
    )?.map((type) => {
      return data?.enums?.data?.interiorTypes[type];
    });
  }
  data.enums = data.enums.data;
  data.colors = data?.colors?.data;
  data.providers = data?.providers?.data;
  data.models = data.models.data;
  data.vehicle = data?.vehicle?.data;

  if (data.vehicle) {
    // normalize sku values
    const fixedPrice = data?.vehicle?.pricing?.find(
      (price) => price?.type === PRICING_TYPES.fixed
    );
    if (!fixedPrice && data?.vehicle?.pricing) {
      data.vehicle.pricing = [
        ...data.vehicle.pricing,
        {
          id: generateShortId(),
          insert: true,
          type: PRICING_TYPES.fixed,
          monthsDuration: 12,
          signUpFee: null,
          initialPrice: '0.00',
          price: '0.00',
        },
        {
          id: generateShortId(),
          insert: true,
          type: PRICING_TYPES.fixed,
          monthsDuration: 24,
          signUpFee: null,
          price: '0.00',
          initialPrice: '0.00',
        },
      ];
    }
    data.vehicle.skuId = data?.vehicle?.skuId?.slice(2);
    data.vehicle.properties.engine.fuelType = getFuelType(
      data?.enums,
      data?.vehicle?.properties?.engine?.fuelType
    );
    data.vehicle.properties.category = getCategory(
      data?.enums,
      data?.vehicle?.properties?.category
    );
    data.vehicle.properties.engine.euroClass = getEuroClass(
      data?.enums,
      data?.vehicle?.properties?.engine?.euroClass
    );
    data.vehicle.availability = getAvailability(
      data?.enums,
      data?.vehicle?.availability
    );
    data.vehicle.properties.engine.type = getEngineType(
      data?.enums,
      data?.vehicle?.properties?.engine?.type
    );
    data.vehicle.properties.transmission = getTransmission(
      data?.enums,
      data?.vehicle?.properties?.transmission
    );
    data.vehicle.properties.body.type = getBodyType(
      data?.enums,
      data?.vehicle?.properties?.body?.type
    );
    data.vehicle.properties.wheelDrive = getWheelDrives(
      data?.enums,
      data?.vehicle?.properties?.wheelDrive
    );
    data.vehicle.properties.body.size = getBodySize(
      data?.enums,
      data?.vehicle?.properties?.body?.size
    );
    data.vehicle.properties.productLine = getProductLines(
      data?.enums,
      data?.vehicle?.properties?.productLine
    );
    if (data?.vehicle?.properties?.equipment?.comfort) {
      data.vehicle.properties.equipment.comfort['el-GR'] =
        data?.vehicle?.properties?.equipment?.comfort?.['el-GR']?.join('<br>');
      data.vehicle.properties.equipment.comfort['en-US'] =
        data?.vehicle?.properties?.equipment?.comfort?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.properties?.equipment?.exterior) {
      data.vehicle.properties.equipment.exterior['el-GR'] =
        data?.vehicle?.properties?.equipment?.exterior?.['el-GR']?.join('<br>');
      data.vehicle.properties.equipment.exterior['en-US'] =
        data?.vehicle?.properties?.equipment?.exterior?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.properties?.equipment?.interior) {
      data.vehicle.properties.equipment.interior['el-GR'] =
        data?.vehicle?.properties?.equipment?.interior?.['el-GR']?.join('<br>');
      data.vehicle.properties.equipment.interior['en-US'] =
        data?.vehicle?.properties?.equipment?.interior?.['en-US']?.join('<br>');
    }
    if (data?.vehicle?.properties?.equipment?.multimedia) {
      data.vehicle.properties.equipment.multimedia['el-GR'] =
        data?.vehicle?.properties?.equipment?.multimedia?.['el-GR']?.join(
          '<br>'
        );
      data.vehicle.properties.equipment.multimedia['en-US'] =
        data?.vehicle?.properties?.equipment?.multimedia?.['en-US']?.join(
          '<br>'
        );
    }
    if (data?.vehicle?.properties?.equipment?.safety) {
      data.vehicle.properties.equipment.safety['el-GR'] =
        data?.vehicle?.properties?.equipment?.safety?.['el-GR']?.join('<br>');
      data.vehicle.properties.equipment.safety['en-US'] =
        data?.vehicle?.properties?.equipment?.safety?.['en-US']?.join('<br>');
    }

    data.vehicle.specialOffer = data?.vehicle?.tags?.includes('offer');
    data.vehicle.tags = data?.vehicle?.tags?.filter((item) => item !== 'offer');
  } else {
    data.vehicle = INITIAL_VALUES;
  }

  data = { ...data, ...computeModelData(data?.models) };

  return data;
};

export const handleVehiclesBySku = (data = []) =>
  data.map((item) => ({
    id: item.id || '-',
    internalId: item.internalId || '-',
    plate: item.plate || '-',
    brand: item.sku?.brand || '-',
    stage: formatVehicleStage(item.procurement.currentStageId),
    finalPurchasePrice: item.procurement.finalPurchasePrice
      ? formatAmount(item.procurement.finalPurchasePrice.number)
      : '-',
  }));
