import dynamic from 'next/dynamic';

const AddTeamModal = dynamic(() => import('./AddTeamModal/AddTeamModal'));

const TeamsGrid = dynamic(() => import('./TeamsGrid/TeamsGrid'));

const AddTeamForm = dynamic(() => import('./AddTeamForm/AddTeamForm'));

const EditTeamModal = dynamic(() => import('./EditTeamModal/EditTeamModal'));

const EditTeamForm = dynamic(() => import('./EditTeamForm/EditTeamForm'));

const TeamDetails = dynamic(() => import('./TeamDetails/TeamDetails'));

const TeamMembers = dynamic(() => import('./TeamMembers/TeamMembers'));

export {
  TeamsGrid,
  AddTeamModal,
  AddTeamForm,
  EditTeamModal,
  EditTeamForm,
  TeamDetails,
  TeamMembers,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
