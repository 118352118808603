import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRoadsideAssistancesState,
  getRoadsideAssistances,
  deleteRoadsideAssistances,
} from '@store';
import { showError } from '@utils';

export const useRoadsideAssistancesOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    roadsideAssistances,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectRoadsideAssistancesState);
  const {
    push,
    query: { page = 1, orderBy = 'internalId', order = 'asc', token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _deleteRoadsideAssistances = useCallback(
    async (roadsideAssistancesIds) => {
      await dispatch(
        deleteRoadsideAssistances(roadsideAssistancesIds, {
          orderBy,
          order,
          page,
        })
      );
    },
    [dispatch, orderBy, order, page]
  );

  const _getRoadsideAssistances = useCallback(async () => {
    await dispatch(getRoadsideAssistances({ page, order, orderBy }));
  }, [page, dispatch, order, orderBy]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/roadsideAssistance?orderBy=${
        sortingModel?.orderBy || ''
      }&order=${sortingModel?.order || ''}&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/roadsideAssistance?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/roadsideAssistance?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/roadsideAssistance?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getRoadsideAssistances();
    }
  }, [_getRoadsideAssistances, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    roadsideAssistances,
    pageCount,
    hasErrors,
    errorMessage,
    getRoadsideAssistances: _getRoadsideAssistances,
    nextPage: _nextPage,
    deleteRoadsideAssistances: _deleteRoadsideAssistances,
    sortBy: _sortBy,
  };
};
