import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectPermissionsState, getPermissions } from '@store';
import { showError } from '@utils';

export const usePermissionsOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    permissions,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectPermissionsState);
  const {
    push,
    query: { page = 1, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getPermissions = useCallback(
    async (payload = {}) => {
      await dispatch(getPermissions({ page, ...payload }));
    },
    [dispatch, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      if (currentPage === 1) {
        return push('/permissions');
      }
      push(`/permissions?page=${currentPage}`, undefined, { shallow: true });
    },
    [push]
  );

  useEffect(() => {
    if (fetchOnMount) {
      _getPermissions({});
    }
  }, [_getPermissions, token, fetchOnMount]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    permissions,
    pageCount,
    hasErrors,
    errorMessage,
    getPermissions: _getPermissions,
    nextPage: _nextPage,
    token,
    firstItem,
    lastItem,
  };
};
