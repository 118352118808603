import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
} from '@mui/material';
import {
  DynamicForm,
  Button,
  IconButton,
  LocationSearch,
  Dialog,
} from '@components';

import CloseIcon from '@mui/icons-material/Close';

const FormModal = forwardRef(
  (
    {
      id,
      title,
      open,
      loading,
      handleClose,
      onSubmit,
      withCancel,
      withReset,
      formScheme,
      size,
      onBackdropClick,
      withSearchLocation,
      onResetForm,
      onFormStateChange,
      containerStyle,
      submitText,
      withConfirmationDialog,
      onConfirmation,
      location,
      customStyles,
      onAddMore,
      onRemove,
      noFormBorder = true,
      disableLocationSearch = false,
      canMakeValueNull,
      showErrorList = true,
      renderExtraActions,
      validationMode = 'onChange',
      contentStyle,
      formStyles,
    },
    ref
  ) => {
    const [openDialog, setOpenDialog] = useState(false);
    const formRef = useRef(null);

    const _handleClose = (e) => {
      e?.stopPropagation();
      if (handleClose && !withConfirmationDialog) {
        handleClose();
      } else if (withConfirmationDialog) {
        setOpenDialog(true);
      }
    };

    const _handleCloseDialog = (e) => {
      e?.stopPropagation();
      setOpenDialog(false);
    };

    const _onConfirmation = () => {
      if (onConfirmation) {
        onConfirmation();
      }
      _handleCloseDialog();
    };

    const _onSubmit = (form) => {
      if (onSubmit) {
        onSubmit(form);
      }
    };

    const _handleReset = () => {
      formRef.current._reset();
      if (onResetForm) {
        onResetForm();
      }
    };

    const _setValue = (key, value) => {
      formRef?.current?._setValue(key, value);
    };

    const _feelForm = (addressComponents) => {
      Object?.keys(addressComponents)?.forEach((key) => {
        _setValue(key, addressComponents[key]);
      });
    };

    const _initializeAddressForm = (address) => {
      setTimeout(() => {
        formRef?.current?._setValue('streetAddress', address?.streetAddress);
        formRef?.current?._setValue('streetNumber', address?.streetNumber);
        formRef?.current?._setValue('locality', address?.locality);
        formRef?.current?._setValue('postalCode', address?.postalCode);
        formRef?.current?._setValue('region', address?.region);
        formRef?.current?._setValue('country', address?.country);
        formRef?.current?._setValue('latitude', address?.latitude);
        formRef?.current?._setValue('longitude', address?.longitude);
      }, 1);
    };

    useImperativeHandle(ref, () => ({
      _setValue(key, value) {
        formRef?.current?._setValue(key, value);
      },
      _ressetForm() {
        formRef.current._reset();
      },
    }));

    return (
      <div>
        <MuiDialog
          open={open}
          onClose={onBackdropClick}
          scroll="paper"
          fullWidth
          maxWidth={size}
          sx={containerStyle}
        >
          <DialogTitle id="scroll-dialog-title">
            {title}

            <IconButton
              aria-label="close"
              onClick={_handleClose}
              disabled={loading}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={contentStyle || { minHeight: 'auto' }} dividers>
            {withSearchLocation && (
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <LocationSearch
                  callback={_feelForm}
                  location={location}
                  initializeAddressForm={_initializeAddressForm}
                  disabled={disableLocationSearch}
                  canMakeValueNull={canMakeValueNull}
                />
              </Box>
            )}
            <Box
              sx={
                formStyles || {
                  marginTop: '0px',
                }
              }
            >
              <DynamicForm
                id={id || 'editForm'}
                ref={formRef}
                onSubmit={_onSubmit}
                loading={loading}
                formScheme={formScheme}
                defaultValues={{}}
                onFormStateChange={onFormStateChange}
                customStyles={customStyles}
                onAddMore={onAddMore}
                onRemove={onRemove}
                noFormBorder={noFormBorder}
                showErrorList={showErrorList}
                validationMode={validationMode}
              />
            </Box>

            {loading && (
              <Box
                sx={{
                  width: '100%',
                  height: '88%',
                  top: '40px',
                  left: '0px',
                  opacity: 0.9,
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {withReset && (
              <Button color="cancel" onClick={_handleReset} disabled={loading}>
                Reset
              </Button>
            )}
            {renderExtraActions?.()}
            {withCancel && (
              <Button color="cancel" onClick={_handleClose} disabled={loading}>
                Cancel
              </Button>
            )}
            <Button
              color="primary"
              type="submit"
              form={id || 'editForm'}
              loading={loading}
            >
              {submitText || 'Ok'}
            </Button>
          </DialogActions>
        </MuiDialog>
        <Dialog
          title="Discard booking?"
          message="Are you sure you want to discard this booking?"
          onConfirm={_onConfirmation}
          onClose={_handleCloseDialog}
          isOpen={openDialog}
        />
      </div>
    );
  }
);

const memoizedFormModal = React.memo(FormModal);
export { memoizedFormModal as FormModal };

export default memoizedFormModal;
