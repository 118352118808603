import { omit } from 'lodash';

import { formatDate } from '@utils';

export const getVehiclePayload = (values) => {
  const cleanedValues = omit(values, [
    'agreedCustomsDate',
    'estimatedCustomsDate',
    'customsEntryDate',
    'plannedDownpaymentDate',
    'discountPercentage',
    'discountWithoutVat',
    'classificationCosts',
    'agreedPurchasePrice',
    'finalPurchasePrice',
    'buyoutPrice',
    'buyoutMonths',
  ]);
  const payload = {
    ...cleanedValues,
    orderDate: formatDate(values.orderDate, 'YYYY-MM-DD'),
    quantity: Number(values.quantity),
    vat: Number(values.vat),
    tenant: Number(values.tenant),
    procurement: {
      agreedCustomsDate: values.agreedCustomsDate
        ? formatDate(values.agreedCustomsDate, 'YYYY-MM-DD')
        : undefined,
      estimatedCustomsDate: values.estimatedCustomsDate
        ? formatDate(values.estimatedCustomsDate, 'YYYY-MM-DD')
        : undefined,
      customsEntryDate: values.customsEntryDate
        ? formatDate(values.customsEntryDate, 'YYYY-MM-DD')
        : undefined,
      plannedDownpaymentDate: values.plannedDownpaymentDate
        ? formatDate(values.plannedDownpaymentDate, 'YYYY-MM-DD')
        : undefined,
      discountPercentage: values.discountPercentage
        ? Number(values.discountPercentage)
        : null,
      discountWithoutVat: values.discountWithoutVat || undefined,
      classificationCosts: values.classificationCosts || undefined,
      agreedPurchasePrice: values.agreedPurchasePrice || undefined,
      finalPurchasePrice: values.finalPurchasePrice || undefined,
      buyoutPrice: values.buyoutPrice || undefined,
      buyoutMonths: values.buyoutMonths
        ? Number(values.buyoutMonths)
        : undefined,
    },
  };

  return payload;
};

const procurementFields = [
  'sourcingType',
  'orderDate',
  'dealer',
  'agreedCustomsDate',
  'estimatedCustomsDate',
  'supplierCarId',
  'agreedPurchasePrice',
  'customsEntryDate',
  'plannedDownpaymentDate',
  'vehicleOwnership',
  'financingType',
  'financingProvider',
  'retailPrice',
  'retailPriceBeforeTax',
  'vat',
  'extras',
  'extrasPrice',
  'specialClassificationFee',
  'classificationCosts',
  'discountPercentage',
  'discountWithoutVat',
  'finalPurchasePrice',
  'kilometersAtPurchase',
  'leaseMonthlyInstallment',
  'buyOutPrice',
  'buyOutMonths',
  'licenseIssuanceDate',
  'insuranceProvider',
  'insuranceDate',
  'insuranceRenewalDate',
  'vehiclePurchaseDate',
  'leaseStartDate',
  'plannedReceiptDate',
  'receiptDate',
  'currentLocation',
  'defleetedDate',
  'cancelledDate',
  'cancelledDefleetedReason',
  'transferCost',
  'insuranceCancellationDate',
];
const propertiesFields = [
  'transmission',
  'color',
  'roofColor',
  'interiorColor',
  'engine',
  'fuelType',
  'emissions',
  'tireSize',
];
const invoiceDetailsFields = [
  'invoiceNumber',
  'netPrice',
  'registrationTax',
  'invoiceDetailsVat', // vat
  'transferFee',
  'plateReleaseFee',
];
const topLevelFields = ['plate', 'vin', 'condition', 'tenant'];

export const getUpdateVehiclePayload = ({ fieldName, newValue, type }) => {
  if (topLevelFields.includes(fieldName)) {
    return {
      [fieldName]: fieldName === 'tenant' ? Number(newValue) : newValue,
    };
  }
  if (propertiesFields.includes(fieldName)) {
    return {
      properties: {
        [fieldName]: newValue,
      },
    };
  }
  if (invoiceDetailsFields.includes(fieldName)) {
    return {
      invoiceDetails: {
        [fieldName === 'invoiceDetailsVat' ? 'vat' : fieldName]:
          newValue.toString(),
      },
    };
  }
  if (procurementFields.includes(fieldName)) {
    let value = newValue;

    if (type === 'date') {
      value = newValue ? formatDate(newValue, 'YYYY-MM-DD') : undefined;
    }

    if (type === 'number') {
      value = newValue >= 0 ? String(newValue) : undefined;
    }

    return {
      procurement: {
        [fieldName]: value,
      },
    };
  }
  return {};

  // const payload = {
  //   ...cleanedValues,
  //   tenant: Number(fieldData.tenant),
  //   properties: {
  //     color: fieldData.color,
  //   },
  //   procurement: {
  //     agreedCustomsDate: fieldData.agreedCustomsDate
  //       ? formatDate(fieldData.agreedCustomsDate, 'YYYY-MM-DD')
  //       : undefined,
  //     leaseStartDate: fieldData.leaseStartDate
  //       ? formatDate(fieldData.leaseStartDate, 'YYYY-MM-DD')
  //       : undefined,
  //     licenseIssuanceDate: fieldData.licenseIssuanceDate
  //       ? formatDate(fieldData.licenseIssuanceDate, 'YYYY-MM-DD')
  //       : undefined,
  //     insuranceDate: fieldData.insuranceDate
  //       ? formatDate(fieldData.insuranceDate, 'YYYY-MM-DD')
  //       : undefined,
  //     plannedReceiptDate: fieldData.plannedReceiptDate
  //       ? formatDate(fieldData.plannedReceiptDate, 'YYYY-MM-DD')
  //       : undefined,
  //     receiptDate: fieldData.receiptDate
  //       ? formatDate(fieldData.receiptDate, 'YYYY-MM-DD')
  //       : undefined,
  //     vehiclePurchaseDate: fieldData.vehiclePurchaseDate
  //       ? formatDate(fieldData.vehiclePurchaseDate, 'YYYY-MM-DD')
  //       : undefined,
  //     estimatedCustomsDate: fieldData.estimatedCustomsDate
  //       ? formatDate(fieldData.estimatedCustomsDate, 'YYYY-MM-DD')
  //       : undefined,
  //     customsEntryDate: fieldData.customsEntryDate
  //       ? formatDate(fieldData.customsEntryDate, 'YYYY-MM-DD')
  //       : undefined,
  //     plannedDownpaymentDate: fieldData.plannedDownpaymentDate
  //       ? formatDate(fieldData.plannedDownpaymentDate, 'YYYY-MM-DD')
  //       : undefined,
  //     orderDate: fieldData.orderDate
  //       ? formatDate(fieldData.orderDate, 'YYYY-MM-DD')
  //       : undefined,
  //     defleetedDate: fieldData.defleetedDate
  //       ? formatDate(fieldData.defleetedDate, 'YYYY-MM-DD')
  //       : undefined,
  //     dealer: fieldData.dealer,
  //     supplierCarId: fieldData.supplierCarId,
  //     sourcingType: fieldData.sourcingType,
  //     vehicleOwnership: fieldData.vehicleOwnership,
  //     financingType: fieldData.financingType,
  //     FinancingProvider: fieldData.FinancingProvider,
  //     discountWithoutVat: fieldData.discountWithoutVat,
  //     currentLocation: fieldData.currentLocation,
  //     classificationCosts: fieldData.classificationCosts,
  //     // agreedPurchasePrice: fieldData.agreedPurchasePrice.number,
  //     // finalPurchasePrice: fieldData.finalPurchasePrice.number,
  //     // buyoutPrice: fieldData.buyoutPrice,
  //     // retailPrice: fieldData.retailPrice,
  //     retailPriceBeforeTax: fieldData.retailPriceBeforeTax,
  //     // extras: fieldData.extras,
  //     // extrasPrice: fieldData.extrasPrice,
  //     insuranceProvider: fieldData.insuranceProvider,
  //     leaseMonthlyInstallment: fieldData.leaseMonthlyInstallment,
  //     kilometersAtPurchase:
  //       fieldData.kilometersAtPurchase >= 0
  //         ? Number(fieldData.kilometersAtPurchase)
  //         : undefined,
  //     discountPercentage:
  //       fieldData.discountPercentage >= 0
  //         ? Number(fieldData.discountPercentage)
  //         : null,
  //     vat: fieldData.vat >= 0 ? Number(fieldData.vat) : null,
  //     buyoutMonths:
  //       fieldData.buyoutMonths >= 0
  //         ? Number(fieldData.buyoutMonths)
  //         : undefined,
  //   },
  // };

  // return payload;
};
