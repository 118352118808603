import dynamic from 'next/dynamic';

const PermissionsGrid = dynamic(() =>
  import('./PermissionsGrid/PermissionsGrid')
);
const AddPermissionModal = dynamic(() =>
  import('./AddPermissionModal/AddPermissionModal')
);

export { PermissionsGrid, AddPermissionModal };
/* PLOP_INJECT_COMPONENT_EXPORT */
