import dynamic from 'next/dynamic';

const VendorsGrid = dynamic(() => import('./VendorsGrid/VendorsGrid'));
const EditVendorModal = dynamic(() =>
  import('./EditVendorModal/EditVendorModal')
);
const VendorDetails = dynamic(() => import('./VendorDetails/VendorDetails'));

const VendorsProducts = dynamic(() =>
  import('./VendorsProducts/VendorsProducts')
);

const VendorsForm = dynamic(() => import('./VendorsForm/VendorsForm'));
const OptionsForm = dynamic(() => import('./OptionsForm/OptionsForm'));

export {
  VendorsGrid,
  EditVendorModal,
  VendorDetails,
  VendorsProducts,
  VendorsForm,
  OptionsForm,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
