import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  fleetDataGrid: {
    all: {},
    created: {},
    order: {},
    customs: {},
    payment: {},
    dealer: {},
    procurement: {},
    fleet: {},
    cancelled: {},
    defleeted: {},
  },
};
// Actual Slice
export const uiSettingsSlice = createSlice({
  name: 'uiSettings',
  initialState,
  reducers: {
    saveFleetDataGrid: (state, { payload }) => {
      state.fleetDataGrid[payload.stage] = payload.gridState;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const { saveFleetDataGrid } = uiSettingsSlice.actions;

export const saveFleetDataGridState = ({ gridState, stage }) => {
  return async (dispatch) => {
    dispatch(saveFleetDataGrid({ gridState, stage }));
  };
};

export const selectUiSettingsState = (state) => state.uiSettings;
export const selectFleetDataGridState = (state) =>
  state.uiSettings.fleetDataGrid;

export default uiSettingsSlice.reducer;
