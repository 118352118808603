import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeamsState, getTeams } from '@store';
import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';

export const useTeamsOperations = (fetchOnMount) => {
  const { total, pageSize, loading, teams, agents, hasErrors, errorMessage } =
    useSelector(selectTeamsState);
  const {
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'asc', token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getTeams = useCallback(async () => {
    const payload = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
    await dispatch(getTeams({ orderBy, order, page }, payload));
  }, [page, orderBy, order, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/teams?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/teams?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/teams?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/teams?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getTeams();
    }
  }, [_getTeams, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    pageSize,
    loading,
    teams,
    agents,
    hasErrors,
    errorMessage,
    getTeams: _getTeams,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
