import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectLoginState } from '@store';

export const usePermissions = () => {
  const { user } = useSelector(selectLoginState);

  const findEntity = useCallback(
    (entities = []) => {
      if (!entities?.length || !Array.isArray(entities)) return -9999;
      let entity;
      entities?.forEach((item) => {
        if (
          Array.isArray(user?.permissions) &&
          user?.permissions?.includes(item)
        ) {
          entity = item;
        }
      });
      return entity;
    },
    [user]
  );

  return {
    user,
    role: user?.role,
    permissions: user?.permissions,
    findEntity,
  };
};
