import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfigurationState, getConfiguration } from '@store';
import { showError } from '@utils';

export const useConfigurationsOperations = (fetchOnMount) => {
  const { loading, configuration, hasErrors, errorMessage } = useSelector(
    selectConfigurationState
  );

  const dispatch = useDispatch();

  const _getConfiguration = useCallback(async () => {
    await dispatch(getConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (fetchOnMount) {
      _getConfiguration();
    }
  }, [_getConfiguration, fetchOnMount]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    configuration,
    hasErrors,
    errorMessage,
    getConfiguration: _getConfiguration,
  };
};
