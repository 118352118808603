import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEbikesState,
  getEbike,
  addEbike,
  initializePostEbike,
  editEbike,
  deleteEbikeImage,
  deleteEbikeFile,
  getEbikesBySku,
} from '@store';
import { showError } from '@utils';
import { LIMIT } from '@constants';

export const useEbikeOperations = () => {
  const {
    loading,
    ebike,
    hasErrors,
    errorMessage,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
  } = useSelector(selectEbikesState);
  const {
    query: { ebikeId, tab },
  } = useRouter();
  const dispatch = useDispatch();

  const _getEbike = useCallback(async () => {
    await dispatch(getEbike(ebikeId));
    await dispatch(getEbikesBySku(ebikeId));
  }, [dispatch, ebikeId]);

  const _nextPageEbikesBySku = (currentPage) => {
    dispatch(getEbikesBySku(ebikeId, { page: currentPage, pageSize: LIMIT }));
  };
  const _postEbike = useCallback(
    async (payload) => {
      await dispatch(addEbike(payload));
    },
    [dispatch]
  );

  const _deleteEbikeImage = useCallback(
    async (payload) => {
      await dispatch(deleteEbikeImage(payload));
    },
    [dispatch]
  );

  const _deleteEbikeFile = useCallback(
    async (payload) => {
      await dispatch(deleteEbikeFile(payload));
    },
    [dispatch]
  );

  const _editEbike = useCallback(
    async (payload) => {
      await dispatch(editEbike(payload));
    },
    [dispatch]
  );

  const _initializePostEbike = useCallback(async () => {
    await dispatch(initializePostEbike());
  }, [dispatch]);

  useEffect(() => {
    _getEbike();
  }, [ebikeId, _getEbike]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (postErrorMessage || errorMessage) {
      showError(postErrorMessage || errorMessage);
    }
    if (ebike?.ebikesBySku?.errorMessage) {
      showError(ebike.ebikesBySku.errorMessage);
    }
  }, [postErrorMessage, errorMessage, ebike?.ebikesBySku?.errorMessage]);

  return {
    loading,
    ebike,
    hasErrors,
    errorMessage,
    getEbikes: _getEbike,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
    ebikeId,
    postEbike: _postEbike,
    initializePostEbike: _initializePostEbike,
    editEbike: _editEbike,
    deleteEbikeImage: _deleteEbikeImage,
    deleteEbikeFile: _deleteEbikeFile,
    nextPageEbikesBySku: _nextPageEbikesBySku,
    tab,
  };
};
