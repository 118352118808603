import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { insurancesServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { asyncForEach } from '@utils';
import {
  handleInsurancesData,
  handleInsuranceData,
} from './insurances.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  insurances: [],
  insurance: {},
  total: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {
    fetchInsurances: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInsurancesSuccess: (state, { payload }) => {
      state.insurances = handleInsurancesData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInsurancesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchInsurance: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInsuranceSuccess: (state, { payload }) => {
      state.insurance = handleInsuranceData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchInsuranceFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchInsurances,
  fetchInsurancesSuccess,
  fetchInsurancesFailure,
  fetchInsurance,
  fetchInsuranceSuccess,
  fetchInsuranceFailure,
} = insurancesSlice.actions;

export const selectInsurancesState = (state) => state.insurances;

export const getInsurances = (payload) => {
  return async (dispatch) => {
    dispatch(fetchInsurances());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await insurancesServices?.getInsurances(payload);
      response.meta.page = page;

      dispatch(fetchInsurancesSuccess(response));
    } catch (error) {
      dispatch(fetchInsurancesFailure(error));
    }
  };
};

export const deleteInsurances = (payload, page) => {
  return async (dispatch) => {
    dispatch(fetchInsurances());

    try {
      await asyncForEach(payload, async (insuranceId) => {
        await insurancesServices?.deleteInsurance(insuranceId);
      });

      dispatch(getInsurances(page));
    } catch (error) {
      dispatch(fetchInsurancesFailure(error));
    }
  };
};

export const getInsurance = (id) => {
  return async (dispatch) => {
    dispatch(fetchInsurance());

    try {
      const response = await insurancesServices?.getInsurance(id);

      dispatch(fetchInsuranceSuccess(response));
    } catch (error) {
      dispatch(fetchInsuranceFailure(error));
    }
  };
};

export default insurancesSlice.reducer;
