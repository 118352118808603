export const computePayload = (data) => {
  if (data.specialOffer) {
    if (!data.tags.includes('offer')) {
      data.tags.push('offer');
    }
  } else {
    data.tags = data?.tags?.filter((tag) => tag !== 'offer') || [];
  }

  return {
    skuId: `IC${data?.skuId}`,
    seoFakeVin: data.seoFakeVin,
    properties: {
      brand: data.properties.brandSlug,
      model: data.properties.modelSlug,
      edition: data.properties.edition,
      year: Number(data.properties.year),
      engine: {
        displacement: Number(data.properties.engine.displacement),
        powerHp: Number(data.properties.engine.powerHp),
        fuelType: Number(data.properties.engine.fuelType),
        engineType: Number(data.properties.engine.type),
        fuelEconomy: Number(data.properties.engine.fuelEconomy),
        fuelCapacity: Number(data.properties.engine.fuelCapacity),
        emissions: Number(data.properties.engine.emissions),
        topSpeed: data.properties.engine.topSpeed
          ? parseInt(data.properties.engine?.topSpeed, 10)
          : 0,
        torque: data.properties.engine.torque
          ? parseInt(data.properties.engine.torque, 10)
          : 0,
        euroClass: data.properties.engine.euroClass
          ? parseInt(data.properties.engine.euroClass, 10)
          : 0,
        autonomy: Number(data.properties.engine.autonomy),
        batteryChargeTime: data.properties.engine.batteryChargeTime
          ? Number(data.properties.engine.batteryChargeTime)
          : 0,
        batterySize: data.properties.engine.batterySize,
        acceleration: Number(data.properties.engine.acceleration),
        numberOfGears: Number(data.properties.engine.numberOfGears),
      },
      body: {
        type: Number(data.properties.body.type),
        size: Number(data.properties.body.size) || null,
        seatsNumber: Number(data.properties.body.seatsNumber),
        doorsNumber: Number(data.properties.body.doorsNumber),
        airbagsNumber: Number(data.properties.body.airbagsNumber),
        height: Number(data.properties.body.height),
        weight: Number(data.properties.body.weight),
        interiorType: data.properties.body.interiorType || null,
        trunkCapacity: data.properties.body.trunkCapacity
          ? Number(data.properties.body.trunkCapacity)
          : 0,
        length: data.properties.body.length
          ? parseInt(data.properties.body.length, 10)
          : 0,
        width: data.properties.body.width
          ? parseInt(data.properties.body.width, 10)
          : 0,
      },
      transmission: Number(data.properties.transmission),
      wheelDrive: Number(data.properties.wheelDrive),
      category: Number(data.properties.category),
      productLine: Number(data.properties.productLine),
      tireSize: data.properties.tireSize,
      // maintenanceSchedules: _maintenanceSchedules(data),
      equipment: {
        multimedia: {
          'el-GR': data?.properties?.equipment?.multimedia?.['el-GR']
            ? data?.properties?.equipment?.multimedia?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.properties?.equipment?.multimedia?.['en-US']
            ? data?.properties?.equipment?.multimedia?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        exterior: {
          'el-GR': data?.properties?.equipment?.exterior?.['el-GR']
            ? data?.properties?.equipment?.exterior?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.properties?.equipment?.exterior?.['en-US']
            ? data?.properties?.equipment?.exterior?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        interior: {
          'el-GR': data?.properties?.equipment?.interior?.['el-GR']
            ? data?.properties?.equipment?.interior?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.properties?.equipment?.interior?.['en-US']
            ? data?.properties?.equipment?.interior?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        comfort: {
          'el-GR': data?.properties?.equipment?.comfort?.['el-GR']
            ? data?.properties?.equipment?.comfort?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.properties?.equipment?.comfort?.['en-US']
            ? data?.properties?.equipment?.comfort?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        safety: {
          'el-GR': data?.properties?.equipment?.safety?.['el-GR']
            ? data?.properties?.equipment?.safety?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.properties?.equipment?.safety?.['en-US']
            ? data?.properties?.equipment?.safety?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
      },
    },
    instastart: data.instastart || null,
    pricePerKm: data.pricePerKm?.value,
    monthlyKm: Number(data.monthlyKilometers),
    tags: data.tags || [],
    additionalInfo: {
      'el-GR': data?.additionalInfo?.['el-GR'] || '',
      'en-US': data?.additionalInfo?.['en-US'] || '',
    },
    published: data.published,
    vendors: data.vendor ? [data.vendor] : [],
    colors: [],
    cities: null,
    km: null,
    retailPrice: data.retailPrice || null,
    instacarPrice: data.instacarPrice || null,
    videoUrl: data.videoUrl,
    specialOffer: data.specialOffer,
    comments: data.comments,
  };
};
