import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectErrorsState, getErrors } from '@store';
import { showError } from '@utils';

export const useErrorOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    errors,
    pageCount,
    hasErrors,
    errorMessage,
  } = useSelector(selectErrorsState);
  const {
    push,
    query: { page = 1, token },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getErrors = useCallback(async () => {
    await dispatch(getErrors({ page }));
  }, [page, dispatch]);

  const _nextPage = useCallback(
    (currentPage) => {
      if (currentPage === 1) {
        return push('/errors');
      }
      push(`/errors?page=${currentPage}`, undefined, { shallow: true });
    },
    [push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getErrors();
    }
  }, [_getErrors, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    errors,
    pageCount,
    hasErrors,
    errorMessage,
    getErrors: _getErrors,
    nextPage: _nextPage,
    token,
  };
};
