import { request } from '@utils';

const report = async (type) => {
  const params = {
    type,
    responseType: 'arraybuffer',
  };
  const results = await request.get(`/v1/pipedrive/events/export`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { report };
