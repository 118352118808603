/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommunicationState, getEmail } from '@store';
import { showError } from '@utils';
import { agentsServices, communicationServices } from '@services';

export const useEmailOperations = (firstMount) => {
  const { loading, email, getEmailhasErrors, getEmailErrorMessage, history } =
    useSelector(selectCommunicationState);
  const [assignees, setAssignees] = useState([]);
  const {
    back,
    query: { emailId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getEmail = useCallback(async () => {
    if (emailId) {
      await dispatch(getEmail(emailId));
    }
  }, [emailId, dispatch]);

  const _initialize = async () => {
    try {
      await communicationServices.editEmailById(emailId, { read: true });
      await _getEmail();
    } catch (error) {
      showError(error);
    }
  };

  const _fetchAssignees = async () => {
    try {
      const { data } = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      setAssignees(data);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (emailId && firstMount) {
      _initialize();
      _fetchAssignees();
    }
  }, [emailId, _getEmail]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (getEmailErrorMessage) {
      showError(getEmailErrorMessage);
    }
  }, [getEmailErrorMessage]);

  return {
    loading,
    emailId,
    email,
    assignees,
    getEmailhasErrors,
    getEmailErrorMessage,
    getEmail: _getEmail,
    goBack: back,
    history,
  };
};
