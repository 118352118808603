import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectServicePointsState,
  getServicePoints,
  deleteServicePoints,
  getServicePointsEnums,
  activateDeactivateServicePoints,
} from '@store';
import { showError } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';

export const useServicePointsOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    servicePoints,
    pageCount,
    hasErrors,
    errorMessage,
    enums,
  } = useSelector(selectServicePointsState);
  const {
    push,
    query: {
      page = 1,
      orderBy = 'internalId',
      order = 'asc',
      token,
      ...others
    },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _deleteServicePoints = useCallback(
    async (servicePointsIds) => {
      await dispatch(
        deleteServicePoints(servicePointsIds, { orderBy, order, page })
      );
    },
    [dispatch, orderBy, order, page]
  );

  const _getServicePoints = useCallback(async () => {
    let payload = localStorage.servicePointsQuery;
    let { servicePointsFilterRules } = localStorage;
    if (servicePointsFilterRules) {
      servicePointsFilterRules = JSON.parse(servicePointsFilterRules);
    } else {
      servicePointsFilterRules = [];
    }
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = [...payload.value, ...servicePointsFilterRules];
      } else if (servicePointsFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: servicePointsFilterRules,
        };
      }
    } else if (!payload && servicePointsFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: servicePointsFilterRules,
      };
    } else {
      payload = {
        type: FILTER_RULE_ENUMS.EmptyRuleType,
      };
    }

    await dispatch(getServicePoints({ page, orderBy, order, ...payload }));
  }, [page, dispatch, orderBy, order]);

  const _getServicePointsEnums = useCallback(async () => {
    await dispatch(getServicePointsEnums());
  }, [dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/servicePoints?page=${page}&${_otherQueries}`;
      if (sortingModel?.orderBy || sortingModel?.order) {
        url = `${url}&orderBy=${sortingModel?.orderBy || ''}&order=${
          sortingModel?.order || ''
        }`;
      }
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/servicePoints?page=${page}&${_otherQueries}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page, _otherQueries]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/servicePoints?page=${currentPage}&${_otherQueries}`;

      if (orderBy || order) {
        url = `${url}&orderBy=${orderBy || ''}&order=${order || ''}`;
      }
      if (!Object.keys(others).length && (!orderBy || !order)) {
        url = `/servicePoints?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push, _otherQueries, others]
  );

  const _activateDeactivateServicePoints = useCallback(
    async (payload) => {
      await dispatch(activateDeactivateServicePoints(payload));
      _getServicePoints();
    },
    [dispatch, _getServicePoints]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getServicePoints();
      _getServicePointsEnums();
    }
  }, [_getServicePoints, _getServicePointsEnums, token, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    loading,
    servicePoints,
    pageCount,
    hasErrors,
    errorMessage,
    enums,
    getServicePoints: _getServicePoints,
    nextPage: _nextPage,
    sortBy: _sortBy,
    deleteServicePoints: _deleteServicePoints,
    activateDeactivateServicePoints: _activateDeactivateServicePoints,
  };
};
