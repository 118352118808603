import { omit } from 'lodash';

export const getArnmMaintenanceTaskPayload = (values) => {
  const clearableFields = ['dot', 'tires'];

  Object.keys(values)?.forEach((key) => {
    if (values[key] === '' && !clearableFields.includes(key)) {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'documents',
    'tags',
    'status',
    'updatedAt',
    'updatedBy',
    'changelog',
    'images',
    'parent',
    'name',
    'createdAt',
    'comments',
    'createdBy',
    'servicePoint',
    'subscriptionId',
    'customFields.vehicleDescription',
    'customFields.vehicleImage',
    'customFields.vehicleOwner',
    'customFields.vehiclePlate',
  ]);

  return {
    assignee: values.assignee?.value,
    customFields: {
      ...cleanedValues.customFields,
      servicePoint: values.customFields.servicePoint?.value,
      expectedOutFromServicePoint:
        values.customFields.expectedOutFromServicePoint || null,
    },
  };
};
