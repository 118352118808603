import { useEffect, useState } from 'react';

export const useKanbanVisibility = (initialKanBanColumns) => {
  const [kanbanColumns, setKanbanColumns] = useState(initialKanBanColumns);
  const [kanbanFilteredColumns, setKanbanFilteredColumns] =
    useState(initialKanBanColumns);

  const handleKanbanVisibility = (column) => {
    // reset columns functionality
    if (!column) {
      setKanbanColumns(initialKanBanColumns);
      setKanbanFilteredColumns(initialKanBanColumns);
      return;
    }

    const columnStatus = kanbanColumns.map((col) => {
      return {
        ...col,
        value: col.key === column.key ? !col.value : col.value,
      };
    });

    setKanbanColumns(columnStatus);
    setKanbanFilteredColumns(columnStatus.filter((col) => col.value));
  };

  useEffect(() => {
    setKanbanColumns(initialKanBanColumns);
    setKanbanFilteredColumns(initialKanBanColumns);
  }, [initialKanBanColumns]);

  return { handleKanbanVisibility, kanbanColumns, kanbanFilteredColumns };
};

export default useKanbanVisibility;
