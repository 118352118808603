import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { communicationServices } from '@services';
import { FILTER_RULE_ENUMS, GENERIC_ERROR_MESSAGE } from '@constants';
import {
  handleCommunicationData,
  handleMarkAsReadUnread,
  handleMoveToTeam,
  handleSetStatus,
  handleAssignPerson,
  handleEmail,
  handleHistory,
  handleMailBoxes,
} from './communication.handlers';

export const COMMUNICATION_EMAIL_LIMIT = 10;

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  communicationEmails: [],
  mailBoxes: [],
  mailBoxesLoaded: false,
  email: {},
  history: [],
  totals: {},
  total: 0,
  pageCount: 0,
  pageSize: COMMUNICATION_EMAIL_LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
  page: 0,
  loaded: false,
};
// Actual Slice
export const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    fetchCommunication: (state, { payload }) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      if (payload === 1) {
        state.communicationEmails = [];
      }
    },
    fetchMailboxesOptionsSuccess: (state, { payload }) => {
      state.mailBoxes = handleMailBoxes(payload);
      state.mailBoxesLoaded = true;
    },
    initializeMailboxes: (state) => {
      state.mailBoxesLoaded = false;
    },
    fetchCommunicationSuccess: (state, { payload }) => {
      state.communicationEmails = handleCommunicationData(
        payload?.response.data
      );
      state.totals = payload?.response?.meta;
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.loaded = true;
      state.page = payload?.response?.meta?.page;
      state.pageCount =
        payload?.response?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.response?.meta?.total || 1) / state.pageSize);
    },
    appendCommunicationSuccess: (state, { payload }) => {
      state.communicationEmails = [
        ...state.communicationEmails,
        ...handleCommunicationData(payload?.response.data),
      ];
      state.totals = payload?.response?.meta;
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.page = payload?.response?.meta?.page;
      state.pageCount =
        payload?.response?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.response?.meta?.total || 1) / state.pageSize);
    },
    getTotalsSuccess: (state, { payload }) => {
      state.totals = payload?.response?.meta;
    },
    fetchCommunicationFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    markAsReadUnreadSuccess: (state, { payload }) => {
      state.communicationEmails = handleMarkAsReadUnread(
        state.communicationEmails,
        payload?.emailIds,
        payload?.action?.read
      );
    },
    moveToTeamSuccess: (state, { payload }) => {
      state.loading = false;
      state.communicationEmails = handleMoveToTeam(
        state.communicationEmails,
        payload?.emailIds,
        payload?.action?.team
      );
    },
    setStatusSuccess: (state, { payload }) => {
      state.loading = false;
      state.communicationEmails = handleSetStatus(
        state.communicationEmails,
        payload?.emailIds,
        payload?.action?.status
      );
    },
    assignPersonSuccess: (state, { payload }) => {
      state.loading = false;
      state.communicationEmails = handleAssignPerson(
        state.communicationEmails,
        payload?.emailIds,
        payload?.action?.assignee
      );
    },
    fetchEmail: (state) => {
      state.loading = true;
      state.getEmailhasErrors = false;
      state.getEmailErrorMessage = '';
      state.email = {};
      state.history = [];
    },
    fetchEmailSuccess: (state, { payload }) => {
      state.email = handleEmail(payload?.email?.data);
      state.history = handleHistory(payload?.history?.data);
      state.loading = false;
    },
    fetchEmailFailure: (state, { payload }) => {
      state.loading = false;
      state.getEmailhasErrors = true;
      state.getEmailErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    initializeLoad: (state) => {
      state.loaded = false;
      state.mailBoxesLoaded = false;
      state.mailBoxes = [];
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchCommunication,
  fetchCommunicationSuccess,
  fetchCommunicationTaskSuccess,
  fetchCommunicationFailure,
  appendCommunicationSuccess,
  getTotalsSuccess,
  markAsReadUnreadSuccess,
  moveToTeamSuccess,
  setStatusSuccess,
  assignPersonSuccess,
  fetchEmail,
  fetchEmailSuccess,
  fetchEmailFailure,
  initializeLoad,
  fetchMailboxesOptionsSuccess,
  initializeMailboxes,
} = communicationSlice.actions;

export const selectCommunicationState = (state) => state.communication;

export const getMailboxes = (params) => {
  return async (dispatch) => {
    const payload = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
    try {
      dispatch(fetchCommunication(params?.page));
      const { data } = await communicationServices.getEmailBoxes(
        {
          orderBy: 'createdAt',
          order: 'desc',
          page: 1,
          pageSize: 1000,
        },
        payload
      );

      dispatch(fetchMailboxesOptionsSuccess(data));
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export const getCommunication = (params, payload) => {
  return async (dispatch) => {
    const page = parseInt(params?.page, 10);
    try {
      dispatch(fetchCommunication(params?.page));
      const response = await communicationServices.getEmails({
        params,
        payload,
      });

      response.meta.page = page;
      if (page !== 1) {
        dispatch(appendCommunicationSuccess({ response, payload }));
      } else {
        dispatch(fetchCommunicationSuccess({ response, payload }));
      }
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export const getTotals = (params, payload) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCommunication());
      const response = await communicationServices.getEmails({
        params,
        payload,
      });

      dispatch(getTotalsSuccess({ response }));
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export const getEmail = (emailId) => {
  return async (dispatch) => {
    dispatch(fetchEmail());

    try {
      const email = await communicationServices.getEmailById(emailId);
      const history = await communicationServices.getEmailHistoryById(
        email?.data?.id
      );
      dispatch(fetchEmailSuccess({ email, history }));
    } catch (error) {
      dispatch(fetchEmailFailure(error));
    }
  };
};

export const markAsReadUnread = (payload) => {
  return async (dispatch) => {
    dispatch(fetchCommunication());

    try {
      await communicationServices.editEmails(payload);
      dispatch(markAsReadUnreadSuccess(payload));
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export const moveToTeam = (payload) => {
  return async (dispatch) => {
    dispatch(fetchCommunication());

    try {
      await communicationServices.editEmails(payload);
      dispatch(moveToTeamSuccess(payload));
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export const setStatus = (payload) => {
  return async (dispatch) => {
    dispatch(fetchCommunication());

    try {
      await communicationServices.editEmails(payload);
      dispatch(setStatusSuccess(payload));
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export const assignPerson = (payload) => {
  return async (dispatch) => {
    dispatch(fetchCommunication());

    try {
      await communicationServices.editEmails(payload);
      dispatch(assignPersonSuccess(payload));
    } catch (error) {
      dispatch(fetchCommunicationFailure(error));
    }
  };
};

export default communicationSlice.reducer;
