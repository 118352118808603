import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';

export const getReservationPayload = (filters = {}) => {
  let payload = {};
  const dropdownReservationsFilterRules = [];

  dropdownReservationsFilterRules.push({
    field: 'isActive',
    operator: 0,
    type: 5,
    value: true,
  });

  if (filters?.type) {
    dropdownReservationsFilterRules.push({
      field: 'type',
      operator: 0,
      type: 5,
      value: Number(filters.type),
    });
  } else {
    dropdownReservationsFilterRules.push({
      type: 5,
      field: 'type',
      operator: FILTER_OPERATOR_ENUMS['!='],
      value: 5, // != Booked
    });
  }
  if (filters?.agent) {
    dropdownReservationsFilterRules.push({
      field: 'agent',
      operator: 0,
      type: 5,
      value: filters.agent,
    });
  }
  if (filters?.subscriptionType) {
    dropdownReservationsFilterRules.push({
      field: 'subscriptionType',
      operator: 0,
      type: 5,
      value: Number(filters.subscriptionType),
    });
  }

  if (filters?.searchTerm) {
    const filedsToSearch = [
      'vehicleInternalId',
      'vehiclePlate',
      'subscriptionId',
      'vehicleName',
    ];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: filters?.searchTerm,
      })),
    };
    dropdownReservationsFilterRules.push(searchRule);
  }

  payload = {
    type: 0,
    field: null,
    operator: null,
    value: dropdownReservationsFilterRules,
  };
  return payload;
};
