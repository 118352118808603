import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { vendorsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';

import { handleVendors, handleVendor } from './vendors.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  vendors: [],
  vendor: {},
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    fetchVendors: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchVendorsSuccess: (state, { payload }) => {
      state.vendors = handleVendors(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.vendors.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.vendors?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchVendorsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchVendor: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchVendorSuccess: (state, { payload }) => {
      state.vendor = handleVendor(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchVendorFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchVendors,
  fetchVendorsSuccess,
  fetchVendorsFailure,
  fetchVendor,
  fetchVendorSuccess,
  fetchVendorFailure,
} = vendorsSlice.actions;

export const selectVendorsState = (state) => state.vendors;

export default vendorsSlice.reducer;

export const getVendors = (payload) => {
  return async (dispatch) => {
    dispatch(fetchVendors());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await vendorsServices?.getVendors(payload);
      response.meta.page = page;

      dispatch(fetchVendorsSuccess(response));
    } catch (error) {
      dispatch(fetchVendorsFailure(error));
    }
  };
};

export const getVendor = (id) => {
  return async (dispatch) => {
    dispatch(fetchVendor());

    try {
      const vendor = await vendorsServices?.getVendorById(id);
      const products = await vendorsServices?.getProductsByVendorId(
        vendor?.data?.id
      );
      dispatch(
        fetchVendorSuccess({
          details: vendor.data,
          products: products?.data,
        })
      );
    } catch (error) {
      dispatch(fetchVendorFailure(error));
    }
  };
};
