import { PROCUREMENT_STAGES } from '@constants';

export const getTabs = (stageId) => [
  {
    label: 'Overview',
    key: 'overview',
  },
  {
    label: 'Service Points',
    key: 'service-points',
    disabled: stageId > PROCUREMENT_STAGES.FLEET,
  },
  {
    label: 'Changelog',
    key: 'changelog',
  },
  {
    label: 'Car History',
    key: 'car-history',
  },
];
