import { useDispatch, useSelector } from 'react-redux';
import { setTenant, selectLayoutState } from '@store';

export const useLayout = () => {
  const { tenant } = useSelector(selectLayoutState);
  const dispatch = useDispatch();

  const _setTenant = (value) => {
    dispatch(setTenant(value));
  };

  return {
    tenant,
    setTenant: _setTenant,
  };
};
