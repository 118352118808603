export const computePayloadEbike = (data) => {
  if (data.specialOffer) {
    if (!data.tags.includes('offer')) {
      data.tags.push('offer');
    }
  } else {
    data.tags = data?.tags?.filter((tag) => tag !== 'offer') || [];
  }

  return {
    skuId: `ICB${data?.skuId}`,
    seoFakeVin: data.seoFakeVin,
    properties: {
      brand: data.properties.brandSlug,
      model: data.properties.modelSlug,
      edition: data.properties.edition,
      year: Number(data.properties.year),
      engine: {
        powerHp: Number(data.properties.engine.powerHp),
        fuelType: Number(data.properties.engine.fuelType),
        speed: data.properties.engine.speed
          ? parseInt(data.properties.engine?.speed, 10)
          : 0,
        torque: data.properties.engine.torque
          ? parseInt(data.properties.engine.torque, 10)
          : 0,
        batterySize: data.properties.engine.batterySize,
        numberOfGears: Number(data.properties.engine.numberOfGears),
      },
      body: {
        type: Number(data.properties.body.type),
        size: Number(data.properties.body.size) || null,
        weight: Number(data.properties.body.weight),
      },
      category: Number(data.properties.category),
      productLine: Number(data.properties.productLine),
      tireSize: data.properties.tireSize,
    },
    instastart: data.instastart,
    pricePerKm: data.pricePerKm?.value,
    monthlyKm: Number(data.monthlyKilometers),
    tags: data.tags || [],
    additionalInfo: {
      'el-GR': data.additionalInfo['el-GR'],
      'en-US': data.additionalInfo['en-US'],
    },
    published: data.published,
    vendors: data.vendor ? [data.vendor] : [],
    colors: [],
    cities: null,
    km: null,
    retailPrice: data.retailPrice,
    instacarPrice: data.instacarPrice,
    videoUrl: data.videoUrl,
    specialOffer: data.specialOffer,
    comments: data.comments,
    availability: data.availability,
  };
};
