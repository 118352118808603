import { request } from '@utils';

const getNotifications = async (payload) => {
  const results = await request.get(`/v1/notifications`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const notificationsAreDeleted = async (data) => {
  const payload = {
    body: data,
  };

  const results = await request.post(`/v1/notifications/delete`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const notificationIsViewed = async (data) => {
  const payload = {
    body: data,
  };

  const results = await request.post(`/v1/notifications/read`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getNotifications, notificationIsViewed, notificationsAreDeleted };
