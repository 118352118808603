import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { driversServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { handleHandoveredCars } from './handoverCars.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  vehicles: [],
  groups: {},
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  page: 1,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const handoverCarsSlice = createSlice({
  name: 'handoveredCars',
  initialState,
  reducers: {
    fetchHandoveredCars: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchHandoveredCarsSuccess: (state, { payload }) => {
      state.vehicles = payload?.handover?.data || [];
      state.groups = handleHandoveredCars(
        payload?.handover?.data,
        payload?.groupType
      );
      state.page = payload?.handover?.meta?.page || 1;
      state.total = payload?.handover?.meta?.total || 0;
      state.pageCount =
        payload?.handover?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.handover?.meta?.total || 1) / state.pageSize);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchHandoveredCarsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchMoreHandoveredCarsSuccess: (state, { payload }) => {
      const stateItems = state.vehicles;

      state.vehicles = [...stateItems, ...payload.handover.data];
      state.groups = handleHandoveredCars(
        [...stateItems, ...payload.handover.data],
        payload?.groupType
      );
      state.page = payload?.handover?.meta?.page || 1;
      state.total = payload?.handover?.meta?.total || 0;
      state.pageCount =
        payload?.handover?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.handover?.meta?.total || 1) / state.pageSize);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    initState: (state) => {
      state.groups = {};
      state.vehicles = [];
      state.page = 1;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchHandoveredCars,
  fetchHandoveredCarsSuccess,
  fetchHandoveredCarsFailure,
  fetchMoreHandoveredCarsSuccess,
  changePage,
  initState,
} = handoverCarsSlice.actions;

export const selectHandoveredCarsState = (state) => state.handoveredCars;

export const getHandoveredCars = (payload) => {
  return async (dispatch) => {
    dispatch(fetchHandoveredCars());
    const page = parseInt(payload?.page, 10);
    const handoverType = payload?.handoverType;
    payload.pageSize = 100;
    try {
      const response = await driversServices?.getHandoveredCars(payload);
      response.meta.page = page;

      dispatch(
        fetchHandoveredCarsSuccess({
          handover: response,
          groupType: handoverType === 0 ? 'deliveryDate' : 'returnDate',
        })
      );
    } catch (error) {
      dispatch(fetchHandoveredCarsFailure(error));
    }
  };
};

export const getMoreHandoveredCars = (payload) => {
  return async (dispatch) => {
    dispatch(fetchHandoveredCars());
    const page = parseInt(payload?.page, 10);
    const handoverType = payload?.handoverType;
    try {
      const response = await driversServices?.getHandoveredCars(payload);
      response.meta.page = page;

      dispatch(
        fetchMoreHandoveredCarsSuccess({
          handover: response,
          groupType: handoverType === 0 ? 'deliveryDate' : 'returnDate',
        })
      );
    } catch (error) {
      dispatch(fetchHandoveredCarsFailure(error));
    }
  };
};

export default handoverCarsSlice.reducer;
