import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getReturnTask, selectReturnsInboxState } from '@store';
import { showError } from '@utils';

export const useReturnOperations = () => {
  const { returnTask, subTasks, loading, hasErrors, errorMessage } =
    useSelector(selectReturnsInboxState);
  const {
    query: { returnsInboxId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getReturnTask = useCallback(async () => {
    await dispatch(getReturnTask(returnsInboxId));
  }, [returnsInboxId, dispatch]);

  useEffect(() => {
    if (returnsInboxId !== undefined) {
      _getReturnTask();
    }
  }, [returnsInboxId, _getReturnTask, token]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    task: returnTask,
    taskId: returnsInboxId,
    subTasks,
    hasErrors,
    errorMessage,
    getReturnTask: _getReturnTask,
    token,
  };
};
