import dynamic from 'next/dynamic';

const CommunicationView = dynamic(() =>
  import('./CommunicationView/CommunicationView')
);
const CommunicationSideBar = dynamic(() =>
  import('./CommunicationSideBar/CommunicationSideBar')
);
const CommunicationFilters = dynamic(() =>
  import('./CommunicationFilters/CommunicationFilters')
);
const EmailView = dynamic(() => import('./EmailView/EmailView'));
const Attachment = dynamic(() => import('./Attachment/Attachment'));
const TeamChip = dynamic(() => import('./TeamChip/TeamChip'));
const AssigneeChip = dynamic(() => import('./AssigneeChip/AssigneeChip'));
const StatusChip = dynamic(() => import('./StatusChip/StatusChip'));
const EmailToolbar = dynamic(() => import('./EmailToolbar/EmailToolbar'));
const MarkAsReadUnread = dynamic(() =>
  import('./MarkAsReadUnread/MarkAsReadUnread')
);
const MoveToTeam = dynamic(() => import('./MoveToTeam/MoveToTeam'));
const AssignPerson = dynamic(() => import('./AssignPerson/AssignPerson'));
const CommunicationViewDetails = dynamic(() =>
  import('./CommunicationViewDetails/CommunicationViewDetails')
);
const EmailViewDetail = dynamic(() =>
  import('./EmailViewDetail/EmailViewDetail')
);
const EmailDetailsToolbar = dynamic(() =>
  import('./EmailDetailsToolbar/EmailDetailsToolbar')
);
const Sender = dynamic(() => import('./Sender/Sender'));
const EmailReply = dynamic(() => import('./EmailReply/EmailReply'));
const SenderDetails = dynamic(() => import('./SenderDetails/SenderDetails'));
const MarkAsReadUnreadSingle = dynamic(() =>
  import('./MarkAsReadUnreadSingle/MarkAsReadUnreadSingle')
);
const EmailChangelog = dynamic(() => import('./EmailChangelog/EmailChangelog'));

export {
  CommunicationView,
  CommunicationSideBar,
  CommunicationFilters,
  EmailView,
  Attachment,
  TeamChip,
  AssigneeChip,
  StatusChip,
  EmailToolbar,
  MarkAsReadUnread,
  MoveToTeam,
  AssignPerson,
  CommunicationViewDetails,
  EmailViewDetail,
  EmailDetailsToolbar,
  Sender,
  EmailReply,
  SenderDetails,
  MarkAsReadUnreadSingle,
  EmailChangelog,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
