import * as Sentry from '@sentry/nextjs';
import { omit } from 'lodash';

import { authHeader } from '@utils';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { ENTITIES_PERMISIONS_NAMES } from 'components/common/PermissionsGate/constants';

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
};

const generateErrorResponse = (response) => {
  return response.text().then((text) => {
    try {
      const data = JSON.parse(text);
      let message = data?.error || GENERIC_ERROR_MESSAGE;
      if (response.status === 403) {
        // in case of a non permited access, the response is the id of the
        // missing permission
        message = `Your role is missing a permission [${
          ENTITIES_PERMISIONS_NAMES[data?.data] || ''
        }]. Contact an administrator.`;
      }
      Sentry.captureException(new Error(message));
      return {
        error: true,
        code: response?.status,
        message,
      };
    } catch (err) {
      const message = response?.statusText || GENERIC_ERROR_MESSAGE;
      Sentry.captureException(new Error(message));

      const error = {
        error: true,
        code: response?.status,
        message,
      };

      return error;
    }
  });
};

const handleResponse = async (response, responseType) => {
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      if (window.location.pathname !== '/login') {
        window.location.replace(`${window.location.origin}/login`);
      }
      return;
    }
    return generateErrorResponse(response);
  }
  if (responseType === 'arraybuffer') {
    // Handle Bloβ pdf response
    return response.arrayBuffer();
  }
  if (responseType === 'text') {
    // Handle text/csv response
    return response.text();
  }
  return response.text().then((text) => {
    try {
      const data = JSON.parse(text);
      if (!data.error) {
        return data;
      }
      return generateErrorResponse(response);
    } catch (err) {
      // console.log('Request parseError', err);
      return text.arrayBuffer;
    }
  });
};

const request = async (url, params, method = 'GET') => {
  const options = {
    method,
  };
  if (params?.responseType) {
    options.responseType = params.responseType;
  }
  if (params?.header) {
    options.headers = authHeader(params?.header);
  } else {
    options.headers = authHeader({ 'Content-Type': 'application/json' });
  }
  if (params) {
    if (method === 'GET') {
      url += `?${objectToQueryString(omit(params, ['responseType']))}`;
    }
    if (options?.headers?.Accept) {
      options.body = params.body;
    } else {
      options.body = JSON.stringify(params.body);
    }
  }
  if (method === 'POST' && !options.body) {
    options.body = {};
  }

  const response = await fetch(url, options);

  return handleResponse(response, params?.responseType);
};

const get = async (url, params) => {
  return request(url, params);
};

const post = async (url, params) => {
  return request(url, params, 'POST');
};

const put = async (url, params) => {
  return request(url, params, 'PUT');
};

const patch = async (url, params) => {
  return request(url, params, 'PATCH');
};

const remove = async (url, params) => {
  return request(url, params, 'DELETE');
};

const methods = {
  get,
  post,
  put,
  patch,
  remove,
};

export default methods;
