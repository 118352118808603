export const consumerDepositsAveragePaymentsOptions = [
  { text: '100 | 0-5', value: 100 },
  { text: '80 | 6-10', value: 80 },
  { text: '60 | 11-15', value: 60 },
  { text: '30 | 16-30', value: 30 },
  { text: '0 | 31+', value: 0 },
];

export const consumerDepositsAgeOptions = [
  { text: '25 | 0-90', value: 25 },
  { text: '50 | 91-180', value: 50 },
  { text: '75 | 181-365', value: 75 },
  { text: '100 | 366-730', value: 100 },
];

export const consumerDepositsTurnoverOptions = [
  { text: '0 | 0-1000', value: 0 },
  { text: '20 | 1000-2500', value: 20 },
  { text: '40 | 2500-5000', value: 40 },
  { text: '60 | 5000-7500', value: 60 },
  { text: '80 | 7500-10000', value: 80 },
  { text: '90 | 10000-15000', value: 90 },
  { text: '100 | 15000+', value: 100 },
];

export const consumerDepositsNumberOfVehiclesOptions = [
  { text: '30 | 1', value: 30 },
  { text: '70 | 2', value: 70 },
  { text: '100 | 3+', value: 100 },
];

export const consumerDepositsUnpaidAmountOptions = [
  { text: '100 | 0-10', value: 100 },
  { text: '90 | 10.01-250', value: 90 },
  { text: '75 | 251-500', value: 75 },
  { text: '50 | 501-1000', value: 50 },
  { text: '25 | 1001-3000', value: 25 },
  { text: '10 | 3001-5000', value: 10 },
  { text: '0 | 5000+', value: 0 },
];

export const consumerDepositsUnpaidAgingDaysOptions = [
  { text: '100 | 0-7', value: 100 },
  { text: '50 | 8-15', value: 50 },
  { text: '0 | 16+', value: 0 },
];

export const consumerStandingOrderAveragePaymentsOptions = [
  { text: '100 | 0-5', value: 100 },
  { text: '80 | 6-10', value: 80 },
  { text: '60 | 11-15', value: 60 },
  { text: '30 | 16-30', value: 30 },
  { text: '0 | 31+', value: 0 },
];

export const consumerStandingOrderAgeOptions = [
  { text: '25 | 0-90', value: 25 },
  { text: '50 | 91-180', value: 50 },
  { text: '75 | 181-365', value: 75 },
  { text: '100 | 366-730', value: 100 },
];

export const consumerStandingOrderTurnoverOptions = [
  { text: '0 | 0-1000', value: 0 },
  { text: '20 | 1000-2500', value: 20 },
  { text: '40 | 2500-5000', value: 40 },
  { text: '60 | 5000-7500', value: 60 },
  { text: '80 | 7500-10000', value: 80 },
  { text: '90 | 10000-15000', value: 90 },
  { text: '100 | 15000+', value: 100 },
];

export const consumerStandingOrderNumberOfVehiclesOptions = [
  { text: '30 | 1', value: 30 },
  { text: '70 | 2', value: 70 },
  { text: '100 | 3+', value: 100 },
];

export const consumerStandingOrderUnpaidAmountOptions = [
  { text: '100 | 0-10', value: 100 },
  { text: '90 | 10.01-250', value: 90 },
  { text: '75 | 251-500', value: 75 },
  { text: '50 | 501-1000', value: 50 },
  { text: '25 | 1001-3000', value: 25 },
  { text: '10 | 3001-5000', value: 10 },
  { text: '0 | 5000+', value: 0 },
];

export const consumerStandingOrderUnpaidAgingDaysOptions = [
  { text: '100 | 0-7', value: 100 },
  { text: '50 | 8-15', value: 50 },
  { text: '0 | 16+', value: 0 },
];

export const consumerStandingOrderSuccessRateFixedAssetsOptions = [
  { text: '100 | 91-100', value: 100 },
  { text: '75 | 81-90', value: 75 },
  { text: '50 | 66-80', value: 50 },
  { text: '25 | 51-65', value: 25 },
  { text: '0 | 0-50', value: 0 },
];

export const businessDepositsAveragePaymentsOptions = [
  { text: '100 | 0-5', value: 100 },
  { text: '80 | 6-10', value: 80 },
  { text: '60 | 11-15', value: 60 },
  { text: '30 | 16-30', value: 30 },
  { text: '0 | 31+', value: 0 },
];

export const businessDepositsAgeOptions = [
  { text: '25 | 0-90', value: 25 },
  { text: '50 | 91-180', value: 50 },
  { text: '75 | 181-365', value: 75 },
  { text: '100 | 366-730', value: 100 },
];

export const businessDepositsTurnoverOptions = [
  { text: '0 | 0-3000', value: 0 },
  { text: '20 | 3001-5000', value: 20 },
  { text: '40 | 5001-10000', value: 40 },
  { text: '60 | 10001-15000', value: 60 },
  { text: '70 | 15001-25000', value: 70 },
  { text: '80 | 25001-35000', value: 80 },
  { text: '90 | 35001-50000', value: 90 },
  { text: '95 | 50001-100000', value: 95 },
  { text: '100 | 100000+', value: 100 },
];

export const businessDepositsNumberOfVehiclesOptions = [
  { text: '10 | 1', value: 10 },
  { text: '30 | 2-3', value: 30 },
  { text: '50 | 4-5', value: 50 },
  { text: '75 | 6-7', value: 75 },
  { text: '100 | 7+', value: 100 },
];

export const businessDepositsUnpaidAmountOptions = [
  { text: '100 | 0-10', value: 100 },
  { text: '90 | 10.01-250', value: 90 },
  { text: '75 | 251-500', value: 75 },
  { text: '50 | 501-1000', value: 50 },
  { text: '25 | 1001-3000', value: 25 },
  { text: '10 | 3001-5000', value: 10 },
  { text: '0 | 5000+', value: 0 },
];

export const businessDepositsUnpaidAgingDaysOptions = [
  { text: '100 | 0-7', value: 100 },
  { text: '50 | 8-15', value: 50 },
  { text: '0 | 16+', value: 0 },
];

export const businessStandingOrderAveragePaymentsOptions = [
  { text: '100 | 0-5', value: 100 },
  { text: '80 | 6-10', value: 80 },
  { text: '60 | 11-15', value: 60 },
  { text: '30 | 16-30', value: 30 },
  { text: '0 | 31+', value: 0 },
];

export const businessStandingOrderAgeOptions = [
  { text: '25 | 0-90', value: 25 },
  { text: '50 | 91-180', value: 50 },
  { text: '75 | 181-365', value: 75 },
  { text: '100 | 366-730', value: 100 },
];

export const businessStandingOrderTurnoverOptions = [
  { text: '0 | 0-3000', value: 0 },
  { text: '20 | 3001-5000', value: 20 },
  { text: '40 | 5001-10000', value: 40 },
  { text: '60 | 10001-15000', value: 60 },
  { text: '70 | 15001-25000', value: 70 },
  { text: '80 | 25001-35000', value: 80 },
  { text: '90 | 35001-50000', value: 90 },
  { text: '95 | 50001-100000', value: 95 },
  { text: '100 | 100000+', value: 100 },
];

export const businessStandingOrderNumberOfVehiclesOptions = [
  { text: '10 | 1', value: 10 },
  { text: '30 | 2-3', value: 30 },
  { text: '50 | 4-5', value: 50 },
  { text: '75 | 6-7', value: 75 },
  { text: '100 | 7+', value: 100 },
];

export const businessStandingOrderUnpaidAmountOptions = [
  { text: '100 | 0-50', value: 100 },
  { text: '90 | 50-200', value: 90 },
  { text: '75 | 200-500', value: 75 },
  { text: '50 | 501-1000', value: 50 },
  { text: '25 | 1001-3000', value: 25 },
  { text: '10 | 3001-5000', value: 10 },
  { text: '0 | 5000+', value: 0 },
];

export const businessStandingOrderUnpaidAgingDaysOptions = [
  { text: '100 | 0-7', value: 100 },
  { text: '50 | 8-15', value: 50 },
  { text: '0 | 16+', value: 0 },
];

export const businessStandingOrderSuccessRateFixedAssetsOptions = [
  { text: '100 | 91-100', value: 100 },
  { text: '75 | 81-90', value: 75 },
  { text: '50 | 66-80', value: 50 },
  { text: '25 | 51-65', value: 25 },
  { text: '0 | 0-50', value: 0 },
];
