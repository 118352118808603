/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSellRequestsState,
  getSellRequests,
  selectSellRequestsColumnsState,
} from '@store';
import { showError, request } from '@utils';
import { boardsServices } from '@services';

export const useSellRequestsOperations = (fetchOnMount) => {
  const { total, loading, sellRequests, hasErrors, errorMessage } = useSelector(
    selectSellRequestsState
  );
  const columns = useSelector(selectSellRequestsColumnsState);

  const {
    isReady,
    push,
    query: { page = 1, orderBy = '', order = '', token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getSellRequests = useCallback(
    async (payload = {}) => {
      await dispatch(getSellRequests({ ...payload, orderBy, order, page }));
    },
    [page, orderBy, order, dispatch]
  );

  const _addTask = useCallback(
    async (payload = {}) => {
      const data = {
        body: payload,
      };
      const response = await request.post(`/v1/www/vehicle-sales`, data);

      if (response?.error) {
        showError(response?.message);
      }
      await _getSellRequests();
    },
    [sellRequests]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload?.id, payload);
        }
      } catch (error) {
        showError(error);
      }
    },
    [sellRequests]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/sellRequests?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/sellRequests?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/sellRequests?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/sellRequests?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getSellRequests();
      }
    }, 100);
  }, [page, token, _getSellRequests, fetchOnMount, isReady]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    sellRequests,
    columns,
    hasErrors,
    errorMessage,
    addTask: _addTask,
    updateTask: _updateTask,
    getSellRequests: _getSellRequests,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
