import { AGENT_ROLES } from '@constants';
import { rolesOptions } from '../rolesOptions';

export const editAgentModalForm = {
  sections: [
    {
      title: '',
      fontWeight: 'bold',
      sectionWrapperStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        width: '100%',
      },
      rows: {
        title: '',
        rowWrapperStyle: {
          width: '100%',
        },
        data: [
          {
            title: '',
            columnWrapperStyle: {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginBottom: 3,
              gap: 2,
            },
            data: [
              {
                firstName: {
                  label: 'First Name',
                  type: 'text',
                  placeholder: '',
                  defaultValue: '',
                  rules: {
                    required: true,
                  },
                  fieldWrapperStyle: {
                    width: '100%',
                  },
                },
                lastName: {
                  label: 'Last Name',
                  type: 'text',
                  placeholder: '',
                  defaultValue: '',
                  rules: {
                    required: '* This field is required',
                  },
                  fieldWrapperStyle: {
                    width: '100%',
                  },
                },
              },
              {
                email: {
                  label: 'Email',
                  type: 'text',
                  placeholder: '',
                  defaultValue: '',
                  rules: {
                    required: '* This field is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  },
                  fieldWrapperStyle: {
                    width: '100%',
                  },
                },
                role: {
                  label: 'Role',
                  type: 'dropdown',
                  placeholder: '',
                  defaultValue: AGENT_ROLES.isCustomerCareAgent,
                  rules: {
                    required: '* This field is required',
                  },
                  options: rolesOptions?.map((item) => {
                    return { text: item?.label, value: item?.value };
                  }),
                  fieldWrapperStyle: {
                    width: '100%',
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ],
};
