const exportFile = (file, filetype, filename) => {
  const blob = new Blob([file], { type: filetype });
  const downloadLink = document.createElement('a');

  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(blob);
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export default exportFile;
