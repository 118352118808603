export const searchVatForm = {
  sections: [
    {
      title: '',
      fontWeight: 'bold',
      sectionWrapperStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        wordWrap: 'break-word',
        backgroundColor: '#fff',
      },
      rows: {
        title: '',
        rowWrapperStyle: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        },
        data: [
          {
            title: '',
            columnWrapperStyle: {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: 2,
            },
            data: [
              {
                vat: {
                  label: '',
                  type: 'text',
                  placeholder: 'Input VAT number',
                  defaultValue: '',
                  rules: {
                    required: '* This field is required',
                    minLength: {
                      value: 9,
                      message: '* Please fill 9 digits',
                    },
                  },
                  columnWrapperStyle: {},
                  inputWrapperStyle: {
                    width: '400px',
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ],
};
