import { formatDate, generateShortId } from '@utils';

export const handleServicePointsData = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      status: item.status,
      email: item?.email || '-',
      internalId: item?.internalId || '-',
      name: { name: item.name, isCertified: item.partnershipInfo.isCertified },
      slug: item?.slug || '-',
      telephoneNumber: item?.telephoneNumber || '-',
      id: item?.id,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
      primaryContactName:
        item?.contacts?.find((contact) => contact?.isPrimary)?.fullName || '-',
      primaryContactNumber:
        item?.contacts?.find((contact) => contact?.isPrimary)?.phoneNumber[0]
          ?.phoneNumber || '-',
      region: item?.addresses?.[0]?.locality || '-',
      workdays: item?.workdays || [],
    };
    newArray[index] = row;

    return newArray;
  });
  return array;
};

export const handleServicePointData = (item) => {
  if (!item?.details?.id) {
    return {};
  }
  let servicePoint = {};
  if (item?.details?.id) {
    servicePoint = {
      email: {
        value: item?.details?.email || '-',
      },
      internalId: {
        value: item?.details?.internalId || '-',
        name: 'internalId',
      },
      name: {
        value: item?.details?.name || '-',
        name: 'name',
      },
      slug: {
        value: item?.details?.slug || '-',
      },
      telephoneNumber: {
        value: item?.details?.telephoneNumber || '-',
        name: 'telephoneNumber',
      },
      id: item?.details?.id,
      createdAt: {
        value: item?.details?.createdAt
          ? formatDate(item?.details?.createdAt, 'DD/MM/YYYY HH:mm')
          : '-',
      },
      updatedAt: {
        value: item?.details?.updatedAt
          ? formatDate(item?.details?.updatedAt, 'DD/MM/YYYY HH:mm')
          : '-',
      },
      isCertified: item?.details?.partnershipInfo?.isCertified || false,
      supportedManufacturers: {
        value: item?.details?.supportedManufacturers || [],
        name: 'supportedManufacturers',
      },
      supportedWorkTypes: {
        value: item?.details?.supportedWorkTypes || [],
        name: 'supportedWorkTypes',
      },
      supportedFuelTypes: {
        value: item?.details?.supportedFuelTypes || [],
        name: 'supportedFuelTypes',
      },
      supportedInsurances: {
        value: item?.details?.supportedInsurances || [],
        name: 'supportedInsurances',
      },
      supportedProviders: {
        value: item?.details?.supportedProviders || [],
        name: 'supportedProviders',
      },
      workdays: {
        value: item?.details?.workdays || [],
        name: 'workdays',
      },
      supportedBanks: {
        value: item?.details?.billingData?.bank || '-',
        name: 'supportedBanks',
      },
      bankIban: {
        value: item?.details?.billingData?.bankIban || '-',
        name: 'bankIban',
      },
      contacts:
        item?.details?.contacts?.map((con) => {
          return {
            ...con,
            phoneNumber: con?.phoneNumber[0],
            phoneNumberSecondary: con?.phoneNumber[1],
          };
        }) || [],
      partnershipInfo: item?.details?.partnershipInfo || {},
    };
  } else {
    servicePoint = {};
  }
  if (item?.addresses?.length) {
    servicePoint.addresses = item?.addresses?.map((address) => {
      return {
        country: address?.country || '-',
        createdAt: address?.createdAt
          ? formatDate(address?.createdAt, 'DD/MM/YYYY')
          : '-',
        id: address?.id,
        latitude: address?.latitude || 0,
        locality: address?.locality || '-',
        longitude: address?.longitude || 0,
        postalCode: address?.postalCode || '-',
        region: address?.region || '-',
        streetAddress: address?.streetAddress || '-',
        streetNumber: address?.streetNumber || '-',
        updatedAt: address?.updatedAt
          ? formatDate(address?.updatedAt, 'DD/MM/YYYY')
          : '-',
      };
    });
  } else {
    servicePoint.addresses = [];
  }
  return servicePoint;
};

export const handleServicePointsEventsData = (array) => {
  if (!array) {
    return [];
  }

  const events = [];
  array?.forEach((event) => {
    events?.push({
      ...event,
      id: generateShortId(),
    });
  });

  return events;
};
