const groupItems = (array, property, order) => {
  const results = array.reduce((groups, item) => {
    const name = item[property];
    const group = groups[name] || (groups[name] = []);
    group.push(item);
    return groups;
  }, {});

  if (order) {
    return Object.keys(results)
      .sort((a, b) => {
        // Convert the dates to a comparable format (YYYYMMDD)
        const aComparable = a.split('.').reverse().join('');
        const bComparable = b.split('.').reverse().join('');

        if (order === 'desc') {
          return bComparable.localeCompare(aComparable);
        }
        return aComparable.localeCompare(bComparable);
      })
      .reduce((acc, key) => {
        acc[key] = results[key];
        return acc;
      }, {});
  }
  return results;
};

export default groupItems;
