export const RETURN_TASK_STATUSES_NAMES = {
  0: 'Pending Returns',
  1: 'For Return',
  2: 'Check list',
  3: 'Done',
};

export const RETURN_LABEL_FIELD_NAMES = {
  2: 'sddssd',
};

export const RETURN_TASK_FIELD_NAMES = {
  name: 'Title',
  subscriberName: 'Subscriber Name',
  subscriberSurname: 'Subscriber Surname',
  promisedReturnDate: 'Promised Return Date',
  plannedUserReturnDate: 'Planned User Return Date',
  dateAndTime: 'Date & Time',
  userId: 'User ID',
  carId: 'Car ID',
  pipedriveId: 'Pipedrive ID',
  trelloBillingCardId: 'Trello Billing Card ID',
  vehicleModel: 'Vehicle Model',
  vehicleBrand: 'Vehicle Brand',
  trelloDriversCardId: 'Trello Drivers Card ID',
  time: 'Time of Return',
  trelloOpsCardId: 'Τrello Ops Card ID',
  location: 'Location of Return',
  plate: 'Plate',
  label: 'Tags',
  status: 'Status',
  tags: 'Tag',
  returnedDate: 'Return Date',
  billingComments: 'Billing Comments',
  billingChargeType: 'Charge',
};

export const RETURN_TASK_LABELS_NAMES = {
  1: 'Return',
  2: 'Instadelivery no Fee',
  3: 'Pick & Del',
  4: 'Port',
  5: 'Carrier',
  6: 'Pre-delivery',
  7: 'Temporary',
  8: 'Carswap',
  9: 'Carswap / Pre-del',
  10: 'Carswap / Temporary',
};

export const RETURN_TASK_BILLING_CHARGE_TYPE = {
  0: 'Proportional charge',
  1: 'Standard charge',
};
