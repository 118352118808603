import { LIMIT } from '@constants';
import { request } from '@utils';

const getPermissions = async (payload) => {
  const params = {
    ...payload,
    pageSize: LIMIT,
  };
  const results = await request.get(`/v2/permissions`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updatePermissions = async (id, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/agents/${id}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const createPermission = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v2/permissions`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deletePermission = async (id) => {
  const results = await request.remove(`/v2/permissions/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportFile = async (type) => {
  const results = await request.get(`/v2/permissions/export`, {
    type,
    responseType: 'text',
  });

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getPermissions,
  updatePermissions,
  createPermission,
  deletePermission,
  exportFile,
};
