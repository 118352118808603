import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSellVehiclesState,
  getSellVehicle,
  getLeaseVehicle,
  addSellVehicle,
  resetSellVehicle,
  editSellVehicle,
  deleteSellVehicleImage,
  deleteSellVehicleFile,
} from '@store';
import { showError } from '@utils';

export const useSellVehicleOperations = () => {
  const {
    loading,
    vehicle,
    hasErrors,
    errorMessage,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
  } = useSelector(selectSellVehiclesState);
  const {
    query: { sellVehicleId, tab },
  } = useRouter();
  const dispatch = useDispatch();

  const _getSellVehicle = useCallback(async () => {
    await dispatch(getSellVehicle(sellVehicleId));
  }, [dispatch, sellVehicleId]);

  const _getLeaseVehicle = useCallback(
    async (id) => {
      const res = await dispatch(getLeaseVehicle(id));
      return res;
    },
    [dispatch]
  );

  const _postSellVehicle = useCallback(
    async (payload) => {
      await dispatch(addSellVehicle(payload));
    },
    [dispatch]
  );

  const _deleteSellVehicleImage = useCallback(
    async (payload) => {
      await dispatch(deleteSellVehicleImage(payload));
    },
    [dispatch]
  );

  const _deleteSellVehicleFile = useCallback(
    async (payload) => {
      await dispatch(deleteSellVehicleFile(payload));
    },
    [dispatch]
  );

  const _editSellVehicle = useCallback(
    async (payload) => {
      await dispatch(editSellVehicle(payload));
    },
    [dispatch]
  );

  const _resetSellVehicle = useCallback(async () => {
    dispatch(resetSellVehicle());
  }, [dispatch]);

  useEffect(() => {
    _getSellVehicle();
  }, [_getSellVehicle]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (postErrorMessage || errorMessage) {
      showError(postErrorMessage || errorMessage);
    }
  }, [postErrorMessage, errorMessage]);

  return {
    loading,
    vehicle,
    hasErrors,
    errorMessage,
    getSellVehicle: _getSellVehicle,
    postLoading,
    postHasErrors,
    postErrorMessage,
    postSuccess,
    vehicleId: sellVehicleId,
    postSellVehicle: _postSellVehicle,
    resetSellVehicle: _resetSellVehicle,
    editSellVehicle: _editSellVehicle,
    deleteSellVehicleImage: _deleteSellVehicleImage,
    deleteSellVehicleFile: _deleteSellVehicleFile,
    tab,
    getLeaseVehicle: _getLeaseVehicle,
  };
};
