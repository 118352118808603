import React from 'react';
import { remoteConfig } from 'config/firebase';
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import { showError } from '@utils';

const FlagsContext = React.createContext({});

if (remoteConfig) {
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 1800000, // 30 mins
  };
}

const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = React.useState(defaults);

  React.useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const data = {};
        const remoteFlags = getAll(remoteConfig);
        Object.entries(remoteFlags).forEach(([key, value]) => {
          data[key] =
            process.env.DISABLE_REMOTE_CONFIG !== 'true' ? value._value : true;
        });

        setFlags(data);
        localStorage.setItem('firebaseFlags', JSON.stringify(data));
      })
      .catch((error) => showError(error));
  }, []);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

const useFlags = () => {
  const context = React.useContext(FlagsContext);
  return context;
};

export { FlagsProvider, useFlags };
