import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { configurationServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { handleConfigurationData } from './configuration.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  configuration: [],
  total: 0,
  pageCount: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    fetchConfiguration: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchConfigurationSuccess: (state, { payload }) => {
      state.configuration = handleConfigurationData(payload?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchConfigurationFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchConfiguration,
  fetchConfigurationSuccess,
  fetchConfigurationFailure,
} = configurationSlice.actions;

export const selectConfigurationState = (state) => state.configuration;

export const getConfiguration = () => {
  return async (dispatch) => {
    dispatch(fetchConfiguration());

    try {
      const response = await configurationServices?.getConfigurations();

      dispatch(fetchConfigurationSuccess(response));
    } catch (error) {
      dispatch(fetchConfigurationFailure(error));
    }
  };
};

export default configurationSlice.reducer;
