import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { showError } from '@utils';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: 'instacar-app.firebaseapp.com',
  projectId: 'instacar-app',
  storageBucket: 'instacar-app.appspot.com',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const signInWithGoogle = async (loginToServer) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);

    if (loginToServer) {
      loginToServer(res?._tokenResponse?.oauthIdToken);
    }
  } catch (err) {
    showError(err.message);
  }
};

export const logout = () => {
  signOut(auth);
};

export const remoteConfig =
  typeof window !== 'undefined' && getRemoteConfig(app);
