import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectServiceVehiclesState,
  selectAgentsState,
  getServiceVehicle,
  getAgents,
  serviceVehiclesTypes,
} from '@store';
import { showError } from '@utils';

export const useServiceVehicleOperations = () => {
  const { total, loading, serviceVehicle, hasErrors, errorMessage } =
    useSelector(selectServiceVehiclesState);
  const {
    agents,
    hasErrors: agentsError,
    errorMessage: agentsErrorMessage,
  } = useSelector(selectAgentsState);
  const {
    query: { serviceVehicleId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getServiceVehicle = useCallback(async () => {
    await dispatch(getServiceVehicle(serviceVehicleId));
    await dispatch(getAgents({ pageSize: 100 }));
  }, [serviceVehicleId, dispatch]);

  useEffect(() => {
    if (serviceVehicleId !== undefined) {
      _getServiceVehicle();
    }
  }, [serviceVehicleId, _getServiceVehicle, token]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    total,
    loading,
    serviceVehicle,
    serviceVehicleId,
    agents,
    agentsError,
    agentsErrorMessage,
    hasErrors,
    errorMessage,
    getServiceVehicle: _getServiceVehicle,
    token,
    serviceVehiclesTypes,
  };
};
