import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubscriptionsState, getSubscription } from '@store';
import { showError } from '@utils';

export const useSubscriptionOperations = (firstMount) => {
  const {
    loading,
    subscription,
    getSubScriptionhasErrors,
    getSubscriptionErrorMessage,
  } = useSelector(selectSubscriptionsState);
  const {
    back,
    query: { subscriptionId, step = 0 },
  } = useRouter();
  const dispatch = useDispatch();

  const _getSubscription = useCallback(async () => {
    if (subscriptionId) {
      await dispatch(getSubscription(subscriptionId, true));
    }
  }, [subscriptionId, dispatch]);

  useEffect(() => {
    if (subscriptionId && firstMount) {
      _getSubscription();
    }
  }, [subscriptionId, _getSubscription, firstMount]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (getSubscriptionErrorMessage) {
      showError(getSubscriptionErrorMessage);
    }
  }, [getSubscriptionErrorMessage]);

  return {
    loading,
    step,
    subscriptionId,
    subscription,
    getSubScriptionhasErrors,
    getSubscriptionErrorMessage,
    getSubscription: _getSubscription,
    goBack: back,
  };
};
