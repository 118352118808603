/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersState, getOrder } from '@store';
import { showError } from '@utils';

export const useOrderOperations = (firstMount) => {
  const {
    loading,
    order,
    transactions,
    getOrderhasErrors,
    getOrderErrorMessage,
  } = useSelector(selectOrdersState);
  const {
    back,
    query: { orderId },
  } = useRouter();
  const dispatch = useDispatch();

  const _getOrder = useCallback(async () => {
    if (orderId) {
      await dispatch(getOrder(orderId));
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId && firstMount) {
      _getOrder();
    }
  }, [orderId, _getOrder]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
  }, []);

  useEffect(() => {
    if (getOrderErrorMessage) {
      showError(getOrderErrorMessage);
    }
  }, [getOrderErrorMessage]);

  return {
    loading,
    orderId,
    order,
    transactions,
    getOrderhasErrors,
    getOrderErrorMessage,
    getOrder: _getOrder,
    goBack: back,
  };
};
