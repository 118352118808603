import { request } from '@utils';
import { LIMIT } from '@constants';

const getSellVehicles = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'skuId';
  }

  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}&order=${payload?.order}&orderBy=${payload?.orderBy}`;
  delete payload?.pageSize;
  delete payload?.page;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/vehicle-sales/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSellVehicle = async (id) => {
  const results = await request.get(`/v1/vehicle-sales/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSellVehicleEnums = async () => {
  const results = await request.get(`/v1/catalog/vehicles/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postSellVehicle = async (params) => {
  const payload = {
    body: params,
  };
  const results = await request.post(`/v1/vehicle-sales`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editSellVehicleImage = async (
  skuId,
  imageId,
  imageTag,
  caption,
  description,
  order
) => {
  const body = {
    body: {
      tags: imageTag,
      caption,
      description,
      order,
    },
  };
  const results = await request.patch(
    `/v1/catalog/vehicles/${skuId}/images/${imageId}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postSellVehicleImage = async (
  skuId,
  file,
  imageTag,
  caption,
  description,
  order
) => {
  const fd = new FormData();
  fd.append('images', file);
  fd.append('tags', imageTag);
  fd.append('caption', caption);
  fd.append('description', description);
  fd.append('order', order);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/images`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postSellVehicleFile = async (skuId, file) => {
  const fd = new FormData();
  fd.append('specs_sheet', file);
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/docs`,
    body
  );
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return results;
};

const updateSellVehicle = async (id, body) => {
  const params = {
    body,
  };
  const results = await request.patch(`/v1/vehicle-sales/${id}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteSellVehicleImage = async (payload) => {
  const results = await request.remove(
    `/v1/catalog/vehicles/${payload?.skuId}/images/${payload?.id}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteSellVehicleFile = async (payload) => {
  const results = await request.remove(
    `/v1/catalog/vehicles/${payload?.skuId}/docs/${payload?.id}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addSellPricing = async (skuId, payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/pricing`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateSellPricing = async (skuId, pricingId, body) => {
  const params = {
    body,
  };
  const results = await request.patch(
    `/v1/catalog/vehicles/${skuId}/pricing/${pricingId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const postSellVehiclePrimaryImage = async (skuId, imageId) => {
  const results = await request.post(
    `/v1/catalog/vehicles/${skuId}/images/${imageId}/select`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSellVehicleColors = async () => {
  const results = await request.get(`/v1/vehicles/config/exterior-colors`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSellVehicleProviders = async () => {
  const results = await request.get(`/v1/vehicles/config/providers`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getSellVehicleDealers = async () => {
  const results = await request.get(`/v1/vehicles/config/dealers`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results?.data;
};

const getSellVehiclesModels = async () => {
  const results = await request.get(`/v2/vehicles/config/models`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getSellVehicles,
  getSellVehicle,
  getSellVehicleEnums,
  postSellVehicle,
  postSellVehicleImage,
  editSellVehicleImage,
  updateSellVehicle,
  deleteSellVehicleImage,
  postSellVehicleFile,
  deleteSellVehicleFile,
  addSellPricing,
  updateSellPricing,
  postSellVehiclePrimaryImage,
  getSellVehicleColors,
  getSellVehicleProviders,
  getSellVehiclesModels,
  getSellVehicleDealers,
};
