import dynamic from 'next/dynamic';

const BookingsGrid = dynamic(() => import('./BookingsGrid/BookingsGrid'));

const BookingsFilter = dynamic(() => import('./BookingsFilter/BookingsFilter'));
const BookingModal = dynamic(() => import('./BookingModal/BookingModal'));
const BookingForm = dynamic(() => import('./BookingForm/BookingForm'));
const BookingsHeaderOptions = dynamic(() =>
  import('./BookingsHeaderOptions/BookingsHeaderOptions')
);

export {
  BookingsGrid,
  BookingModal,
  BookingsFilter,
  BookingForm,
  BookingsHeaderOptions,
};
/* PLOP_INJECT_COMPONENT_EXPORT */
