import { formatDate } from '@utils';

export const handleAgentsData = (data) => {
  if (!data) {
    return [];
  }

  data?.forEach((item, index, newArray) => {
    const row = {
      status: item.status || '-',
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      email: item?.email || '-',
      firstName: item?.firstName || '-',
      lastName: item?.lastName || '-',
      fullName: `${item?.firstName || ''} ${item?.lastName || ''}`,
      role: item?.role || '-',
      id: item.id,
      userName: item.username || '-',
    };
    newArray[index] = row;

    return newArray;
  });
  return data;
};
