/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddFleetVehicleState,
  initializeAddFleetVehicleState,
  getFleetSourcingTypes,
  getFleetDealers,
  getFleetColors,
} from '@store';

import { showError } from '@utils';

export const useAddFleetVehicleOperations = () => {
  const { loading, hasErrors, errorMessage, colors, dealers, sourcingTypes } =
    useSelector(selectAddFleetVehicleState);

  const dispatch = useDispatch();

  const _getFleetSourcingTypes = useCallback(async () => {
    await dispatch(getFleetSourcingTypes());
  }, [dispatch]);

  const _getFleetColors = useCallback(async () => {
    await dispatch(getFleetColors());
  }, [dispatch]);

  const _getFleetDelears = useCallback(async () => {
    await dispatch(getFleetDealers());
  }, [dispatch]);

  useEffect(() => {
    sessionStorage.lastRoute = window.location.pathname;
    _getFleetSourcingTypes();
    _getFleetColors();
    _getFleetDelears();

    return () => {
      dispatch(initializeAddFleetVehicleState());
    };
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    hasErrors,
    errorMessage,
    colors,
    dealers,
    sourcingTypes,
  };
};
