import { request } from '@utils';
import { RESERVATION_LIMIT } from '@store';

const getReservations = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = RESERVATION_LIMIT;
  }

  const queryParams = `?page=${payload.page}&pageSize=${payload.pageSize}&order=${payload.order}&orderBy=${payload.orderBy}`;
  delete payload?.page;
  delete payload?.pageSize;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/reservations/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getReservationById = async (reservationId) => {
  const results = await request.get(`/v1/reservations/${reservationId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getReservationsEnums = async () => {
  const results = await request.get(`/v1/reservations/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addReservation = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/reservations`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateReservation = async (reservationId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/reservations/${reservationId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getReservationsHistoryById = async (id) => {
  const results = await request.get(
    `/v1/changelogs?entityType=reservation&entityID=${id}&orderBy=createdAt&order=desc`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportFile = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = RESERVATION_LIMIT;
  }

  const queryParams = `?page=${payload.page}&pageSize=${payload.pageSize}&order=${payload.order}&orderBy=${payload.orderBy}&type=csv`;
  delete payload?.page;
  delete payload?.pageSize;
  delete payload?.order;
  delete payload?.orderBy;

  const body = {
    body: payload,
    responseType: 'text',
  };
  const results = await request.post(
    `/v1/reservations/export${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getReservations,
  getReservationById,
  getReservationsEnums,
  addReservation,
  updateReservation,
  getReservationsHistoryById,
  exportFile,
};
