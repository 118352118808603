import { request } from '@utils';

const ocr = async (type, files) => {
  const fd = new FormData();
  fd.append('type', type);
  files?.forEach((file) => {
    fd.append('document', file);
  });
  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(`/v1/utilities/ocr`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVAT = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/utilities/vat`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { ocr, getVAT };
