import { formatDate } from '@utils';

export const handleInsurancesData = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const row = {
      email: item?.email || '-',
      internalId: item?.internalId || '-',
      name: item?.name || '-',
      telephoneNumber: item?.telephoneNumber || '-',
      id: item?.id,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
    };
    newArray[index] = row;

    return newArray;
  });
  return array;
};

export const handleInsuranceData = (item) => {
  if (!item) {
    return {};
  }
  const insurance = {
    email: item?.email || '-',
    internalId: item?.internalId || '-',
    name: item?.name || '-',
    phoneNumber: item?.telephoneNumber || '-',
    id: item?.id,
    createdAt: item?.createdAt
      ? formatDate(item?.createdAt, 'DD/MM/YYYY HH:mm')
      : '-',
    updatedAt: item?.updatedAt
      ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
      : '-',
  };
  return insurance;
};
