import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  graphServices,
  fleetVehiclesServices,
  vehiclesServices,
} from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

import {
  handleAvailabilities,
  handleConfigData,
  handleTimeRange,
} from './availability.handlers';
// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  availabilities: [],
  timeRange: {},
  pageSize: 100,
};
// Actual Slice
export const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    fetchAvailabilities: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.vehicle = null;
      state.availabilities = [];
      state.availabilities = [];
    },
    fetchAvailabilitiesSuccess: (state, { payload }) => {
      state.availabilities = handleAvailabilities(
        payload?.availabilities?.data,
        payload?.payload
      );
      state.timeRange = handleTimeRange(payload?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.pageCount =
        payload?.availabilities?.meta?.total < state.pageSize
          ? 1
          : Math.ceil(
              (payload?.availabilities?.meta?.total || 1) / state.pageSize
            );
    },
    getConfigDataSuccess: (state, { payload }) => {
      state.configData = handleConfigData(payload);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchAvailabilitiesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchAvailabilities,
  fetchAvailabilitiesSuccess,
  getConfigDataSuccess,
  fetchAvailabilitiesFailure,
} = availabilitySlice.actions;

export const selectAvailabilityState = (state) => state.availability;

export const getConfigData = () => {
  return async (dispatch) => {
    dispatch(fetchAvailabilities());

    try {
      const brands = await fleetVehiclesServices?.getVehiclesModels();
      const enums = await vehiclesServices?.getVehicleEnums();
      dispatch(
        getConfigDataSuccess({ brands: brands?.data, enums: enums?.data })
      );
    } catch (error) {
      dispatch(fetchAvailabilitiesFailure(error));
    }
  };
};

export const getAvailabilities = (payload) => {
  return async (dispatch) => {
    dispatch(fetchAvailabilities());
    try {
      const response = await graphServices?.getAvailabilities(payload);
      dispatch(
        fetchAvailabilitiesSuccess({
          availabilities: response,
          payload,
        })
      );
    } catch (error) {
      dispatch(fetchAvailabilitiesFailure(error));
    }
  };
};

export default availabilitySlice.reducer;
