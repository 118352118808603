const computeModelData = (models) => {
  if (!models) {
    return {};
  }
  const data = {};
  const uniqueManufacturers = [];
  const uniqueModels = [];
  const modelObj = {};
  Object.keys(models)?.forEach((dataManufacturer) => {
    const exists = uniqueManufacturers?.some((manufacturer) => {
      return manufacturer.value === models[dataManufacturer].slug;
    });

    if (exists) return;
    uniqueManufacturers?.push({
      value: models[dataManufacturer].slug,
      text: models[dataManufacturer].name,
    });
    Object.keys(models[dataManufacturer]?.models)?.forEach((model) => {
      const uniqueModelExists = uniqueModels?.some((uniqueModel) => {
        return (
          uniqueModel.value === models[dataManufacturer]?.models[model].slug &&
          uniqueModel.group === models[dataManufacturer]?.slug
        );
      });

      if (uniqueModelExists) return;
      if (modelObj[models[dataManufacturer]?.slug]) {
        modelObj[models[dataManufacturer]?.slug]?.push({
          value: models[dataManufacturer]?.models[model].slug,
          text: models[dataManufacturer]?.models[model].name,
          group: models[dataManufacturer]?.slug,
        });
      } else {
        modelObj[models[dataManufacturer]?.slug] = [
          {
            value: models[dataManufacturer]?.models[model].slug,
            text: models[dataManufacturer]?.models[model].name,
            group: models[dataManufacturer]?.slug,
          },
        ];
      }
      uniqueModels?.push({
        value: models[dataManufacturer]?.models[model].slug,
        text: models[dataManufacturer]?.models[model].name,
        group: models[dataManufacturer]?.slug,
      });
    });
  });

  data.models = uniqueModels;
  data.manufacturers = uniqueManufacturers;
  modelObj[undefined] = [];
  data.modelObj = modelObj;
  return data;
};

export default computeModelData;
