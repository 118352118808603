import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { servicePointsServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { asyncForEach } from '@utils';
import {
  handleServicePointsData,
  handleServicePointData,
  handleServicePointsEventsData,
} from './servicePoints.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  servicePoints: [],
  servicePoint: {},
  events: [],
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
  enums: {},
};
// Actual Slice
export const servicePointsSlice = createSlice({
  name: 'servicePoints',
  initialState,
  reducers: {
    fetchServicePoints: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServicePointsSuccess: (state, { payload }) => {
      state.servicePoints = handleServicePointsData(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.servicePoints.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.servicePoints?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchServicePointsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload?.message || GENERIC_ERROR_MESSAGE;
    },
    fetchServicePoint: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.eventsLoading = false;
      state.events = [];
      state.errorMessage = '';
      state.servicePoint = {};
    },
    fetchServicePointSuccess: (state, { payload }) => {
      state.servicePoint = handleServicePointData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServicePointFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.eventsLoading = false;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchServicePointsEvents: (state) => {
      state.eventsLoading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchServicePointsSuccessEvents: (state, { payload }) => {
      state.eventsLoading = false;
      state.events = handleServicePointsEventsData(payload?.data);
    },
    fetchServicePointsEnums: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.enums = {};
    },
    fetchServicePointsEnumsSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.enums = payload?.data;
    },
    fetchServicePointsEnumsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
      state.enums = {};
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchServicePoints,
  fetchServicePointsSuccess,
  fetchServicePointsFailure,
  fetchServicePoint,
  fetchServicePointSuccess,
  fetchServicePointFailure,
  fetchServicePointsEvents,
  fetchServicePointsSuccessEvents,
  fetchServicePointsEnums,
  fetchServicePointsEnumsSuccess,
  fetchServicePointsEnumsFailure,
} = servicePointsSlice.actions;

export const selectServicePointsState = (state) => state.servicePoints;

export const getServicePoints = (payload) => {
  return async (dispatch) => {
    dispatch(fetchServicePoints());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await servicePointsServices?.getServicePointsFilters(
        payload
      );
      response.meta.page = page;

      dispatch(fetchServicePointsSuccess(response));
    } catch (error) {
      dispatch(fetchServicePointsFailure(error));
    }
  };
};

export const deleteServicePoints = (payload, page) => {
  return async (dispatch) => {
    dispatch(fetchServicePoints());

    try {
      await asyncForEach(payload, async (servicePointId) => {
        await servicePointsServices?.deleteServicePoint(servicePointId);
      });

      dispatch(getServicePoints(page));
    } catch (error) {
      dispatch(fetchServicePointsFailure(error));
    }
  };
};

export const getServicePoint = (id) => {
  return async (dispatch) => {
    dispatch(fetchServicePoint());

    try {
      const servicePoint = {};
      const details = await servicePointsServices?.getServicePoint(id);
      servicePoint.details = details?.data;
      const addresses = await servicePointsServices?.getServicePointAddresses(
        id
      );
      servicePoint.addresses = addresses?.data;

      dispatch(fetchServicePointSuccess(servicePoint));
    } catch (error) {
      dispatch(fetchServicePointFailure(error));
    }
  };
};

export const getServicePointEvents = (id) => {
  return async (dispatch) => {
    dispatch(fetchServicePointsEvents());

    try {
      const events = await servicePointsServices?.syncServicePointEvents(id);

      dispatch(fetchServicePointsSuccessEvents(events));
    } catch (error) {
      dispatch(fetchServicePointFailure(error));
    }
  };
};

export const getServicePointsEnums = () => {
  return async (dispatch) => {
    dispatch(fetchServicePointsEnums());

    try {
      const enums = await servicePointsServices?.getServicePointsEnums();

      dispatch(fetchServicePointsEnumsSuccess(enums));
    } catch (error) {
      dispatch(fetchServicePointsEnumsFailure(error));
    }
  };
};

export const activateDeactivateServicePoints = (payload) => {
  return async (dispatch) => {
    dispatch(fetchServicePoints());

    try {
      await servicePointsServices?.activateDeactivateServicePoints(payload);
    } catch (error) {
      dispatch(fetchServicePointsFailure(error));
    }
  };
};

export default servicePointsSlice.reducer;
