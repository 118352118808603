import { formatDate, formatAmount } from '@utils';
import { pickBy, identity } from 'lodash';

export const handleSubscribers = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, innerArray) => {
    const row = {
      id: item.id,
      internalIdentificationNumber: item?.internalIdentificationNumber || '-',
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      firstName: item?.firstName || '-',
      lastName: item?.lastName || '-',
      email: item?.email || '-',
      subscriptions: item?.subscriptions ? item?.subscriptions?.length : 0,
    };
    innerArray[index] = row;

    return innerArray;
  });
  return array;
};

export const handleSubscriber = (response) => {
  const data = { ...response };
  const user = data?.user;
  const featureFlags = data?.featureFlags;

  data.details = {
    createdAt: {
      label: 'Created At:',
      renderedValue: data?.createdAt
        ? formatDate(data.createdAt, 'DD/MM/YYYY')
        : '-',
      onEditField: null,
    },
    updatedAt: {
      label: 'Updated At:',
      renderedValue: data?.updatedAt
        ? formatDate(data.updatedAt, 'DD/MM/YYYY')
        : '-',
      onEditField: null,
    },
    email: {
      label: 'E-mail:',
      renderedValue: data?.email || '-',
      onEditField: null,
    },
    telephoneNumber: {
      canEdited: true,
      label: 'Mobile Phone:',
      type: 'text',
      renderedValue: user?.phoneNumber || '-',
      defaultValue: user?.phoneNumber,
      instructions:
        'Telephone number should be in E.164 format e.g. +3069xxxxxxxx',
      onEditField: null,
      fieldWrapperStyle: {
        width: '100%',
      },
    },
  };
  data.billingInfoRaw = data.billingInfo;
  // TODO: this should be in a selector
  data.billingInfo = data.billingInfo?.reduce((acc, cur) => {
    const normalizedBillingItem = pickBy(
      {
        id: cur.billingId,
        firstName: {
          label: 'First Name:',
          renderedValue: cur.firstName || '-',
          defaultValue: cur.firstName,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        lastName: {
          label: 'Last Name:',
          renderedValue: cur.lastName || '-',
          defaultValue: cur.lastName,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        vat: {
          label: 'Tax code:',
          renderedValue: cur.vat || '-',
          defaultValue: cur.vat,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
          // TODO: enable after permission handle
          // disabled: !!cur.vat,
        },
        companyName: cur.companyName
          ? {
              label: 'Business Name:',
              renderedValue: cur.companyName || '-',
              defaultValue: cur.companyName,
              onEditField: null,
              canEdited: true,
              type: 'text',
              placeholder: '',
              rules: {
                required: '* This field is required',
              },
              fieldWrapperStyle: {
                width: '100%',
              },
              hidden: false,
            }
          : undefined,
        doi: {
          label: 'D.O.Y:',
          renderedValue: cur.doi || '-',
          defaultValue: cur.doi,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        activity: {
          label: 'Activity:',
          renderedValue: cur.activity || 'Ιδιώτης',
          defaultValue: cur.activity || 'Ιδιώτης',
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        phoneNumber: {
          label: 'Phone Number:',
          renderedValue: cur.phoneNumber || '-',
          defaultValue: cur.phoneNumber,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        address: {
          label: 'Street Address:',
          renderedValue: cur.address || '-',
          defaultValue: cur.address,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        email: {
          label: 'E-mail:',
          renderedValue: cur.email || '-',
          defaultValue: cur.email,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'invalid email address',
            },
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
        postalCode: {
          label: 'Postal Code:',
          renderedValue: cur.postalCode || '-',
          defaultValue: cur.postalCode,
          onEditField: null,
          canEdited: true,
          type: 'text',
          placeholder: '',
          rules: {
            required: '* This field is required',
          },
          fieldWrapperStyle: {
            width: '100%',
          },
          hidden: false,
        },
      },
      identity
    );
    acc.push(normalizedBillingItem);
    return acc;
  }, []);

  data.featureFlags = {
    isUserAppEnabled: {
      label: 'IS USER APP ENABLED',
      renderedValue: featureFlags?.isUserAppEnabled,
      hasSwitch: true,
    },
  };

  return data;
};

export const handleSubscriberSubscriptions = (response, subscriberId) => {
  const data = [...response];
  data?.map((subscription) => {
    const billingInfo =
      subscription?.subscribers[0]?.subscriptions[0]?.billingInfo;
    subscription.subscriptionType = subscription?.type;

    subscription.type = subscription?.subscribers[0]?.subscriptions[0]?.type;

    subscription.phoneField = {
      telephoneNumber: {
        label: 'Telephone Number',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.telephoneNumber,
        instructions:
          'Telephone number should be in E.164 format e.g. +3069xxxxxxxx',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    };
    subscription.vatField = {
      vat: {
        label: 'VAT number',
        type: 'number',
        placeholder: '',
        defaultValue: billingInfo?.vatRegistrationNumber,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    };
    subscription.addressField = {
      streetAddress: {
        label: 'Street Name',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.streetAddress || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      streetNumber: {
        label: 'Street Number',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.streetNumber || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      locality: {
        label: 'Locality',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.locality || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      postalCode: {
        label: 'Postal Code',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.postalCode || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      region: {
        label: 'Region',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.region || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
      country: {
        label: 'Country',
        type: 'text',
        placeholder: '',
        defaultValue: billingInfo?.address?.country || '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '45%',
        },
      },
    };
    subscription.plate = subscription?.vehicle?.plate;
    subscription.amount = formatAmount(subscription?.rules[0]?.amount?.value);

    const currentSubscriber = subscription?.subscribers.find(
      (subscriber) => subscriber.id === subscriberId
    );

    const currentSubscription = currentSubscriber?.subscriptions.find(
      (subscr) => subscr.id === subscription.id
    );
    subscription.role = currentSubscription?.role === 1 ? 'OWNER' : 'DRIVER';
    return subscription;
  });
  return data;
};
