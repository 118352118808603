import { useRef } from 'react';
import { useSelector } from 'react-redux';

// useSelector without re-render
export const useSelectorRef = (selectHandler) => {
  const ref = useRef();

  useSelector(selectHandler, (_, b) => {
    ref.current = b;
    return true;
  });

  return ref.current;
};
