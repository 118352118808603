import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { marketingServices } from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';

import { handleCampaigns } from './campaigns.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  campaigns: [],
  campaign: {},
  total: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    fetchCampaigns: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchCampaignsSuccess: (state, { payload }) => {
      state.campaigns = handleCampaigns(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchCampaignsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchCampaign: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchCampaignSuccess: (state, { payload }) => {
      state.campaign = payload.data;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchCampaignFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    initializePostCampaign: (state) => {
      state.campaign = {};
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
    },
    postCampaign: (state) => {
      state.postLoading = true;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
    },
    postCampaignSuccess: (state) => {
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = true;
    },
    postCampaignFailure: (state, { payload }) => {
      state.postLoading = false;
      state.postHasErrors = true;
      state.postErrorMessage = payload || GENERIC_ERROR_MESSAGE;
      state.postSuccess = false;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchCampaigns,
  fetchCampaignsSuccess,
  fetchCampaignsFailure,
  postCampaign,
  postCampaignSuccess,
  postCampaignFailure,
  fetchCampaign,
  fetchCampaignSuccess,
  fetchCampaignFailure,
  initializePostCampaign,
} = campaignsSlice.actions;

export const selectCampaignsState = (state) => state.campaigns;

export const getCampaigns = (payload) => {
  return async (dispatch) => {
    dispatch(fetchCampaigns());

    try {
      const page = parseInt(payload?.page, 10);
      const response = await marketingServices?.getCampaigns(payload);
      response.meta.page = page;

      dispatch(fetchCampaignsSuccess(response));
    } catch (error) {
      dispatch(fetchCampaignsFailure(error));
    }
  };
};

export const getCampaign = (id) => {
  return async (dispatch) => {
    try {
      let data = {};
      if (id) {
        dispatch(fetchCampaign());
        data = await marketingServices?.getCampaign(id);
      } else {
        data = {};
      }

      dispatch(fetchCampaignSuccess(data));
    } catch (error) {
      dispatch(fetchCampaignFailure(error));
    }
  };
};

export const addCampaign = (payload) => {
  return async (dispatch) => {
    dispatch(postCampaign());

    try {
      await marketingServices?.createCampaign(payload);

      dispatch(postCampaignSuccess());
    } catch (error) {
      dispatch(postCampaignFailure(error));
    }
  };
};

export const editCampaign = (payload) => {
  return async (dispatch) => {
    dispatch(postCampaign());

    try {
      const { id } = payload;
      await marketingServices?.editCampaign(id, payload);

      dispatch(postCampaignSuccess());
    } catch (error) {
      dispatch(postCampaignFailure(error));
    }
  };
};

export const deleteCampaignImage = (payload) => {
  return async (dispatch) => {
    dispatch(postCampaign());

    try {
      await marketingServices?.deleteCampaignImage(payload);

      dispatch(postCampaignSuccess());
    } catch (error) {
      dispatch(postCampaignFailure(error));
    }
  };
};

export default campaignsSlice.reducer;
