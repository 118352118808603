import moment from 'moment';

const duration = (from, to) => {
  const momentFrom = moment(from);
  const momentTo = moment(to);
  const dur = moment.duration(momentFrom.diff(momentTo));
  const humanizedString = dur.humanize(true)?.replace('ago', '');
  return humanizedString;
};

export default duration;
