import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';

import {
  handleSubscribers,
  handleSubscriber,
  handleSubscriberSubscriptions,
} from './subscribers.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  subscribers: [],
  subscriber: {},
  subscriptions: [],
  getSubscriberHasErrors: false,
  getSubscriberErrorMessage: '',
  total: 0,
  firstItem: 1,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
};
// Actual Slice
export const subscribersSlice = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {
    fetchSubscribers: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchSubscribersSuccess: (state, { payload }) => {
      state.subscribers = handleSubscribers(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.firstItem =
        payload?.meta?.page === 1
          ? 1
          : ((payload?.meta?.page || 1) - 1) * state.pageSize + 1;
      state.lastItem =
        payload?.meta?.page === 1
          ? state.subscribers.length
          : ((payload?.meta?.page || 1) - 1) * state.pageSize +
            (state?.subscribers?.length || 0);
      state.pageCount =
        payload?.meta?.total < state.pageSize
          ? 1
          : Math.ceil((payload?.meta?.total || 1) / state.pageSize);
    },
    fetchSubscribersFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchSubscriber: (state) => {
      state.loading = true;
      state.getSubscriberHasErrors = false;
      state.getSubscriberErrorMessage = '';
    },
    fetchSubscriberSuccess: (state, { payload }) => {
      state.subscriber = handleSubscriber(payload?.data);
      state.loading = false;
    },
    fetchSubscriberFailure: (state, { payload }) => {
      state.loading = false;
      state.getSubscriberHasErrors = true;
      state.getSubscriberErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchSubscriberSubscriptions: (state) => {
      state.loading = true;
      state.getSubscriberSubscriptionsHasErrors = false;
      state.getSubscriberSubscriptionsErrorMessage = '';
      state.subscriptions = [];
    },
    fetchSubscriberSubscriptionsSuccess: (state, { payload }) => {
      state.subscriptions = handleSubscriberSubscriptions(
        payload?.response?.data,
        payload.subscriberId
      );
      state.loading = false;
    },
    fetchSubscriberSubscriptionsFailure: (state, { payload }) => {
      state.subscriptions = [];
      state.loading = false;
      state.getSubscriberSubscriptionsHasErrors = true;
      state.getSubscriberSubscriptionsErrorMessage =
        payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchSubscribers,
  fetchSubscribersSuccess,
  fetchSubscribersFailure,
  fetchSubscriber,
  fetchSubscriberSuccess,
  fetchSubscriberFailure,
  fetchSubscriberSubscriptions,
  fetchSubscriberSubscriptionsSuccess,
  fetchSubscriberSubscriptionsFailure,
} = subscribersSlice.actions;

export const selectSubscribersState = (state) => state.subscribers;

export default subscribersSlice.reducer;
