import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { boardsServices } from '@services';
import { BOARDS, GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import {
  handleReturnsInboxData,
  handleReturnsTaskData,
  handleReturnsSubTasksData,
} from './returnsInbox.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  returnsInbox: [],
  returnTask: {},
  subTasks: [],
  total: 0,
  pageCount: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const returnsInboxSlice = createSlice({
  name: 'returnsInbox',
  initialState,
  reducers: {
    fetchReturnsInbox: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
    },
    fetchReturnsInboxSuccess: (state, { payload }) => {
      state.returnsInbox = handleReturnsInboxData(payload);
      state.total = payload?.returnsInbox?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.pageCount =
        payload?.returnsInbox?.meta?.total < state.pageSize
          ? 1
          : Math.ceil(
              (payload?.returnsInbox?.meta?.total || 1) / state.pageSize
            );
    },
    fetchReturnsTaskSuccess: (state, { payload }) => {
      state.returnTask = handleReturnsTaskData(payload?.task);
      state.subTasks = handleReturnsSubTasksData(payload?.subTasks);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchReturnsInboxFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchReturnsInbox,
  fetchReturnsInboxSuccess,
  fetchReturnsTaskSuccess,
  fetchReturnsInboxFailure,
} = returnsInboxSlice.actions;

export const selectReturnsInboxState = (state) => state.returnsInbox;

export const getReturnsInbox = () => {
  return async (dispatch) => {
    dispatch(fetchReturnsInbox());

    try {
      const returnsInbox = await boardsServices.getBoardsByType(BOARDS.RETURNS);
      dispatch(fetchReturnsInboxSuccess(returnsInbox?.data));
    } catch (error) {
      dispatch(fetchReturnsInboxFailure(error));
    }
  };
};

export const getReturnTask = (taskId) => {
  return async (dispatch) => {
    dispatch(fetchReturnsInbox());

    try {
      const task = await boardsServices.getTaskById(taskId);
      const subtasks = await boardsServices.getSubTasksById(taskId);

      dispatch(
        fetchReturnsTaskSuccess({ task: task?.data, subTasks: subtasks?.data })
      );
    } catch (error) {
      dispatch(fetchReturnsInboxFailure(error));
    }
  };
};

export default returnsInboxSlice.reducer;
