import dynamic from 'next/dynamic';

const BillingFilters = dynamic(() => import('./BillingFilters/BillingFilters'));
const BillingTabs = dynamic(() => import('./BillingTabs/BillingTabs'));
const BillingForm = dynamic(() => import('./BillingForm/BillingForm'));
const AddBillingTask = dynamic(() => import('./AddBillingTask/AddBillingTask'));
const BillingListContent = dynamic(() => import('./BillingListContent'));

export {
  BillingFilters,
  BillingTabs,
  BillingForm,
  AddBillingTask,
  BillingListContent,
};
