import { request } from '@utils';
import { LIMIT } from '@constants';

const getPartners = async ({ params, payload = {} }) => {
  if (!params?.page) {
    params.page = 1;
  }
  if (!params?.pageSize) {
    params.pageSize = LIMIT;
  }

  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/partners/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const createPartner = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/partners`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const removePartner = async (internalId) => {
  const params = {
    body: {
      isActive: false,
    },
  };
  const results = await request.patch(`/v1/partners/${internalId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editPartner = async (internalId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/partners/${internalId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editPartnerPhone = async (id, phoneId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.put(
    `/v1/partners/${id}/phones/${phoneId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};
export {
  getPartners,
  createPartner,
  editPartner,
  removePartner,
  editPartnerPhone,
};
