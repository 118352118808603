import { PRICING_TYPES } from '@constants';
import {
  getAvailability,
  getBodySize,
  getBodyType,
  getCategory,
  getFuelType,
  getProductLines,
} from 'store/catalog/vehicles.helpers';
import {
  computeModelData,
  fixedNumber,
  formatAmount,
  formatDate,
  formatVehicleStage,
} from '@utils';
import { INITIAL_VALUES } from './constants';

export const handleEbikes = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    const itemPrice = item.pricing?.find((pricingItem) => {
      return pricingItem?.type === PRICING_TYPES.open;
    });

    const row = {
      availability: item.availability || '-',
      brand: item?.properties?.brand || '-',
      category: item?.properties?.category || '-',
      edition: item?.properties?.edition?.trim() || '-',
      year: item?.properties?.year || '-',
      engineDisplacement: item?.properties?.engine?.displacement,
      fuelType: item?.properties?.engine?.fuelType || '-',
      image: item?.image?.url ? item.image?.url : '',
      initialPrice: itemPrice.initialPrice
        ? formatAmount(fixedNumber(itemPrice.initialPrice))
        : '-',
      instastart: itemPrice.instastart
        ? formatAmount(fixedNumber(itemPrice.instastart))
        : '-',
      kilometers: item.kilometers || '-',
      model: item?.properties?.model || '-',
      monthlyKilometers: item.monthlyKilometers || '-',
      name: item.name || '-',
      price: itemPrice.price ? formatAmount(fixedNumber(itemPrice.price)) : '-',
      status: item.published ? 'Published' : 'Unpublished',
      published: item.published,
      skuId: item.skuId || '-',
      tags: item.tags,
      transmission: item?.properties?.transmission || '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      vendors: item.vendors,
      id: item.skuId,
      bodyType: item?.properties?.body?.type || '-',
      type: item?.type || '-',
      powerHp: item?.properties?.engine?.powerHp || '-',
      batterySize: item?.properties?.engine?.batterySize || '-',
      euroClass: item?.properties?.engine?.euroClass || '-',
      signUpFee: formatAmount(fixedNumber(item.signUpFee)) || '-',
    };
    newArray[index] = row;

    return newArray;
  });
  return array;
};

export const handleEbikeData = (data) => {
  if (
    !('enums' in data) &&
    !('colors' in data) &&
    !('providers' in data) &&
    !('ebike' in data) &&
    !('models' in data)
  ) {
    return {};
  }

  data.enums = data.enums.data;
  data.colors = data?.colors?.data;
  data.providers = data?.providers?.data;
  data.models = data.models.data;
  data.ebike = data?.ebike?.data;

  if (data.ebike) {
    // normalize sku values
    const openPrice = data?.ebike?.pricing?.find(
      (price) => price?.type === PRICING_TYPES.open
    );
    data.ebike.price = openPrice?.price;
    data.ebike.signUpFee = openPrice?.signUpFee;
    data.ebike.initialPrice = openPrice?.initialPrice;
    data.ebike.skuId = data?.ebike?.skuId?.slice(3);
    data.ebike.properties.engine.fuelType = getFuelType(
      data?.enums,
      data?.ebike?.properties?.engine?.fuelType
    );
    data.ebike.properties.category = getCategory(
      data?.enums,
      data?.ebike?.properties?.category
    );
    data.ebike.availability = getAvailability(
      data?.enums,
      data?.ebike?.availability
    );
    data.ebike.properties.body.type = getBodyType(
      data?.enums,
      data?.ebike?.properties?.body?.type
    );
    data.ebike.properties.body.size = getBodySize(
      data?.enums,
      data?.ebike?.properties?.body?.size
    );
    data.ebike.properties.productLine = getProductLines(
      data?.enums,
      data?.ebike?.properties?.productLine
    );
    data.ebike.specialOffer = data?.ebike?.tags?.includes('offer');
    data.ebike.tags = data?.ebike?.tags?.filter((item) => item !== 'offer');
  } else {
    data.ebike = INITIAL_VALUES;
  }

  data = { ...data, ...computeModelData(data?.models) };

  return data;
};

export const handleEbikesBySku = (data = []) =>
  data.map((item) => ({
    id: item.id || '-',
    internalId: item.internalId || '-',
    plate: item.plate || '-',
    brand: item.sku?.brand || '-',
    stage: formatVehicleStage(item.procurement.currentStageId),
    finalPurchasePrice: item.procurement.finalPurchasePrice
      ? formatAmount(item.procurement.finalPurchasePrice.number)
      : '-',
  }));
