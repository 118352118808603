import { RETURN_TASK_STATUSES_NAMES } from 'components/returnsInbox/constants';

export const handleReturnsInboxData = (data) => {
  if (!('tasksByColumn' in data)) {
    return {};
  }
  const tasks = [];
  const columns = [];
  data?.tasksByColumn?.forEach((item, index) => {
    const newTasks = item?.tasks?.map((task) => {
      return {
        ...task,
        href: `/returnsInbox/${task?.id}`,
        status: index,
        assignee: item?.assignee || '-',
      };
    });
    tasks.push(...newTasks);
  });
  data?.statuses
    ?.sort((a, b) => b.order - a.order)
    ?.reverse()
    ?.forEach((item) => {
      columns.push({
        id: item.status,
        title: RETURN_TASK_STATUSES_NAMES[item.status],
        cardIds: data?.tasksByColumn[item.status]?.tasks?.map(
          (task) => task?.id
        ),
      });
    });

  return {
    boardId: data?.id,
    tasks,
    columns,
  };
};

export const handleReturnsTaskData = (data) => {
  if (!data) {
    return {};
  }
  const task = {
    ...data,
    changelog: data?.changelog?.reverse(),
  };
  return task;
};

export const handleReturnsSubTasksData = (data) => {
  if (!data) {
    return [];
  }
  const subTasks = data?.map((task) => {
    return {
      ...task,
      assignee: task?.assignee || '-',
      status: RETURN_TASK_STATUSES_NAMES[task?.status],
    };
  });
  return subTasks;
};
