import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectMailBoxesState, getMailBoxes } from '@store';
import { showError, showSuccess } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';
import { communicationServices } from '@services';

export const useMailBoxesOperations = (fetchOnMount) => {
  const { total, loading, mailBoxes, pageSize, hasErrors, errorMessage } =
    useSelector(selectMailBoxesState);
  const {
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', code = '' },
    isReady,
  } = useRouter();
  const dispatch = useDispatch();

  const _getMailBoxes = useCallback(async () => {
    const payload = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
    await dispatch(getMailBoxes({ orderBy, order, page, pageSize }, payload));
  }, [orderBy, order, page, pageSize, dispatch]);

  const _validateMailbox = useCallback(async () => {
    try {
      if (!code) return;
      const id = localStorage.mailboxId;
      await communicationServices.validateEmailBox({
        id,
        code,
      });
      localStorage.removeItem('mailboxId');
      showSuccess('The mailbox is validated');
      await _getMailBoxes();
      push({
        pathname: '/mailBoxes',
        query: {
          page,
          orderBy,
          order,
        },
      });
    } catch (error) {
      showError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, page, orderBy, order]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/mailBoxes?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/mailBoxes?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/mailBoxes?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/mailBoxes?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    _validateMailbox();
  }, [code]);

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getMailBoxes();
    }
  }, [_getMailBoxes, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    pageSize,
    total,
    loading,
    mailBoxes,
    hasErrors,
    errorMessage,
    getMailBoxes: _getMailBoxes,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
